const CONSTANTS = {
  TRENDING_NEWS_COLORS: [
    '#FF0000', // Red
    '#4caf50', // Green
    '#50b3f6', // Blue
    '#ffc107', // Yellow
    '#FF00FF', // Magenta
    '#a48011', // Gold
    '#8A8A8A', // Platinum
    '#ff7043', // Orange
    '#ec407a', // Pink
    '#7e57c2' // Purple
  ]
}

export default CONSTANTS
