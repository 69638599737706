import './styles.scss'
import { React, useState } from 'react'
import { Form, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { InputText } from 'primereact/inputtext'
import { getLocalUserDetails } from 'Utils/LocalStorageHandler'
import LayoutContainer from 'Components/LayoutComponent'
import ChangePasswordDialog from '../ChangePasswordDialog'

const Profile = () => {
  const { t } = useTranslation()
  const { email, phone, firstName, lastName } = getLocalUserDetails()

  const fullName = `${firstName} ${lastName}`

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const onSubmit = (e, form) => {
    form.restart()
  }

  return (
    <LayoutContainer title={t('USER_PROFILE')} breadCrumbsTitle={'profile'} setIsDialogOpen={setIsDialogOpen} showChangePasswordButton={true}>
      <div className="profile-container">
        <Form
          onSubmit={onSubmit}
          initialValues={(email, fullName, phone)}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="p-fluid">
              <Field
                name="userType"
                render={({ input }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <InputText id="userType" {...input} value={fullName} disabled={fullName} />
                      <label htmlFor="userType" className="label-text">
                        {t('USER_TYPE')} <span className="star-required"> *</span>
                      </label>
                    </span>
                  </div>
                )}
              />
              <Field
                name="email"
                render={({ input }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <InputText id="email" {...input} value={email} disabled={email} />
                      <label htmlFor="email" className="label-text">
                        {t('USER_EMAIL')} <span className="star-required"> *</span>
                      </label>
                    </span>
                  </div>
                )}
              />

              <Field
                name="phone"
                render={({ input }) => (
                  <div className="field">
                    <span className="p-float-label ">
                      <InputText id="phone" {...input} value={phone} disabled={phone} />
                      <label htmlFor="phone" className="label-text">
                        {t('USER_PHONE')} <span className="star-required"> *</span>
                      </label>
                    </span>
                  </div>
                )}
              />
            </form>
          )}
        />
        <ChangePasswordDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} />
      </div>
    </LayoutContainer>
  )
}

export default Profile
