export const commonMethods = {
  utcToLocal(dateString) {
    const date = new Date(dateString)

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short'
    }

    return date.toLocaleString('en-US', options)
  },
  utcToLocalTime(dateString) {
    const date = new Date(dateString)

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }

    return date.toLocaleString('en-US', options)
  },
  utcToLocalOnlyTime(dateString) {
    const date = new Date(dateString)

    const options = {
      hour: 'numeric',
      minute: 'numeric'
    }

    return date.toLocaleString('en-US', options)
  },
  utcToLocalDate(dateString) {
    const date = new Date(dateString)

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }

    return date.toLocaleString('en-US', options)
  },
  utcToLocalShortDate(dateString) {
    const date = new Date(dateString)

    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }

    return date.toLocaleString('en-US', options)
  },
  handleRetry() {
    window.location.reload()
  },

  getOptionExpiryColor(optionExpiryStatus) {
    if (optionExpiryStatus === 'boldAndRed') return 'bold_and_red'
    if (optionExpiryStatus === 'expired') return 'expired'

    return ''
  },

  getFirstNoticeDayColor(optionExpiryStatus) {
    if (optionExpiryStatus === 'boldAndBlack') return 'bold_and_black'
    if (optionExpiryStatus === 'boldAndRed') return 'bold_and_red'
    if (optionExpiryStatus === 'expired') return 'expired'

    return ''
  }
}
