import './styles.scss'
import React from 'react'
import moment from 'moment'
import { Dialog } from 'primereact/dialog'
import { useTranslation } from 'react-i18next'
import { ButtonComponent } from 'Components/UIComponents'

function GlobalDifferentialUserPreview({ showUserPreview, setShowUserPreview, globalDifferentialData, confirmApproveGlobalDifferential }) {
  const { t } = useTranslation()

  const approveGlobalDifferential = () => {
    confirmApproveGlobalDifferential()
    setShowUserPreview(false)
  }

  const footerContent = (
    <div className="global-differential-user-preview-footer">
      <ButtonComponent type="custom" variant="neutral" label={t('DISMISS')} onClick={() => setShowUserPreview(false)} className="p-button-text" />
      <ButtonComponent type="custom" variant="action" label={t('APPROVE')} icon="pi pi-check" onClick={() => approveGlobalDifferential()} />
    </div>
  )

  const getTextColor = (value) => {
    if (value) {
      const convertedToNumber = parseFloat(value?.replace(/[$¢]/g, ''))

      if (convertedToNumber >= 0) {
        return { color: 'green' }
      }

      return { color: 'red' }
    }

    return {}
  }

  return (
    <div>
      <Dialog className="global-differential-user-preview" header={t('GLOBAL_DIFFERENTIAL_USER_PREVIEW')} visible={showUserPreview} onHide={() => setShowUserPreview(false)} footer={footerContent}>
        {globalDifferentialData?.map((GDItem, index) => (
          <div key={index}>
            <div className="differential_table">
              {GDItem.cropyear?.map((qualityDiffGroup, index) => (
                <div key={index} className="table_data">
                  <div className="year">
                    <div className="flag_and_cropyear">
                      <div className="crop_year_flag">
                        <img src={`https://coffeeweb.s3.ap-south-1.amazonaws.com/Masters/Flags/${GDItem?.iso3}.svg`} alt="Flag" /> {GDItem?.country}
                      </div>
                      <div className="cropyear">
                        {t('CROP_YEAR')} : {qualityDiffGroup?.croyear}
                      </div>
                    </div>
                    <div className="updated_on">
                      {t('UPDATED_ON')} : {GDItem.upDatedDate}
                    </div>
                    <div>
                      {GDItem?.cropyear[0]?.globalDifferentialAdminDTOs[0]?.forexType} : {GDItem.cropyear[0]?.globalDifferentialAdminDTOs[0]?.globalDifferentialData[0]?.forexValue}
                    </div>
                  </div>
                  {qualityDiffGroup?.globalDifferentialAdminDTOs?.map((ele, index) => (
                    <table className="each_table" key={index}>
                      <thead className="differential_table_header">
                        <tr>
                          <th className="slNo">{index + 1}</th>
                          <th colSpan={1} className="quality-th-cell">
                            {t('QUALITY')}
                          </th>
                          <th className="screen-th-cell">{t('SCREEN')}</th>
                          <th colSpan={2}>{t('DIFFERENTIALS')}</th>
                          <th colSpan={2}>{ele?.terminal}</th>
                          <th colSpan={2}>{ele?.terminalLevel}</th>
                          <th className="delivery_port">{t('DIFFERENTIALS_BASED_ON_FOB')}</th>
                        </tr>
                        <tr>
                          <th>{t('SL')}</th>
                          <th className="text_content">{`${GDItem?.country?.toUpperCase()}  ${ele?.qualityGroupName}`}</th>
                          <th>{t('SIZE')}</th>
                          <th colSpan={6}>{t('BETWEEN')}</th>
                          <th>{t('FOB Port')}</th>
                        </tr>
                      </thead>
                      <tbody className="differential_table_body">
                        {ele?.globalDifferentialData?.map(({ qualityName, qualityCode, rangeStart, rangeEnd, terminal, remark, fobPort }, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{qualityName}</td>
                            <td>{qualityCode}</td>
                            <td className="range_starts" style={getTextColor(rangeStart)} colSpan={3}>
                              {rangeStart}
                            </td>
                            <td className="range_ends" style={getTextColor(rangeEnd)} colSpan={3}>
                              {rangeEnd}
                            </td>
                            {index === 0 && (
                              <td className="fob-cell" rowSpan={ele?.globalDifferentialData.length}>
                                {ele?.fobPort}
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ))}
      </Dialog>
    </div>
  )
}

export default GlobalDifferentialUserPreview
