import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button'
import { TABLE_CONST } from 'Utils/Constants'

const ButtonComponent = (props) => {
  const { type, disabled, label, onClick } = props
  const { t } = useTranslation()

  let showLabel = true
  let onlyLabel = ''
  let iconPresent = 'icon'

  const [windowSize, setWindowSize] = useState(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const actions = {
    ADD: { labelName: t('ADD'), iconName: 'pi pi-plus', buttonVariant: 'action' },
    EDIT: { labelName: t('EDIT'), iconName: 'pi pi-pencil', buttonVariant: 'action' },
    CANCEL: { labelName: t('CANCEL'), iconName: '', buttonVariant: 'neutral' },
    RESET: { labelName: t('RESET'), iconName: '', buttonVariant: 'neutral' },
    SUBMIT: { labelName: t('SUBMIT'), iconName: '', buttonVariant: 'action' },
    CUSTOM: { labelName: props?.label ? t(props?.label) : t('CUSTOM_BUTTON'), iconName: props?.icon ? props?.icon : '', buttonVariant: props?.variant ? props?.variant : 'neutral' }
  }

  const action = actions[type?.toUpperCase() || label]
  const { labelName, iconName, buttonVariant } = action

  if (!iconName) {
    onlyLabel = 'only-label'
    iconPresent = ''
  }

  if (windowSize < TABLE_CONST.maxWidthTab && labelName && iconName) {
    showLabel = false
  }

  return (
    <div className="button-component-container">
      <Button label={showLabel ? labelName : null} icon={iconName} className={`${buttonVariant} ${iconPresent} ${onlyLabel}`} disabled={disabled} onClick={onClick} />
    </div>
  )
}

export default ButtonComponent
