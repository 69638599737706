import './styles.scss'
import React, { useEffect, useState } from 'react'
import { Form, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { InputText } from 'primereact/inputtext'
import { useLocation } from 'react-router-dom'
import { act } from '@testing-library/react'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import DialogModal from 'Components/UIComponents/DialogModal'
import { commonMethods } from 'Utils/commonMethods'
import Loader from 'Components/Loader'
import { ButtonComponent } from 'Components/UIComponents'

const Settings = ({ setChangesMade, index, setLoading }) => {
  const { t } = useTranslation()
  const location = useLocation()

  const initialState = {
    cqPostiveFontColor: false,
    cqNegativeFontColor: false,
    cqNeutralFontColor: false,
    defaultDateFormat: false,
    lastUpdatedRowColor: false,
    focusDurationAfterSubmit: false,
    fbsenderId: false,
    fbserverKey: false,
    newsImageDisplay: false,
    applicationLogo: false,
    menuLogo: false,
    defaultFlagUrl: false,
    flagUrl: false
  }
  const [editSettings, setEditSettings] = useState({
    editMode: false,
    submitButton: false,
    editIcon: true
  })
  const [userAppSetting, setUserAppSetting] = useState({})
  const [appSettingsData, setAppSettingsData] = useState({})
  const [error, setError] = useState(initialState)
  const [submitButtonDisable, setSubmitButtonDisable] = useState(true)

  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onConfirmation: () => {}, onSuccess: () => {} })

  useEffect(() => {
    getAllAppSetting(location.state)
  }, [])

  const getAllAppSetting = async () => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.appSetting()
      const data = response.data

      setUserAppSetting(data)
      setAppSettingsData(data)
    } catch {
      act(() => {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'error',
          message: t('NO_DATA_FOUND'),
          onRetry: commonMethods.handleRetry,
          onHide: handleDismiss
        })
      })
    } finally {
      act(() => {
        setLoading(false)
      })
    }
  }

  const updateAppSetting = async () => {
    setLoading(true)
    try {
      const updateAppSettingsData = {
        id: userAppSetting.id,
        cqPostiveFontColor: userAppSetting.cqPostiveFontColor,
        cqNegativeFontColor: userAppSetting.cqNegativeFontColor,
        cqNeutralFontColor: userAppSetting.cqNeutralFontColor,
        defaultDateFormat: userAppSetting.defaultDateFormat,
        lastUpdatedRowColor: userAppSetting.lastUpdatedRowColor,
        focusDurationAfterSubmit: userAppSetting.focusDurationAfterSubmit,
        fbsenderId: userAppSetting.fbsenderId,
        fbserverKey: userAppSetting.fbserverKey,
        showWorkbook: userAppSetting.showWorkbook,
        applicationLogo: userAppSetting.applicationLogo,
        newsImageDisplay: 6,
        showLanguageinMobile: userAppSetting.showLanguageinMobile,
        menuLogo: userAppSetting.menuLogo,
        showSignup: userAppSetting.showSignup,
        isProductionRazorPayKey: userAppSetting.isProductionRazorPayKey,
        showLanguageBar: userAppSetting.showLanguageBar,
        imageScrollingInterval: 8,
        allowEditNews: userAppSetting.allowEditNews,
        showSubscriptionEndDate: userAppSetting.showSubscriptionEndDate,
        showrenewal: userAppSetting.showrenewal,
        showValidTillDays: userAppSetting.showValidTillDays,
        allowEditProfile: userAppSetting.allowEditProfile,
        showShareButton: userAppSetting.showShareButton,
        showLoginThroughOtp: userAppSetting.showLoginThroughOtp,
        showAllNotification: userAppSetting.showAllNotification,
        flagUrl: userAppSetting.flagUrl,
        defaultFlagUrl: userAppSetting.defaultFlagUrl
      }

      const response = await apiAdapterCoffeeWeb.updateAppSettings(updateAppSettingsData)

      setAppSettingsData(updateAppSettingsData)

      if (response.data.statusCode === 200) {
        setChangesMade(null)
        setEditSettings({
          editIcon: true,
          submitButton: false
        })

        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: t('APP_SETTINGS_DATA_UPDATED'),
          onHide: handleDismiss,
          onSuccess: handleDismiss
        })
      }
    } catch {
      act(() => {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'error',
          message: t('APP_SETTINGS_DATA_UPDATE_FAIL'),
          onRetry: modalRetry,
          onHide: handleDismiss
        })
      })
    } finally {
      act(() => {
        setLoading(false)
      })
    }
  }

  const modalRetry = () => {
    setShowDialog({
      ...showDialog,
      showModal: false
    })
    setTimeout(() => {
      updateAppSetting()
    }, 300)
  }

  const handleSaveButton = () => {
    if (!hasErrors) {
      updateAppSetting()
    }
  }

  const handleChange = (name, value) => {
    setUserAppSetting((prevSetting) => ({
      ...prevSetting,
      [name]: value
    }))

    setError((prevErrState) => ({
      ...prevErrState,
      [name]: value === ''
    }))
    setSubmitButtonDisable(false)
    setChangesMade(index)
  }

  const handleEditButtonClick = () => {
    setEditSettings({
      editMode: !editSettings.editMode,
      editIcon: false,
      submitButton: true
    })

    setSubmitButtonDisable(true)
  }

  const handleReset = () => {
    setError(false)
    setEditSettings({
      editMode: false,
      submitButton: false,
      editIcon: true
    })
    setUserAppSetting(appSettingsData)
    setShowDialog({
      ...showDialog,
      showModal: false
    })
  }

  const handleResetConfirmationOpen = () => {
    document.body.style.overflow = 'hidden'
    setShowDialog({
      ...showDialog,
      showModal: true,
      modalType: 'info',
      message: t('ARE_YOU_SURE_YOU_WANT_TO_PROCEED'),
      onConfirmation: handleReset,
      onHide: handleDismiss
    })
  }

  const hasErrors = Object.values(error).some((value) => value)

  const onSubmit = (handleSaveButton) => {
    handleSaveButton
  }

  const handleDismiss = () => {
    setShowDialog({
      ...showDialog,
      showModal: false
    })
  }

  return (
    <>
      <div className="settings-container">
        <div className="edit-container">
          {editSettings.editMode ? (
            <ButtonComponent type={'reset'} onClick={handleResetConfirmationOpen} />
          ) : (
            <>
              <ButtonComponent type={'edit'} onClick={handleEditButtonClick} />
            </>
          )}
        </div>
        <div className="app-settings">
          <Form
            onSubmit={onSubmit}
            initialValues={userAppSetting}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className="p-fluid">
                <div className="grid-input">
                  <Field
                    name="positiveFontColor"
                    render={({ input }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText
                            id="positiveFontColor"
                            data-testid="positiveColorInput"
                            {...input}
                            disabled={!editSettings.editMode}
                            value={userAppSetting.cqPostiveFontColor || ''}
                            onChange={(e) => {
                              handleChange('cqPostiveFontColor', e.target.value)
                            }}
                            className={`${error.cqPostiveFontColor ? 'p-invalid custom-hover-color' : 'custom-hover-color'}`}
                          />

                          <label htmlFor="positiveFontColor" className={error.cqPostiveFontColor ? 'error-label' : ''}>
                            {t('POSITIVE_COLOR')}

                            <span className={`star ${editSettings.editMode ? '' : 'star-required'}`}> *</span>
                          </label>
                        </span>

                        <div className="error-message">{error.cqPostiveFontColor && <span className="error-message">{t('VALUE_IS_REQUIRED')}</span>}</div>
                      </div>
                    )}
                  />

                  <Field
                    name="negativeFontColor"
                    render={({ input }) => (
                      <div className="field">
                        <span className="p-float-label ">
                          <InputText id="negativeFontColor" data-testid="negativeColorInput" {...input} autoFocus value={userAppSetting.cqNegativeFontColor || ''} onChange={(e) => handleChange('cqNegativeFontColor', e.target.value)} className={`${error.cqNegativeFontColor ? 'p-invalid custom-hover-color' : 'custom-hover-color'}`} disabled={!editSettings.editMode} />

                          <label htmlFor="negativeFontColor" className={error.cqNegativeFontColor ? 'error-label' : ''}>
                            {t('NEGATIVE_COLOR')}
                            <span className={`star ${!editSettings.editMode ? 'star-required' : ''}`}> *</span>
                          </label>
                        </span>

                        <div className="error-message">{error.cqNegativeFontColor && <span className="error-message">{t('VALUE_IS_REQUIRED')}</span>}</div>
                      </div>
                    )}
                  />
                </div>

                <div className="grid-input">
                  <Field
                    name="neutralFontColor"
                    render={({ input }) => (
                      <div className="field">
                        <span className="p-float-label ">
                          <InputText id="neutralFontColor" data-testid="neutralColorInput" {...input} autoFocus value={userAppSetting.cqNeutralFontColor || ''} onChange={(e) => handleChange('cqNeutralFontColor', e.target.value)} disabled={!editSettings.editMode} className={`${error.cqNeutralFontColor ? 'p-invalid custom-hover-color' : 'custom-hover-color'}`} />

                          <label htmlFor="negativeFontColor" className={error.cqNeutralFontColor ? 'error-label' : ''}>
                            {t('NEUTRAL_COLOR')}

                            <span className={`star ${!editSettings.editMode ? 'star-required' : ''}`}> *</span>
                          </label>
                        </span>

                        <div className="error-message">{error.cqNeutralFontColor && <span className="error-message">{t('VALUE_IS_REQUIRED')}</span>}</div>
                      </div>
                    )}
                  />

                  <Field
                    name="dateFormat"
                    render={({ input }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText id="dateFormat" data-testid="dateFormatInput" {...input} value={userAppSetting.defaultDateFormat || ''} onChange={(e) => handleChange('defaultDateFormat', e.target.value)} disabled={!editSettings.editMode} className={`${error.defaultDateFormat ? 'p-invalid custom-hover-color' : 'custom-hover-color'}`} />

                          <label htmlFor="dateFormat" className={error.defaultDateFormat ? 'error-label' : ''}>
                            {t('DATE_FORMAT')} <span className={`star ${!editSettings.editMode ? 'star-required' : ''}`}> *</span>
                          </label>
                        </span>

                        <div className="error-message">{error.defaultDateFormat && <span className="error-message">{t('VALUE_IS_REQUIRED')}</span>}</div>
                      </div>
                    )}
                  />
                </div>
                <div className="grid-input">
                  <Field
                    name="rowColor"
                    render={({ input }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText id="rowColor" data-testid="rowColorInput" {...input} value={userAppSetting.lastUpdatedRowColor || ''} autoFocus onChange={(e) => handleChange('lastUpdatedRowColor', e.target.value)} disabled={!editSettings.editMode} className={`${error.lastUpdatedRowColor ? 'p-invalid custom-hover-color' : 'custom-hover-color'}`} />

                          <label htmlFor="rowColor" className={error.lastUpdatedRowColor ? 'error-label' : ''}>
                            {t('ROW_COLOR')} <span className={`star ${!editSettings.editMode ? 'star-required' : ''}`}> *</span>
                          </label>
                        </span>

                        <div className="error-message">{error.lastUpdatedRowColor && <span className="error-message">{t('VALUE_IS_REQUIRED')}</span>}</div>
                      </div>
                    )}
                  />

                  <Field
                    name="updateSubmit"
                    render={({ input }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText id="updateSubmit" data-testid="updateSubmitInput" {...input} value={userAppSetting.focusDurationAfterSubmit || ''} autoFocus onChange={(e) => handleChange('focusDurationAfterSubmit', e.target.value)} disabled={!editSettings.editMode} className={`${error.focusDurationAfterSubmit ? 'p-invalid custom-hover-color' : 'custom-hover-color'}`} />

                          <label htmlFor="updateSubmit" className={error.focusDurationAfterSubmit ? 'error-label' : ''}>
                            {t('UPDATE_SUBMIT')} <span className={`star ${!editSettings.editMode ? 'star-required' : ''}`}> *</span>
                          </label>
                        </span>

                        <div className="error-message">{error.focusDurationAfterSubmit && <span className="error-message">{t('VALUE_IS_REQUIRED')}</span>}</div>
                      </div>
                    )}
                  />
                </div>

                <div className="grid-input">
                  <Field
                    name="senderId"
                    render={({ input }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText id="senderId" data-testid="senderIDInput" {...input} value={userAppSetting.fbsenderId || ''} disabled={!editSettings.editMode} onChange={(e) => handleChange('fbsenderId', e.target.value)} className={`${error.fbsenderId ? 'p-invalid custom-hover-color' : 'custom-hover-color'}`} />
                          <label htmlFor="senderId" className={error.fbsenderId ? 'error-label' : ''}>
                            {t('SENDER_ID')}
                            <span className={`star ${!editSettings.editMode ? 'star-required' : ''}`}> *</span>
                          </label>
                        </span>

                        <div className="error-message">{error.fbsenderId && <span className="error-message">{t('VALUE_IS_REQUIRED')}</span>}</div>
                      </div>
                    )}
                  />

                  <Field
                    name="serverKey"
                    render={({ input }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText id="serverKey" data-testid="serverKeyInput" {...input} value={userAppSetting.fbserverKey || ''} autoFocus onChange={(e) => handleChange('fbserverKey', e.target.value)} disabled={!editSettings.editMode} className={`${error.fbserverKey ? 'p-invalid custom-hover-color' : 'custom-hover-color'}`} />
                          <label htmlFor="serverKey" className={error.fbserverKey ? 'error-label' : ''}>
                            {t('SERVER_KEY')}
                            <span className={`star ${!editSettings.editMode ? 'star-required' : ''}`}> *</span>
                          </label>
                        </span>

                        <div className="error-message">{error.fbserverKey && <span className="error-message">{t('VALUE_IS_REQUIRED')}</span>}</div>
                      </div>
                    )}
                  />
                </div>
                <div className="grid-input">
                  <Field
                    name="applicationLogo"
                    render={({ input }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText id="applicationLogo" data-testid="applicationLogoInput" {...input} value={userAppSetting.applicationLogo || ''} autoFocus onChange={(e) => handleChange('applicationLogo', e.target.value)} disabled={!editSettings.editMode} className={`${error.applicationLogo ? 'p-invalid custom-hover-color' : 'custom-hover-color'}`} />
                          <label htmlFor="applicationLogo" className={error.applicationLogo ? 'error-label' : ''}>
                            {t('APPLICATION_LOGO')}
                            <span className={`star ${!editSettings.editMode ? 'star-required' : ''}`}> *</span>
                          </label>
                        </span>

                        <div className="error-message">{error.applicationLogo && <span className="error-message">{t('VALUE_IS_REQUIRED')}</span>}</div>
                      </div>
                    )}
                  />
                  <Field
                    name="menuLogo"
                    render={({ input }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText id="menuLogo" data-testid="menuLogoInput" {...input} value={userAppSetting.menuLogo || ''} autoFocus onChange={(e) => handleChange('menuLogo', e.target.value)} disabled={!editSettings.editMode} className={`${error.menuLogo ? 'p-invalid custom-hover-color' : 'custom-hover-color'}`} />
                          <label htmlFor="menuLogo" className={error.fbserverKey ? 'error-label' : ''}>
                            {t('MENU_LOGO')} <span className={`star ${!editSettings.editMode ? 'star-required' : ''}`}> *</span>
                          </label>
                        </span>

                        <div className="error-message">{error.menuLogo && <span className="error-message">{t('VALUE_IS_REQUIRED')}</span>}</div>
                      </div>
                    )}
                  />
                </div>
                <div className="grid-input">
                  <Field
                    name="defaultLogo"
                    render={({ input }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText id="defaultLogo" data-testid="defaultLogoInput" {...input} value={userAppSetting.defaultFlagUrl || ''} autoFocus onChange={(e) => handleChange('defaultFlagUrl', e.target.value)} disabled={!editSettings.editMode} className={`${error.defaultFlagUrl ? 'p-invalid custom-hover-color' : 'custom-hover-color'}`} />
                          <label htmlFor="defaultLogo" className={error.defaultFlagUrl ? 'error-label' : ''}>
                            {t('DEFAULT_LOGO')}
                            <span className={`star ${!editSettings.editMode ? 'star-required' : ''}`}> *</span>
                          </label>
                        </span>

                        <div className="error-message">{error.defaultFlagUrl && <span className="error-message">{t('VALUE_IS_REQUIRED')}</span>}</div>
                      </div>
                    )}
                  />

                  <Field
                    name="flagUrl"
                    render={({ input }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <InputText id="flagUrl" data-testid="flagUrlInput" {...input} value={userAppSetting.flagUrl || ''} autoFocus onChange={(e) => handleChange('flagUrl', e.target.value)} disabled={!editSettings.editMode} className={`${error.flagUrl ? 'p-invalid custom-hover-color' : 'custom-hover-color'}`} />
                          <label htmlFor="flagUrl" className={error.flagUrl ? 'error-label' : ''}>
                            {t('FLAG_URL')}
                            <span className={`star ${!editSettings.editMode ? 'star-required' : ''}`}> *</span>
                          </label>
                        </span>

                        <div className="error-message">{error.flagUrl && <span className="error-message">{t('VALUE_IS_REQUIRED')}</span>}</div>
                      </div>
                    )}
                  />
                </div>

                <div className="app-settings-button-container">
                  {editSettings.submitButton && (
                    <div className="app-settings-buttons">
                      <ButtonComponent type={'cancel'} onClick={handleReset} />
                      <ButtonComponent type={'submit'} onClick={handleSaveButton} disabled={submitButtonDisable || hasErrors} />
                    </div>
                  )}
                </div>
              </form>
            )}
          />
        </div>
      </div>
      <DialogModal {...showDialog} onDismiss={handleDismiss} />
    </>
  )
}

export default Loader(Settings)
