import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { InputSwitch } from 'primereact/inputswitch'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { commonMethods } from 'Utils/commonMethods'
import DialogModal from 'Components/UIComponents/DialogModal'
import { ButtonComponent } from 'Components/UIComponents'
import Loader from 'Components/Loader'

const FeatureToggles = ({ ...props }) => {
  const { setChangesMade, index, setLoading } = props
  const { t } = useTranslation()
  const location = useLocation()

  const [userToggleSettings, setUserToggleSettings] = useState({
    allowEditNews: false,
    showrenewal: false,
    showValidTillDays: false,
    allowEditProfile: false,
    showShareButton: false,
    showLoginThroughOtp: false,
    showAllNotification: true,
    showWorkbook: false,
    showLanguageinMobile: false
  })
  const [editSettings, setEditSettings] = useState({
    editMode: false,
    submitButton: false,
    workbookEditIcon: true,
    toggle: false,
    editText: false
  })
  const [appSettingsData, setAppSettingsData] = useState({})
  const [submitDisable, setSubmitDisable] = useState(false)

  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onConfirmation: () => {}, onSuccess: () => {} })

  useEffect(() => {
    getAllAppSetting(location.state)
  }, [])

  const getAllAppSetting = async () => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.appSetting()
      const data = response.data

      setUserToggleSettings(data)
      setAppSettingsData(data)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('NO_DATA_FOUND'),
        onRetry: commonMethods.handleRetry,
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const updateAppSetting = async () => {
    setLoading(true)
    setSubmitDisable(false)
    try {
      const updateAppSettingsData = {
        id: userToggleSettings.id,
        cqPostiveFontColor: userToggleSettings.cqPostiveFontColor,
        cqNegativeFontColor: userToggleSettings.cqNegativeFontColor,
        cqNeutralFontColor: userToggleSettings.cqNeutralFontColor,
        defaultDateFormat: userToggleSettings.defaultDateFormat,
        lastUpdatedRowColor: userToggleSettings.lastUpdatedRowColor,
        focusDurationAfterSubmit: userToggleSettings.focusDurationAfterSubmit,
        fbsenderId: userToggleSettings.fbsenderId,
        fbserverKey: userToggleSettings.fbserverKey,
        showWorkbook: userToggleSettings.showWorkbook,
        applicationLogo: userToggleSettings.applicationLogo,
        newsImageDisplay: 6,
        showLanguageinMobile: userToggleSettings.showLanguageinMobile,
        menuLogo: userToggleSettings.menuLogo,
        showSignup: userToggleSettings.showSignup,
        isProductionRazorPayKey: userToggleSettings.isProductionRazorPayKey,
        showLanguageBar: userToggleSettings.showLanguageBar,
        imageScrollingInterval: 8,
        allowEditNews: userToggleSettings.allowEditNews,
        showSubscriptionEndDate: userToggleSettings.showSubscriptionEndDate,
        showrenewal: userToggleSettings.showrenewal,
        showValidTillDays: userToggleSettings.showValidTillDays,
        allowEditProfile: userToggleSettings.allowEditProfile,
        showShareButton: userToggleSettings.showShareButton,
        showLoginThroughOtp: userToggleSettings.showLoginThroughOtp,
        showAllNotification: userToggleSettings.showAllNotification,
        flagUrl: userToggleSettings.flagUrl,
        defaultFlagUrl: userToggleSettings.defaultFlagUrl
      }

      const response = await apiAdapterCoffeeWeb.updateAppSettings(updateAppSettingsData)

      setAppSettingsData(updateAppSettingsData)
      if (response.data.statusCode === 200) {
        setChangesMade(null)

        setEditSettings({
          submitButton: false
        })

        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: t('SERVICE_UPDATE_SUCCESS'),
          onHide: handleDismiss,
          onSuccess: handleDismiss
        })
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('SERVICE_UPDATE_FAILED'),
        onRetry: modalRetry,
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const modalRetry = () => {
    setShowDialog({
      ...showDialog,
      showModal: false
    })
    setTimeout(() => {
      updateAppSetting()
    }, 300)
  }

  const handleChange = (setting) => {
    setUserToggleSettings({
      ...userToggleSettings,
      [setting]: !userToggleSettings[setting]
    })
    setEditSettings({ ...editSettings, toggle: true })
    setSubmitDisable(true)
    setChangesMade(index)
  }

  const handleResetConfirmationOpen = () => {
    document.body.style.overflow = 'hidden'
    setShowDialog({
      ...showDialog,
      showModal: true,
      modalType: 'info',
      message: t('ARE_YOU_SURE_YOU_WANT_TO_PROCEED'),
      onConfirmation: handleReset,
      onHide: handleDismiss
    })
  }

  const handleReset = () => {
    setEditSettings({
      editMode: false,
      submitButton: false
    })
    setUserToggleSettings(appSettingsData)
    setSubmitDisable(false)
    setShowDialog({
      ...showDialog,
      showModal: false
    })
    setChangesMade(false)
  }
  const handleDismiss = () => {
    setShowDialog({
      ...showDialog,
      showModal: false
    })
  }

  const settings = [
    {
      id: 'showSignup',
      label: t('SHOW_SIGN_UP')
    },
    {
      id: 'showShareButton',
      label: t('SHOW_SHARE_BTN')
    },
    {
      id: 'showLanguageBar',
      label: t('SHOW_LANGUAGE_BAR')
    },
    {
      id: 'allowEditNews',
      label: t('ALLOW_EDIT_NEWS')
    },
    {
      id: 'showSubscriptionEndDate',
      label: t('SHOW_SUBSCRIPTION_END_DATE')
    },
    {
      id: 'showrenewal',
      label: t('SHOW_RENEWAL')
    },
    {
      id: 'allowEditProfile',
      label: t('ALLOW_EDIT_PROFILE')
    },
    {
      id: 'showValidTillDays',
      label: t('SHOW_VALID_TILL_DAYS')
    },
    {
      id: 'showLoginThroughOtp',
      label: t('SHOW_LOGIN_OTP')
    },
    {
      id: 'showAllNotification',
      label: t('NOTIFICATION')
    },
    {
      id: 'showWorkbook',
      label: t('SHOW_WORK_BOOK')
    },
    {
      id: 'showLanguageinMobile',
      label: t('SHOW_LANGUAGE')
    },
    {
      id: 'isProductionRazorPayKey',
      label: t('PRODUCTION_RAZOR_PAY_KEY')
    }
  ]

  return (
    <div className="feature-toggle">
      <DataTable value={settings}>
        <Column field="label" className="options" header={t('OPTIONS')} />
        <Column field="id" header={t('STATUS')} body={(rowData) => <InputSwitch checked={userToggleSettings[rowData.id]} onChange={() => handleChange(rowData.id)} className="toggle-button" />} />
      </DataTable>

      <div className="feature-settings-buttons">
        <ButtonComponent type={'reset'} onClick={handleResetConfirmationOpen} />
        <ButtonComponent type={'submit'} onClick={updateAppSetting} disabled={!submitDisable} />
      </div>
      <DialogModal {...showDialog} onDismiss={handleDismiss} />
    </div>
  )
}

export default Loader(FeatureToggles)
