import './styles.scss'
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Chart } from 'primereact/chart'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import { TABLE_CONST } from 'Utils/Constants'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import LayoutContainer from 'Components/LayoutComponent'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import Loader from 'Components/Loader'
import { commonMethods } from 'Utils/commonMethods'

const UserStats = ({ setLoading }) => {
  const { t } = useTranslation()

  let rowCount = 7
  let paginationButtons = TABLE_CONST.paginationButtonCount
  const staticColorsForDifferentDivices = ['#50b3f6', 'red', 'blue', 'gray']

  const [chartData, setChartData] = useState({})
  const [chartOptions, setChartOptions] = useState({})
  const [subscriptionList, setSubscriptionList] = useState([])
  const [selectedSubscription, setSelectedSubscription] = useState(null)
  const [totalUsersCount, setTotalUsersCount] = useState(null)
  const [userList, setUserList] = useState([])
  const [filteredUserList, setFilteredUserList] = useState([])
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [stackedBarData, setStackedBarData] = useState({})
  const [stackedBarOptions, setStackedBarOptions] = useState({})
  const [fileName, setFileName] = useState('')
  const [showTableMessage, setShowTableMessage] = useState(false)
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', modalCancel: '', onHide: () => {} })

  const columns = [
    { field: selectedSubscription?.subscriptionId === 0 ? 'serialNumber' : 'subUniqueNumber', header: `${t('SL')}#` },
    { field: 'userName', header: t('USER_NAME') },
    { field: 'country', header: t('COUNTRY') },
    { field: 'subscriptionName', header: t('SUBSCRIPTION_NAME') },
    { field: 'occupation', header: t('OCCUPATION') },
    { field: 'deviceUsed', header: t('DEVICE_USED') },
    { field: 'customCreatedOn', header: t('CREATED_ON') },
    { field: 'userRegistered', header: t('USER_REGISTERED') },
    { field: 'customSubEndDate', header: t('SUBSCRIPTION_EXPIRY_DATE') }
  ]

  const options = {
    plugins: {
      legend: {
        labels: {
          usePointStyle: true
        }
      }
    }
  }

  useEffect(() => {
    getUserStats()
    setChartOptions(options)

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    if (selectedSubscription) {
      if (selectedSubscription.name === 'All') {
        setFileName(`${t('USER_STATS_REPORT')}_${getCurrentDate()}`)
      } else {
        const subscriptionName = selectedSubscription.name

        setFileName(`${subscriptionName}_${t('STATS_REPORT')}_${getCurrentDate()}`)
      }
    }
  }, [selectedSubscription])

  if (windowSize < TABLE_CONST.maxWidthTab) {
    rowCount = 8
    paginationButtons = TABLE_CONST.mobilePaginationButtonCount
  }

  //   Get user stats along with subscription detail
  const getUserStats = async () => {
    setLoading(true)
    setShowTableMessage(true)
    try {
      const { data: response } = await apiAdapterCoffeeWeb.getAllUserListReport()
      const { subscription, totalUserCount, userlist } = response.returnLst

      setUserList(userlist)
      setFilteredUserList(userlist)

      const modifiedSubscriptionList = subscription.map((ele) => ({ ...ele, name: ele.subscriptionName }))

      const allDropDownObject = [
        {
          name: 'All',
          subscriptionName: 'All',
          subscriptionColor: '',
          subscriptionUserCount: totalUserCount,
          subscriptionId: 0
        }
      ]

      setSubscriptionList([...allDropDownObject, ...modifiedSubscriptionList])
      getChartInfo(modifiedSubscriptionList)
      setSelectedSubscription(allDropDownObject[0])
      setTotalUsersCount(totalUserCount)

      getSubscriptionStatsDetails(subscription)
    } catch (err) {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: 'NO_DATA_FOUND',
        modalCancel: false,
        onHide: commonMethods.handleRetry
      })
    } finally {
      setLoading(false)
      setShowTableMessage(false)
    }
  }

  //   Get charts information
  const getChartInfo = (list) => {
    const data = {
      labels: getChartLabels(list),
      datasets: [
        {
          data: getChartCount(list),
          backgroundColor: getChartColors(list)
        }
      ]
    }

    setChartData(data)
  }

  //   Get user counts for chart
  const getChartCount = (list) => {
    const userCounts = list.map(({ subscriptionUserCount }) => subscriptionUserCount)

    return userCounts
  }

  //   Get chart labeling
  const getChartLabels = (list) => {
    const chartLabelings = list.map(({ subscriptionName }) => subscriptionName)

    return chartLabelings
  }

  //   Get chart colors based on subscription
  const getChartColors = (list) => {
    const colorCodes = list.map(({ subscriptionColor }) => {
      if (subscriptionColor) {
        return subscriptionColor.split(',')[0].trim(' ')
      }

      return 'lightgray'
    })

    return colorCodes
  }

  //   Get badge color based on subscription
  const getIndividualColor = (subscriptionColor) => {
    if (subscriptionColor) {
      return {
        color: subscriptionColor.split(',')[0].trim(' ')
      }
    }

    return { color: 'black' }
  }

  // Handle change subscription dropdown
  const handleChangeSubscription = (item) => {
    const { subscriptionId: selectedSubscriptionId } = item
    const filterdUserList = userList?.filter(({ subscriptionId }) => subscriptionId === selectedSubscriptionId)

    setFilteredUserList(selectedSubscriptionId === 0 ? userList : filterdUserList)
    setSelectedSubscription(item)
  }

  const handleWindowResize = () => setWindowSize(window.innerWidth)

  const handleDismiss = () => setShowDialog({ ...showDialog, showModal: false })

  const getStats = (list) => {
    const arrayList = []

    const colors = staticColorsForDifferentDivices
    const allSubscription = list?.forEach((ele) => {
      ele.userDevices.forEach((ele) => {
        arrayList.push(ele)
      })
    })

    const newArray = []

    const statsArray = arrayList?.forEach(({ device, usersCount }) => {
      const isPresent = newArray?.some(({ label }) => label === device)

      if (!isPresent) {
        const object = {
          type: 'bar',
          label: device,
          backgroundColor: colors[newArray?.length],
          data: [usersCount]
        }

        newArray.push(object)
      } else {
        const foundObject = newArray.find((item) => item.label === device)

        foundObject.data.push(usersCount)
      }
    })

    return newArray
  }

  const getSubscriptionStatsDetails = (list) => {
    const documentStyle = getComputedStyle(document.documentElement)
    const textColor = documentStyle.getPropertyValue('--text-color')
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary')
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border')

    const data = {
      labels: list.map(({ subscriptionName }) => subscriptionName),
      datasets: getStats(list)
    }
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        tooltips: {
          mode: 'index',
          intersect: false
        },
        legend: {
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder
          }
        },
        y: {
          stacked: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder
          }
        }
      }
    }

    setStackedBarData(data)
    setStackedBarOptions(options)
  }

  const getCurrentDate = () => {
    const date = new Date()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const year = date.getFullYear()

    return `${month}-${day}-${year}`
  }

  const tableResponseMessage = () => <div>{showTableMessage ? t('FETCHING') : t('NO_RESULTS_FOUND')}</div>

  return (
    <div>
      <LayoutContainer title={'USER_STATS'} breadCrumbsTitle={'userStats'}>
        <div className="user-stats-wrapper">
          <div className="user-stats-contrainer">
            <div className="cards pie-chart-user-detail">
              <div className="pie-chart">
                <Chart type="pie" data={chartData} options={chartOptions} />
              </div>
              <div className="sub-counts">
                <div className="total-count">
                  <div className="count">{totalUsersCount}</div>
                  <div className="labeling">{t('TOTAL_USERS')}</div>
                </div>
                <div className="user-details">
                  {subscriptionList?.map(({ subscriptionName, subscriptionColor, subscriptionUserCount, subscriptionId }, index) => (
                    <>
                      {subscriptionId !== 0 && (
                        <div key={index} className="each-subscription">
                          <div className="count" style={getIndividualColor(subscriptionColor)}>
                            {subscriptionUserCount}
                          </div>
                          <div className="labeling">{subscriptionName}</div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>
            <div>
              <div className="cards">
                <Chart type="bar" data={stackedBarData} options={stackedBarOptions} />
              </div>
            </div>
          </div>
          <div className="table-actions">
            <Dropdown value={selectedSubscription} className="subscription-dropdown" onChange={(e) => handleChangeSubscription(e.value)} options={subscriptionList} optionLabel="name" placeholder={t('SELECT_SUBSCRIPTION')} />
            <CSVLink data={filteredUserList.map(({ subscriptionId, ...rest }) => rest)} filename={fileName}>
              <ButtonComponent type="custom" label={t('DOWNLOAD_REPORTS')} icon="pi pi-download" variant="action" />
            </CSVLink>
          </div>
          <DataTable value={filteredUserList} className="card" paginator={filteredUserList.length > 0} pageLinkSize={paginationButtons} rows={rowCount} emptyMessage={tableResponseMessage} scrollable scrollHeight="60vh">
            {columns.map(({ field, header, className, rowReorder, body }) => (
              <Column rowReorder={rowReorder} key={field} className={className} field={field} header={header} body={body} />
            ))}
          </DataTable>
        </div>
        <DialogModal {...showDialog} onDismiss={handleDismiss} onRetry={commonMethods.handleRetry} />
      </LayoutContainer>
    </div>
  )
}

export default Loader(UserStats)
