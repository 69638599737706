import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import { Form, Field } from 'react-final-form'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import { Calendar } from 'primereact/calendar'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { commonMethods } from 'Utils/commonMethods'
import Validation from 'Utils/Validation'
import LayoutContainer from 'Components/LayoutComponent'
import DialogModal from 'Components/UIComponents/DialogModal'
import Loader from 'Components/Loader'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { ButtonComponent } from 'Components/UIComponents'

const EditTerminalDetails = ({ setLoading }) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const history = useHistory()

  const [terminalDetails, setTerminalDetails] = useState({
    id: '',
    idMarket: '',
    marketName: '',
    contractName: '',
    optionExpiry: '',
    firstNoticeDay: '',
    terminalId: '',
    isActive: true,
    _52weeksHighRate: '',
    _52weeksLowRate: '',
    _52weeksHighDate: '',
    _52weeksLowDate: ''
  })
  const [submitButtonDisable, setSubmitButtonDisable] = useState(true)
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', modalCancel: '', onRetry: '', onHide: () => {} })

  useEffect(() => {
    getAllTerminalDetailsById()
  }, [])

  const getAllTerminalDetailsById = async () => {
    setLoading(true)
    try {
      const terminalDetails = await apiAdapterCoffeeWeb.getTerminalDetails(id)

      setTerminalDetails(terminalDetails.data.returnLst)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: 'NO_DATA_FOUND',
        modalCancel: false,
        onHide: commonMethods.handleRetry,
        onRetry: commonMethods.handleRetry
      })
    } finally {
      setLoading(false)
    }
  }

  const updateTerminalDetails = async () => {
    const { id, optionExpiry, firstNoticeDay, _52weeksHighRate, _52weeksLowRate, _52weeksHighDate, _52weeksLowDate } = terminalDetails

    setLoading(true)
    try {
      const { status } = await apiAdapterCoffeeWeb.putTerminalDetail({
        id,
        optionExpiry,
        firstNoticeDay,
        _52weeksHighRate,
        _52weeksLowRate,
        _52weeksHighDate,
        _52weeksLowDate
      })

      if (status === 200) {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: 'TERMINAL_DETAILS_UPDATED_SUCCESSFULLY',
          modalCancel: false,
          onHide: handleSuccess
        })
      }
    } catch {
      setLoading(false)
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: 'TERMINAL_DATA_UPDATE_FAIL',
        modalCancel: false,
        onHide: initialForm,
        onRetry: postRetry
      })
    } finally {
      setLoading(false)
    }
  }

  const putTerminalDetails = () => {
    const { optionExpiry, firstNoticeDay, _52weeksHighDate, _52weeksHighRate, _52weeksLowDate, _52weeksLowRate } = terminalDetails

    if (optionExpiry && firstNoticeDay && _52weeksHighDate && _52weeksHighRate && _52weeksLowDate && _52weeksLowRate) {
      updateTerminalDetails()
    }
  }
  const handleChange = (event) => {
    const { name, value } = event.target

    setSubmitButtonDisable(false)

    if (name === '_52weeksHighRate' || name === '_52weeksLowRate') {
      if (Validation.isInputFieldEmpty(value)) {
        setSubmitButtonDisable(true)
      }

      setTerminalDetails((prevData) => ({
        ...prevData,
        [name]: value
      }))
    }
  }

  const optionExpiryDateChange = (changedDateAndTime, field) => {
    if (changedDateAndTime) {
      const selectedDate = changedDateAndTime.value

      if (selectedDate) {
        const formattedDate = formatDate(selectedDate)

        setTerminalDetails((prevState) => ({
          ...prevState,
          [field]: formattedDate
        }))
        setSubmitButtonDisable(false)
      } else {
        setTerminalDetails((prevState) => ({
          ...prevState,
          [field]: ''
        }))

        const fieldsToDisableSubmit = ['optionExpiry', 'firstNoticeDay', '_52weeksHighDate', '_52weeksHighRate', '_52weeksLowDate', '_52weeksLowRate']

        if (fieldsToDisableSubmit.includes(field)) {
          setSubmitButtonDisable(true)
        }
      }
    }
  }

  const formatDate = (date) => {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
  }

  const onSubmit = (data) => {
    setTerminalDetails(data)
  }

  const handleResetButtonClick = () => {
    history.push(ROUTE_STRINGS.listTerminalDetails)
  }
  const initialForm = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  const postRetry = () => {
    setShowDialog({ ...showDialog, showModal: false })
    setTimeout(() => {
      updateTerminalDetails()
    }, 300)
    setLoading(true)
    setLoading(false)
  }

  const handleDismiss = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  const handleSuccess = () => {
    history.push(ROUTE_STRINGS.listTerminalDetails)
    setShowDialog({ ...showDialog, showModal: false })
  }

  const requiredFields = {
    optionExpiry: t('TERMINAL_DETAILS_ERROR_MESSAGE', { dataField: 'Option Expiry' }),
    firstNoticeDay: t('TERMINAL_DETAILS_ERROR_MESSAGE', { dataField: 'First Notice Day' }),
    _52weeksHighRate: t('WEEKS_HIGH_AND_LOW_ERROR_MESSAGE', { dataField: 'High Rate' }),
    _52weeksHighDate: t('WEEKS_HIGH_AND_LOW_ERROR_MESSAGE', { dataField: 'High Date' }),
    _52weeksLowRate: t('WEEKS_HIGH_AND_LOW_ERROR_MESSAGE', { dataField: 'Low Rate' }),
    _52weeksLowDate: t('WEEKS_HIGH_AND_LOW_ERROR_MESSAGE', { dataField: 'Low Date' })
  }

  const validate = (data) => {
    const errors = {}

    Object.keys(requiredFields).forEach((field) => {
      if (!data[field]) {
        errors[field] = requiredFields[field]
      }
    })

    return errors
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  return (
    <LayoutContainer title={'EDIT TERMINAL DETAILS'} breadCrumbsTitle={'editTerminal'}>
      <div className="edit-terminal-form">
        <Form
          onSubmit={onSubmit}
          initialValues={terminalDetails}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="p-fluid">
              <Field
                name="marketName"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <InputText disabled {...input} id="marketName" name="marketName" value={terminalDetails.marketName} onChange={handleChange} />
                      <label htmlFor="marketName" className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                        {t('MARKET_TYPE')} <span className="span-text-color">*</span>
                      </label>
                    </span>
                  </div>
                )}
              />
              <Field
                name="contractName"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <InputText disabled id="contractName" {...input} name="contractName" value={terminalDetails.contractName} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
                      <label htmlFor="contractName">
                        {t('CONTRACT_NAME')} <span className="span-text-color">*</span>
                      </label>
                    </span>
                  </div>
                )}
              />

              <Field
                name="optionExpiry"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <Calendar {...input} id="optionExpiry" name="optionExpiry" value={new Date(terminalDetails.optionExpiry)} onChange={(date) => optionExpiryDateChange(date, 'optionExpiry')} dateFormat="dd/mm/yy" showIcon className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
                      <label htmlFor="optionExpiry" className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                        {t('OPTION_EXPIRY')} <span className="span-text-color">*</span>
                      </label>
                    </span>
                    <div className="error-message"> {getFormErrorMessage(meta)}</div>
                  </div>
                )}
              />

              <Field
                name="firstNoticeDay"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <Calendar {...input} id="firstNoticeDay" name="firstNoticeDay" value={new Date(terminalDetails.firstNoticeDay)} onChange={(newValue) => optionExpiryDateChange(newValue, 'firstNoticeDay')} dateFormat="dd/mm/yy" showIcon className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
                      <label htmlFor="firstNoticeDay" className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                        {t('FIRST_NOTICE_DAY')} <span className="span-text-color">*</span>
                      </label>
                    </span>
                    <div className="error-message"> {getFormErrorMessage(meta)}</div>
                  </div>
                )}
              />

              <Field
                name="terminalId"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <InputText disabled {...input} name="terminalId" value={terminalDetails.terminalId} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />

                      <label htmlFor="terminalId" className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                        {t('TERMINAL_ID')} <span className="span-text-color">*</span>
                      </label>
                    </span>
                  </div>
                )}
              />

              <Field
                name="_52weeksHighRate"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <InputText
                        {...input}
                        id="_52weeksHighRate"
                        name="_52weeksHighRate" // eslint-disable-next-line no-underscore-dangle
                        value={terminalDetails._52weeksHighRate}
                        onChange={handleChange}
                        className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')}
                      />
                      <label htmlFor="_52weeksHighRate" className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                        {t('WEEKS_HIGH_RATE')} <span className="span-text-color">*</span>
                      </label>
                    </span>
                    <div className="error-message"> {getFormErrorMessage(meta)}</div>
                  </div>
                )}
              />

              <Field
                name="_52weeksHighDate"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <Calendar
                        {...input}
                        id="_52weeksHighDate"
                        name="_52weeksHighDate" // eslint-disable-next-line no-underscore-dangle
                        value={new Date(terminalDetails._52weeksHighDate)}
                        onChange={(newValue) => optionExpiryDateChange(newValue, '_52weeksHighDate')}
                        dateFormat="dd/mm/yy"
                        showIcon
                        className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')}
                      />
                      <label htmlFor="_52weeksHighDate" className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                        {t('WEEKS_HIGH_DATE')} <span className="span-text-color">*</span>
                      </label>
                    </span>
                    <div className="error-message"> {getFormErrorMessage(meta)}</div>
                  </div>
                )}
              />
              <Field
                name="_52weeksLowRate"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <InputText
                        {...input}
                        id="_52weeksLowRate"
                        name="_52weeksLowRate" // eslint-disable-next-line no-underscore-dangle
                        value={terminalDetails._52weeksLowRate}
                        onChange={handleChange}
                        className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')}
                      />
                      <label htmlFor="_52weeksLowRate" className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                        {t('WEEKS_LOW_RATE')} <span className="span-text-color">*</span>
                      </label>
                    </span>
                    <div className="error-message"> {getFormErrorMessage(meta)}</div>
                  </div>
                )}
              />

              <Field
                name="_52weeksLowDate"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <Calendar
                        {...input}
                        id="_52weeksLowDate"
                        name="_52weeksLowDate" // eslint-disable-next-line no-underscore-dangle
                        value={new Date(terminalDetails._52weeksLowDate)}
                        onChange={(newValue) => optionExpiryDateChange(newValue, '_52weeksLowDate')}
                        dateFormat="dd/mm/yy"
                        showIcon
                        className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')}
                      />
                      <label htmlFor="_52weeksLowDate" className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                        {t('WEEKS_LOW_DATE')}
                        <span className="span-text-color">*</span>
                      </label>
                    </span>

                    <div className="error-message"> {getFormErrorMessage(meta)}</div>
                  </div>
                )}
              />
            </form>
          )}
        />
        <div className="button-container">
          <ButtonComponent type="cancel" onClick={handleResetButtonClick} />
          <ButtonComponent onClick={putTerminalDetails} type="submit" disabled={submitButtonDisable} />
        </div>
        <DialogModal {...showDialog} onDismiss={handleDismiss} onSuccess={handleSuccess} />
      </div>
    </LayoutContainer>
  )
}

export default Loader(EditTerminalDetails)
