import './styles.scss'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TabView, TabPanel } from 'primereact/tabview'
import { useHistory, useLocation } from 'react-router-dom'
import { ROUTE_STRINGS, TABLE_CONST, TAB_MENUS } from 'Utils/Constants'
import { Button } from 'primereact/button'
import { InputSwitch } from 'primereact/inputswitch'
import { PreviewIcon } from 'Assets/Icons'
import DialogModal from 'Components/UIComponents/DialogModal'
import { getLocalUserDetails } from 'Utils/LocalStorageHandler'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import LayoutContainer from 'Components/LayoutComponent'
import Loader from 'Components/Loader'
import ClientUser from './Client'
import OtherUser from './Others'

const ListUsers = ({ setLoading }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)

  const userDetails = getLocalUserDetails()

  let rowCount = 7
  let paginationButtons = TABLE_CONST.paginationButtonCount

  const [clientData, setClientData] = useState([])
  const [othersData, setOthersData] = useState([])
  const [clientFilterData, setClientFilterData] = useState([])
  const [othersFilterData, setOthersFilterData] = useState([])
  const [selectedRowData, setSelectedRowData] = useState({})
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [modalState, setModalState] = useState({
    showModal: false,
    hardAction: '',
    softAction: '',
    modalType: '',
    message: '',
    onHide: () => {},
    onDismiss: () => {},
    onRetry: () => {},
    onConfirmation: () => {}
  })

  const initialActiveTab = urlParams.get('tab') || TAB_MENUS.clientsRoute
  const [activeTab, setActiveTab] = useState(initialActiveTab)

  const columns = [
    {
      dataField: 'fullName',
      text: t('USER'),
      sortable: true,
      className: 'full-name-column'
    },
    {
      dataField: 'subscriptionTypeName',
      text: t('SUBSCRIPTION'),
      sortable: false,
      className: 'subscription-type-column'
    },
    {
      dataField: 'occupationName',
      text: t('OCCUPATION'),
      sortable: true,
      className: 'occupation-column'
    },
    {
      dataField: 'userRoleName',
      text: t('USER_ROLE'),
      sortable: false,
      className: 'user-role-column'
    },
    {
      dataField: 'phone',
      text: t('PHONE'),
      sortable: false,
      className: 'phone-column'
    },
    {
      dataField: 'email',
      text: t('EMAIL'),
      sortable: false,
      className: 'email-column'
    },
    {
      dataField: 'states',
      text: t('STATES'),
      sortable: false,
      className: 'state-column'
    },
    {
      dataField: 'countryName',
      text: t('COUNTRY'),
      sortable: false,
      className: 'country-column'
    }
  ]

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    const params = new URLSearchParams()

    params.set('tab', activeTab)
    history.replace({ search: params.toString() })
  }, [activeTab, history])

  if (windowSize < TABLE_CONST.maxWidthTab) {
    rowCount = 8
    paginationButtons = TABLE_CONST.mobilePaginationButtonCount
  }

  const toggleUserStatus = async (userState) => {
    setModalState((previousState) => ({
      ...previousState,
      showModal: false,
      modalType: '',
      modalMessage: '',
      hardAction: '',
      softAction: ''
    }))
    setLoading(true)
    const { id } = userState

    try {
      let modifiedUserData
      let modifiedFilteredData

      if (userState.isActive) {
        await apiAdapterCoffeeWeb.putDeactivateAccount({ id, userId: userDetails.id })
      } else {
        await apiAdapterCoffeeWeb.putActivateAccount({ id })
      }

      const updateData = (data) => data.map((element) => (element.id === userState.id ? { ...element, isActive: !userState.isActive } : element))

      if (activeTab === TAB_MENUS.clientsRoute) {
        modifiedUserData = updateData(clientData, clientFilterData)
        modifiedFilteredData = updateData(clientFilterData, clientFilterData)
      } else {
        modifiedUserData = updateData(othersData, othersFilterData)
        modifiedFilteredData = updateData(othersFilterData, othersFilterData)
      }

      if (activeTab === TAB_MENUS.clientsRoute) {
        setClientData(modifiedUserData)
        setClientFilterData(modifiedFilteredData)
      } else {
        setOthersData(modifiedUserData)
        setOthersFilterData(modifiedFilteredData)
      }
    } catch {
      setModalState((previousState) => ({
        ...previousState,
        showModal: true,
        modalType: 'error',
        message: 'USER_ACCOUNT_STATUS_CHANGE_FAIL',
        onDismiss: handleDismiss,
        onHide: handleDismiss,
        onRetry: () => toggleUserStatus(selectedRowData)
      }))
    } finally {
      setLoading(false)
    }
  }

  const editUserRedirect = (rowData) => {
    const tabParam = urlParams.get('tab')

    history.push({
      pathname: `${ROUTE_STRINGS.editUser}/${rowData.id}`,
      state: { rowData, tabUrl: tabParam }
    })
  }

  const handleUserToggle = (options) => {
    setSelectedRowData(options)
    if (options.isActive) {
      setModalState((previousState) => ({
        ...previousState,
        softAction: 'CANCEL',
        hardAction: 'DEACTIVATE',
        showModal: true,
        modalType: 'info',
        message: 'DEACTIVATE_USER_CONFIRMATION',
        onDismiss: handleDismiss,
        onHide: handleDismiss,
        onConfirmation: () => toggleUserStatus(options)
      }))
    } else {
      setModalState((previousState) => ({
        ...previousState,
        softAction: 'CANCEL',
        hardAction: 'ACTIVATE',
        showModal: true,
        modalType: 'info',
        message: 'ACTIVATE_USER_CONFIRMATION',
        onDismiss: handleDismiss,
        onHide: handleDismiss,
        onConfirmation: () => toggleUserStatus(options)
      }))
    }
  }

  const handleAddButtonClick = () => {
    history.push(ROUTE_STRINGS.addUser)
  }

  const handleDismiss = () => {
    setModalState((previousState) => ({
      ...previousState,
      showModal: false,
      modalType: '',
      message: '',
      hardAction: '',
      softAction: ''
    }))
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const openErrorModal = (showModal, getUsersData) => {
    setModalState({
      ...modalState,
      showModal,
      modalType: 'error',
      message: 'NO_DATA_FOUND',
      onDismiss: handleDismiss,
      onHide: handleDismiss,
      onRetry: () => getUsersData()
    })
  }

  const toggleTemplate = (options) => (
    <div className="toggle-switch">
      <InputSwitch checked={options.isActive} onChange={() => handleUserToggle(options)} />
    </div>
  )

  const actionTemplate = (rowData) => {
    const actionButtonIcon = activeTab === TAB_MENUS.clientsRoute ? <PreviewIcon className="user-preview-image" /> : 'pi pi-pencil'
    const toolTopTitle = activeTab === TAB_MENUS.clientsRoute ? t('USER_DETAILS') : t('EDIT')

    return (
      <div className="action-container">
        <Button type="button" icon={actionButtonIcon} className="pi-edit-icon" tooltip={toolTopTitle} onClick={() => editUserRedirect(rowData)} tooltipOptions={{ position: 'top' }} disabled={!rowData.isActive} />
      </div>
    )
  }

  const propObject = {
    rowCount,
    paginationButtons,
    clientData,
    othersData,
    clientFilterData,
    othersFilterData,
    columns,
    setClientData,
    setClientFilterData,
    actionTemplate,
    toggleTemplate,
    openErrorModal,
    setOthersData,
    setOthersFilterData
  }

  return (
    <LayoutContainer title={'LIST_USERS_TITLE'} breadCrumbsTitle={'listUser'} showAddButton={activeTab !== TAB_MENUS.clientsRoute} handleAddButtonClick={handleAddButtonClick}>
      <div className="card">
        <div className="user-list-container tab-container">
          <TabView activeIndex={activeTab === TAB_MENUS.clientsRoute ? 0 : 1} onTabChange={(e) => setActiveTab(e.index === 0 ? TAB_MENUS.clientsRoute : TAB_MENUS.contributorsRoute)}>
            <TabPanel header={t('USER_TYPE_CLIENT')}>
              <ClientUser {...propObject} />
              <DialogModal {...modalState} />
            </TabPanel>
            <TabPanel header={t('USER_TYPE_OTHER')}>
              <OtherUser {...propObject} />
              <DialogModal {...modalState} />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </LayoutContainer>
  )
}

export default Loader(ListUsers)
