import './styles.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Breadcrumbs from 'Components/LayoutComponent/Components/BreadCrumbs'
import Footer from 'Components/LayoutComponent/Components/Footer'
import ButtonComponent from '../UIComponents/ButtonComponent'

const LayoutContainer = (props) => {
  const { t } = useTranslation()
  const { title, children, handleEditButtonClick, showEditButton, breadCrumbsTitle, showAddButton, handleAddButtonClick, showRoleFeatureButton, handleRoleFeatureButtonClick, showChangePasswordButton, setIsDialogOpen } = props
  const showButtonContainer = showEditButton || showAddButton || showRoleFeatureButton || showChangePasswordButton

  const getClassName = () => {
    if (showButtonContainer) {
      return 'body-heading'
    }

    return 'body-heading-full'
  }

  return (
    <div className="body-container">
      {breadCrumbsTitle && <Breadcrumbs crumbsKey={breadCrumbsTitle} />}
      <div className="heading-action-wrapper">
        <div className={getClassName()}>{t(title)}</div>
        {showButtonContainer && (
          <div className="heading-button-container">
            {showEditButton && <ButtonComponent type={'edit'} onClick={handleEditButtonClick} />}
            {showAddButton && <ButtonComponent type={'add'} onClick={handleAddButtonClick} />}
            {showRoleFeatureButton && <ButtonComponent type={'custom'} label={'ROLE_FEATURES'} icon="pi pi-wrench" variant={'action'} onClick={() => handleRoleFeatureButtonClick()} />}
            {showChangePasswordButton && <ButtonComponent type={'custom'} variant={'action'} label={t('CHANGE_PASSWORD')} onClick={() => setIsDialogOpen(true)} />}
          </div>
        )}
      </div>
      <div className="body-content">{children}</div>
      <Footer />
    </div>
  )
}

export default LayoutContainer
