import { Redirect, Route } from 'react-router'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { getLocalAuthToken, getLocalUserDetails } from 'Utils/LocalStorageHandler'

const ProtectedRoute = (props) => {
  const { component: Component, ...rest } = props
  const userDetails = getLocalUserDetails()
  const accessToken = getLocalAuthToken()

  return (
    <>
      <Route
        {...rest}
        render={(props) => {
          if (accessToken && userDetails !== null) {
            return <Component {...props} />
          }

          return <Redirect to={ROUTE_STRINGS.login} />
        }}
      />
    </>
  )
}

export default ProtectedRoute
