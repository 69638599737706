import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'
import { useTranslation } from 'react-i18next'
import { InputText } from 'primereact/inputtext'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Button } from 'primereact/button'
import { InputSwitch } from 'primereact/inputswitch'
import { ROUTE_STRINGS, TABLE_CONST } from 'Utils/Constants'
import Loader from 'Components/Loader'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const ClientMenu = ({ setLoading, handleAddMenu }) => {
  const history = useHistory()
  const { t } = useTranslation()
  let rowCount = 8
  let paginationButtons = TABLE_CONST.paginationButtonCount
  const location = useLocation()

  const params = new URLSearchParams()
  const searchParams = new URLSearchParams(location.search)
  const tab = searchParams.get('tab')

  const [clientMenuList, setClientMenuList] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [showTableMessage, setShowTableMessage] = useState(false)
  const [windowSize, setWindowSize] = useState(window.innerWidth)

  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onConfirmation: () => {}, onSuccess: () => {} })

  useEffect(() => {
    init()
  }, [])

  const init = () => {
    getMenuGroups()

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }

  if (windowSize < TABLE_CONST.maxWidthTab) {
    rowCount = TABLE_CONST.mobileRowCount
    paginationButtons = TABLE_CONST.mobilePaginationButtonCount
  }

  const getMenuGroups = async () => {
    setShowDialog({ ...showDialog, showModal: false })
    setLoading(true)
    setShowTableMessage(true)
    try {
      const response = await apiAdapterCoffeeWeb.getAllMenu()
      const filteredMenuList = response.data.filter((menu) => menu.isadminConsoleMenu === false)

      setClientMenuList(filteredMenuList)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('NO_DATA_FOUND'),
        onRetry: () => init(),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
      setShowTableMessage(false)
    }
  }

  const putActiveMaster = async (item) => {
    handleDismiss()
    setLoading(true)
    try {
      if (!item.isActive) {
        await apiAdapterCoffeeWeb.activeMenu(item.id)
      } else {
        await apiAdapterCoffeeWeb.deactivateMenu(item.id)
      }
      const updatedList = clientMenuList.map((element) => {
        if (element.id === item.id) {
          return {
            ...element,
            isActive: !item.isActive
          }
        }

        return element
      })

      setClientMenuList(updatedList)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('FAILED'),
        onRetry: () => putActiveMaster(item),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const putWebMaster = async (item) => {
    handleDismiss()
    setLoading(true)

    try {
      if (!item.isWebDisplayEnable) {
        await apiAdapterCoffeeWeb.activateMenuForWeb(item.id)
      } else {
        await apiAdapterCoffeeWeb.deactivateMenuForWeb(item.id)
      }
      const updatedList = clientMenuList.map((element) => {
        if (element.id === item.id) {
          return {
            ...element,
            isWebDisplayEnable: !item.isWebDisplayEnable
          }
        }

        return element
      })

      setClientMenuList(updatedList)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('FAILED'),
        onRetry: () => putWebMaster(item),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const putIsLaunch = async (item) => {
    handleDismiss()
    setLoading(true)
    try {
      if (!item.isLaunch) {
        await apiAdapterCoffeeWeb.activateMenuLaunch(item.id)
      } else {
        await apiAdapterCoffeeWeb.deactivateMenuLaunch(item.id)
      }
      const updatedList = clientMenuList.map((element) => {
        if (element.id === item.id) {
          return {
            ...element,
            isLaunch: !item.isLaunch
          }
        }

        return element
      })

      setClientMenuList(updatedList)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('FAILED'),
        onRetry: () => putIsLaunch(item),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const putMobileMaster = async (item) => {
    handleDismiss()
    setLoading(true)
    try {
      if (!item.isMobDisplayEnable) {
        await apiAdapterCoffeeWeb.activateMenuForMobile(item.id)
      } else {
        await apiAdapterCoffeeWeb.deactivateMenuForMobile(item.id)
      }
      const updatedList = clientMenuList.map((element) => {
        if (element.id === item.id) {
          return {
            ...element,
            isMobDisplayEnable: !item.isMobDisplayEnable
          }
        }

        return element
      })

      setClientMenuList(updatedList)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('FAILED'),
        onRetry: () => putMobileMaster(item),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const handleDismiss = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const handleEditMenuGroup = (item) => {
    const newTab = tab || 'admin_menu'

    params.set('tab', newTab)

    history.push({ pathname: `${ROUTE_STRINGS.editmenugroup}/${item.id}`, search: params.toString() })
  }

  const editScreenRedirect = (index) => <Button type="button" icon="pi pi-pencil" className="edit-button" tooltip={t('EDIT')} tooltipOptions={{ position: 'top' }} onClick={() => handleEditMenuGroup(index)} />

  const toggleWebDisplay = (item) => (
    <div className="toggle-switch-menu">
      <InputSwitch checked={item.isWebDisplayEnable} onChange={() => putWebMaster(item)} />
    </div>
  )
  const toggleActivate = (item) => (
    <div className="toggle-switch-menu">
      <InputSwitch checked={item.isActive} onChange={() => putActiveMaster(item)} />
    </div>
  )
  const toggleLaunch = (item) => (
    <div className="toggle-switch-menu">
      <InputSwitch checked={item.isLaunch} onChange={() => putIsLaunch(item)} />
    </div>
  )

  const toggleMobileDisplay = (item) => (
    <div className="toggle-switch-menu">
      <InputSwitch checked={item.isMobDisplayEnable} onChange={() => putMobileMaster(item)} />
    </div>
  )

  const columns = [
    {
      field: 'menuName',
      header: t('MENU_NAME'),
      className: 'menu-name'
    },
    {
      body: toggleWebDisplay,
      header: t('WEB_DISPLAY'),
      className: 'web-display'
    },
    {
      body: toggleMobileDisplay,
      header: t('MOBILE_DISPLAY'),
      className: 'mobile-display'
    },
    {
      body: toggleLaunch,
      header: t('IS_LAUNCH'),
      className: 'is-launch'
    },
    {
      body: toggleActivate,
      header: t('IS_ACTIVE'),
      className: 'is-active'
    },

    {
      body: editScreenRedirect,
      header: t('ACTIONS'),
      className: 'action-column'
    }
  ]

  const filteredClientMenuList = clientMenuList.filter((menu) => menu.menuName.toLowerCase().includes(searchValue.toLowerCase()))

  const handleSearch = (e) => {
    setSearchValue(e.target.value)
  }

  const tableResponseMessage = () => <div>{showTableMessage ? t('FETCHING') : t('NO_RESULTS_FOUND')}</div>

  return (
    <div className="client-menu-wrapper">
      <div className="client-menu-table-container">
        <div className="search-container">
          <div className="search-field-wrapper">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText placeholder={t('SEARCH_MENU')} className="search-field" value={searchValue} onChange={handleSearch} />
            </span>
          </div>

          <div className="add-button">
            <ButtonComponent type="add" onClick={handleAddMenu} />
          </div>
        </div>

        <DataTable value={filteredClientMenuList} paginator={filteredClientMenuList.length} rows={rowCount} pageLinkSize={paginationButtons} emptyMessage={tableResponseMessage} className="client-menu-table">
          {columns.map((column, index) => (
            <Column key={index} field={column.field} header={column.header} body={column.body} className={column.className} />
          ))}
        </DataTable>
      </div>
      <DialogModal {...showDialog} onDismiss={handleDismiss} />
    </div>
  )
}

export default Loader(ClientMenu)
