import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable } from 'primereact/datatable'
import { InputSwitch } from 'primereact/inputswitch'
import { Column } from 'primereact/column'
import { useHistory } from 'react-router-dom'
import { ROUTE_STRINGS, TABLE_CONST } from 'Utils/Constants'
import { commonMethods } from 'Utils/commonMethods'
import LayoutContainer from 'Components/LayoutComponent'
import Loader from 'Components/Loader'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import DialogModal from 'Components/UIComponents/DialogModal'
import { act } from '@testing-library/react'

function ListMobile({ setLoading, listMobileMockData }) {
  const { t } = useTranslation()
  const history = useHistory()
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [listMobileData, setListMobileData] = useState([])
  const [showTableMessage, setShowTableMessage] = useState(false)

  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onConfirmation: () => {} })

  let paginationButtons = 5
  let rowCount = TABLE_CONST.rowsCount

  const makeMobileUpdateActive = (options) => (
    <div className="toggle-switch">
      <InputSwitch checked={options.isActive} onChange={() => openConfirmDialogIsActive(options)} />
    </div>
  )

  const makeMobileIsForceUpdate = (options) => (
    <div className="toggle-switch">
      <InputSwitch checked={options.isforcefullupdate} onChange={() => openConfirmDialogIsForceUpdate(options)} />
    </div>
  )

  const columns = [
    {
      dataField: 'currentVersion',
      text: t('CURRENT_VERSION'),
      className: 'current-version-column'
    },
    {
      dataField: 'buildNumber',
      text: t('BUILD_NUMBER'),
      className: 'build-number-column'
    },
    {
      dataField: 'mobileOs',
      text: t('MOBILE_OS'),
      className: 'mobile-os-column'
    },
    {
      dataField: 'buildDate',
      text: t('BUILD_DATE'),
      className: 'build-date-column'
    },
    {
      body: makeMobileUpdateActive,
      text: t('IS_ACTIVE'),
      className: 'is-active-column'
    },
    {
      body: makeMobileIsForceUpdate,
      text: t('IS_FORCE_UPDATE'),
      className: 'isForceUpdate-column'
    }
  ]

  useEffect(() => {
    init()
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  // useEffect to initialize state for testing purposes
  useEffect(() => {
    if (listMobileMockData) {
      setListMobileData(listMobileMockData)
    }
  }, [])

  if (windowSize < TABLE_CONST.maxWidthTab) {
    rowCount = 8
    paginationButtons = 1
  }

  const init = async () => {
    setLoading(true)
    setShowTableMessage(true)

    try {
      const mobileListResponse = await apiAdapterCoffeeWeb.getAllMobileUpdates()
      const mobileListDataResponse = mobileListResponse?.data?.returnLst
      const modifiedMobileList = mobileListDataResponse.map((ele) => {
        const buildDate = commonMethods.utcToLocal(ele.buildDate)

        return {
          ...ele,
          buildDate
        }
      })

      setListMobileData(modifiedMobileList)
    } catch (error) {
      act(() => {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'error',
          message: t('NO_DATA_FOUND'),
          onRetry: commonMethods.handleRetry,
          onHide: handleDismiss
        })
      })
    } finally {
      act(() => {
        setLoading(false)
        setShowTableMessage(false)
      })
    }
  }

  const activeMobileUpdate = async (options) => {
    setShowDialog({
      ...showDialog,
      showModal: false
    })
    const { idMobUpdate, isActive } = options

    setLoading(true)
    try {
      await apiAdapterCoffeeWeb.toggleMobileUpdateStatus({ userId: idMobUpdate, activeAndInactive: !isActive })

      init()
    } catch {
      act(() => {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'error',
          message: t('FAILED_TO_ACTIVATE_INACTIVATE_MOBILE_STATUS'),
          onRetry: modalRetry,
          onHide: handleDismiss
        })
      })
    } finally {
      act(() => {
        setLoading(false)
      })
    }
  }

  const ActiveAndInactiveIsForceUpdates = async (options) => {
    setShowDialog({
      ...showDialog,
      showModal: false
    })
    const { idMobUpdate, isforcefullupdate } = options

    setLoading(true)
    try {
      await apiAdapterCoffeeWeb.ActiveAndInactiveIsForceUpdate({ userId: idMobUpdate, activeAndInactive: !isforcefullupdate })

      init()
    } catch {
      act(() => {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'error',
          message: t('FAILED_TO_ACTIVATE_INACTIVATE_MOBILE_STATUS'),
          onRetry: modalRetry,
          onHide: handleDismiss
        })
      })
    } finally {
      act(() => {
        setLoading(false)
      })
    }
  }

  const modalRetry = (options) => {
    setShowDialog({
      ...showDialog,
      showModal: false
    })
    setTimeout(() => {
      activeMobileUpdate()
      ActiveAndInactiveIsForceUpdates()
    }, 300)
  }

  const handleAddButtonClick = () => {
    history.push({
      pathname: ROUTE_STRINGS.addMobile
    })
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const handleDismiss = () => {
    setShowDialog({
      ...showDialog,
      showModal: false
    })
  }

  const openConfirmDialogIsActive = (options) => {
    if (options.isActive) {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'info',
        message: t('CONFIRM_THE_DEACTIVATION_OF_MOBILE_UPDATE'),
        onConfirmation: () => activeMobileUpdate(options),
        onHide: handleDismiss
      })
    } else {
      activeMobileUpdate(options)
    }
  }

  const openConfirmDialogIsForceUpdate = (options) => {
    if (options.isforcefullupdate) {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'info',
        message: t('CONFIRM_THE_DEACTIVATION_OF_MOBILE_UPDATE'),
        onConfirmation: () => ActiveAndInactiveIsForceUpdates(options),
        onHide: handleDismiss
      })
    } else {
      ActiveAndInactiveIsForceUpdates(options)
    }
  }

  const tableResponseMessage = () => <div>{showTableMessage ? t('FETCHING') : t('NO_RESULTS_FOUND')}</div>

  return (
    <LayoutContainer title={'LIST_MOBILE_VERSION'} breadCrumbsTitle={'listMobile'} showAddButton={true} handleAddButtonClick={handleAddButtonClick}>
      <div className="list-mobile-container">
        <DataTable value={listMobileData} paginator={listMobileData?.length > 0} rows={rowCount} pageLinkSize={paginationButtons} emptyMessage={tableResponseMessage} className="list-mobile-table">
          {columns.map((column, index) => (
            <Column key={index} field={column.dataField} body={column.body} header={column.text} className={column.className} />
          ))}
        </DataTable>
      </div>
      <DialogModal {...showDialog} onDismiss={handleDismiss} />
    </LayoutContainer>
  )
}
export default Loader(ListMobile)
