import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'
import { useTranslation } from 'react-i18next'
import { TabPanel, TabView } from 'primereact/tabview'
import { ROUTE_STRINGS } from 'Utils/Constants'
import LayoutContainer from 'Components/LayoutComponent'
import MapMenu from './Pages/MapMenu'
import SubscriptionFeature from './Pages/SubscriptionFeature'
import { ListSubscriptionPaymentMapping } from './Pages/SubscriptionPaymentMapping'

const SubscriptionConfig = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const location = useLocation()
  const [activeIndex, setActiveIndex] = useState(null)
  const params = new URLSearchParams()
  const searchParams = new URLSearchParams(location.search)
  const tab = searchParams.get('tab')

  const {
    state: { name }
  } = location

  useEffect(() => {
    const defaultTab = tab || 'subscription_features'
    let tabIndex

    if (defaultTab === 'subscription_features') {
      tabIndex = 0
    } else if (defaultTab === 'subscription_payment') {
      tabIndex = 1
    } else if (defaultTab === 'subscription_menu') {
      tabIndex = 2
    }

    updateHistoryAndSetActiveTab(defaultTab, tabIndex)
  }, [tab])

  const updateHistoryAndSetActiveTab = (newTab, tabIndex) => {
    params.set('tab', newTab)
    history.replace({ pathname: ROUTE_STRINGS.subscriptionConfig, search: params.toString(), state: location.state })
    setActiveIndex(tabIndex)
  }

  const handleTabChange = (index) => {
    let newTab

    if (index === 0) {
      newTab = 'subscription_features'
    } else if (index === 1) {
      newTab = 'subscription_payment'
    } else if (index === 2) {
      newTab = 'subscription_menu'
    }

    updateHistoryAndSetActiveTab(newTab, index)
  }

  return (
    <LayoutContainer title={`${t('SUBSCRIPTION_CONFIG')}` + ' - ' + `${name.toUpperCase()}`} breadCrumbsTitle={'subscriptionType'}>
      <div className="subscription-config-tab-container">
        <div className="tab-container">
          <TabView activeIndex={activeIndex} onTabChange={(e) => handleTabChange(e.index)}>
            <TabPanel header={t('FEATURES')} className="features-tab-panel" index={0}>
              <SubscriptionFeature />
            </TabPanel>
            <TabPanel header={t('PAYMENT')} className="payment-tab-panel" index={1}>
              <ListSubscriptionPaymentMapping />
            </TabPanel>
            <TabPanel header={t('SUBSCRIPTION_MENU')} className="menu-tab-panel" index={2}>
              <MapMenu />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </LayoutContainer>
  )
}

export default SubscriptionConfig
