import './styles.scss'
import React, { useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { InputTextarea } from 'primereact/inputtextarea'
import { Calendar } from 'primereact/calendar'
import { classNames } from 'primereact/utils'
import { Toast } from 'primereact/toast'
import Validation from 'Utils/Validation'
import { ROUTE_STRINGS } from 'Utils/Constants'
import Loader from 'Components/Loader'
import LayoutContainer from 'Components/LayoutComponent'
import DialogModal from 'Components/UIComponents/DialogModal'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { ButtonComponent } from 'Components/UIComponents'

const AddMobile = ({ setLoading }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const toast = useRef(null)
  const launchPlatformOptions = [{ mobileOS: 'Android' }, { mobileOS: 'iOS' }, { mobileOS: 'Fushia' }]

  const [addMobileData, setAddMobileData] = useState({
    majorVersion: '',
    minorVersion: '',
    patchVersion: '',
    version: '',
    buildNumber: '',
    launchPlatform: '',
    buildDate: new Date(),
    releaseNotes: ''
  })
  const [errorState, setErrorState] = useState({
    majorVersion: '',
    minorVersion: '',
    patchVersion: '',
    buildNumber: '',
    launchPlatform: '',
    buildDate: '',
    releaseNotes: ''
  })

  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onConfirmation: () => {}, onSuccess: () => {} })

  const { majorVersion, minorVersion, patchVersion, buildNumber, launchPlatform, buildDate, releaseNotes, version } = addMobileData
  const submitButtonDisable = !majorVersion || !minorVersion || !patchVersion || !buildNumber || !launchPlatform || !buildDate || !releaseNotes

  const addMobileVersion = async () => {
    setLoading(true)
    const { version, buildNumber, buildDate, releaseNotes, launchPlatform } = addMobileData
    const dateOnly = buildDate.toISOString().slice(0, 10)
    const timeOnly = buildDate.toString().substr(16, 8)
    const mobileUpdateData = {
      versionName: version,
      buildNumber,
      buildDate: `${dateOnly}T${timeOnly}`,
      releaseNotes,
      launchPlateform: launchPlatform.mobileOS,
      isActive: true
    }

    try {
      const {
        data: { statusCode }
      } = await apiAdapterCoffeeWeb.addMobile(mobileUpdateData)

      if (statusCode === 200 || statusCode === 201) {
        setLoading(false)

        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: t('MOBILE_VERSION_ADDED_SUCCESSFULLY'),
          onHide: () => history.push(ROUTE_STRINGS.listMobile),
          onSuccess: () => history.push(ROUTE_STRINGS.listMobile)
        })
      }
    } catch (error) {
      const { statusCode } = error.response.data

      if (statusCode === 404) {
        toast.current.show({ severity: t('ERROR_TOAST'), summary: t('DATA_ALREADY_EXISTS'), detail: t('PLEASE_ENTER_ANOTHER_MOBILE_VERSION') })
      } else {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'error',
          message: t('FAILED_TO_ADD_MOBILE'),
          onRetry: modalRetry,
          onHide: handleDismiss
        })
      }
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (event) => {
    const { value, name } = event.target

    const setMobileData = (update) => {
      setAddMobileData((prevData) => ({
        ...prevData,
        ...update
      }))
    }

    const setError = (update) => {
      setErrorState((prevErrState) => ({
        ...prevErrState,
        ...update
      }))
    }

    const validatePhoneNumber = (value) => Validation.phoneNumberValidation(value) || Validation.isInputFieldEmpty(value)

    const validateNumberAndDots = (value) => Validation.isInputFieldEmpty(value) || !Validation.numberAndDotsValidation(value)

    const validateMobileOS = (mobileOS) => Validation.isInputFieldEmpty(mobileOS) || !Validation.nameValidation(mobileOS)

    if (name === 'majorVersion' || name === 'minorVersion' || name === 'patchVersion') {
      if (validatePhoneNumber(value)) {
        const { majorVersion, minorVersion, patchVersion } = addMobileData
        let updatedData = {}
        let updatedErrorState = {}

        if (name === 'majorVersion') {
          updatedData = {
            majorVersion: value,
            version: `${value}.${minorVersion}.${patchVersion}`
          }
          updatedErrorState = { majorVersion: validateNumberAndDots(value) }
        } else if (name === 'minorVersion') {
          updatedData = {
            minorVersion: value,
            version: `${majorVersion}.${value}.${patchVersion}`
          }
          updatedErrorState = { minorVersion: validateNumberAndDots(value) }
        } else {
          updatedData = {
            patchVersion: value,
            version: `${majorVersion}.${minorVersion}.${value}`
          }
          updatedErrorState = { patchVersion: validateNumberAndDots(value) }
        }

        const { version } = updatedData

        if (version === '..') {
          updatedData = {
            majorVersion: value,
            minorVersion: value,
            patchVersion: value,
            version: ''
          }
        }

        setMobileData(updatedData)
        setError(updatedErrorState)
      }
    } else if (name === 'buildNumber') {
      if (validatePhoneNumber(value)) {
        setMobileData({ buildNumber: value })
        setError({ buildNumber: Validation.isInputFieldEmpty(value) || !Validation.phoneNumberValidation(value) })
      }
    } else if (name === 'launchPlatform') {
      const { mobileOS } = value

      setMobileData({ launchPlatform: value })
      setError({ launchPlatform: validateMobileOS(mobileOS) })
    } else if (name === 'buildDate') {
      setAddMobileData({ ...addMobileData, buildDate: value })
      setError({ buildDate: Validation.checkEmptyFieldNull(value) })
    } else if (name === 'releaseNotes') {
      setMobileData({ releaseNotes: value })
      setError({ releaseNotes: Validation.isInputFieldEmpty(value) })
    }
  }

  const onSubmit = (data) => {
    setAddMobileData(data)
  }

  const modalRetry = () => {
    setShowDialog({
      ...showDialog,
      showModal: false
    })
    setTimeout(() => {
      addMobileVersion()
    }, 300)
  }

  const handleDismiss = () => {
    setShowDialog({
      ...showDialog,
      showModal: false
    })
  }

  const validate = (data) => {
    const requiredFields = {
      majorVersion: 'MAJOR_VERSION_IS_REQUIRED',
      minorVersion: 'MINOR_VERSION_IS_REQUIRED',
      patchVersion: 'PATCH_VERSION_IS_REQUIRED',
      buildNumber: 'BUILD_NUMBER_IS_REQUIRED',
      launchPlatform: 'LAUNCH_PLATFORM_IS_REQUIRED',
      buildDate: 'BUILD_DATE_IS_REQUIRED',
      releaseNotes: 'RELEASE_NOTES_IS_REQUIRED'
    }

    const errors = Object.keys(requiredFields).reduce((acc, field) => {
      if (!data[field]) {
        acc[field] = t(requiredFields[field])
      }

      return acc
    }, {})

    return errors
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  return (
    <LayoutContainer title="ADD_MOBILE" breadCrumbsTitle="addMobileVersion">
      <div className="add-mobile-group-card" data-testid="addMobile-form">
        <Form
          onSubmit={onSubmit}
          initialValues={addMobileData}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="p-fluid">
              <div className="versions-row">
                <Field
                  name="majorVersion"
                  render={({ input, meta }) => (
                    <div className="major-version">
                      <span className="p-float-label">
                        <InputText {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'field-label')} value={majorVersion} onChange={handleChange} placeholder={t('ENTER_MAJOR_VERSION')} id="majorVersion" maxLength={4} data-testid="majorVersionField" />
                        <label htmlFor="majorVersion" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'field-label'}`)}>
                          {t('MAJOR_VERSION')}
                          <span className="field-required"> *</span>
                        </label>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />
                <Field
                  name="minorVersion"
                  render={({ input, meta }) => (
                    <div className="minor-version">
                      <span className="p-float-label">
                        <InputText {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'field-label')} value={minorVersion} onChange={handleChange} placeholder={t('ENTER_MINOR_VERSION')} id="minorVersion" maxLength={4} data-testid="minorVersionField" />
                        <label htmlFor="minorVersion" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'field-label'}`)}>
                          {t('MINOR_VERSION')}
                          <span className="field-required"> *</span>
                        </label>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />
                <Field
                  name="patchVersion"
                  render={({ input, meta }) => (
                    <div className="patch-version">
                      <span className="p-float-label">
                        <InputText {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'field-label')} value={patchVersion} onChange={handleChange} placeholder={t('ENTER_PATCH_VERSION')} id="patchVersion" maxLength={4} data-testid="patchVersionField" />
                        <label htmlFor="patchVersion" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'field-label'}`)}>
                          {t('PATCH_VERSION')}
                          <span className="field-required"> *</span>
                        </label>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />
              </div>
              <Field
                name="version"
                render={({ input, meta }) => (
                  <div className="version">
                    <span className="p-float-label">
                      <InputText disabled className="field-width" {...input} type="text" name="version" value={version} id="idVersion" data-testid="versionName" />
                      <label htmlFor="idVersion" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'field-label'}`)}>
                        {t('VERSION_NAME')}
                        <span className="field-required"></span>
                      </label>
                    </span>
                  </div>
                )}
              />
              <Field
                name="buildNumber"
                render={({ input, meta }) => (
                  <div className="build-number">
                    <span className="p-float-label">
                      <InputText className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'field-label')} {...input} name="buildNumber" maxLength={'4'} onChange={handleChange} value={buildNumber} placeholder={t('ENTER_BUILD_NUMBER')} id="buildNumber" data-testid="buildNumberField" />
                      <label htmlFor="buildNumber" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'field-label'}`)}>
                        {t('BUILD_NUMBER')}
                        <span className="field-required"> *</span>
                      </label>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}
              />
              <Field
                name="launchPlatform"
                render={({ input, meta }) => (
                  <div className="launchPlatform">
                    <span className="p-float-label">
                      <Dropdown {...input} name="launchPlatform" className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'field-label')} onChange={handleChange} value={launchPlatform} options={launchPlatformOptions} optionLabel="mobileOS" placeholder={t('SELECT_MOBILE_OS_PLATFORM')} id="launchPlatform" data-testid="launchPlatformDropdown" />
                      <label htmlFor="launchPlatform" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'field-label'}`)}>
                        {t('LAUNCH_PLATFORM')}
                        <span className="field-required"> *</span>
                      </label>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}
              />
              <Field
                name="buildDate"
                render={({ input, meta }) => (
                  <div className="build-date">
                    <span className="p-float-label">
                      <Calendar {...input} id="buildDate" className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'field-label')} name="buildDate" value={buildDate} onChange={handleChange} showTime hourFormat="12" maxDate={new Date()} showIcon data-testid="buildDateCalendar" />
                      <label htmlFor="buildDate" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'field-label'}`)}>
                        {t('BUILD_DATE')}
                        <span className="field-required"> *</span>
                      </label>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}
              />
              <Field
                name="releaseNotes"
                render={({ input, meta }) => (
                  <div className="release-notes">
                    <span className="p-float-label">
                      <InputTextarea className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'field-label')} {...input} type="text" name="releaseNotes" onChange={handleChange} value={releaseNotes} rows={3} placeholder={t('ENTER_RELEASE_NOTES')} id="idReleaseNotes" data-testid="releaseNotesField" />
                      <label htmlFor="idReleaseNotes" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'field-label'}`)}>
                        {t('RELEASE_NOTES')}
                        <span className="field-required"> *</span>
                      </label>
                    </span>
                    <div className="release-notes-error">{getFormErrorMessage(meta)}</div>
                  </div>
                )}
              />
              <div className="add-mobile-save">
                <ButtonComponent type={'submit'} onClick={addMobileVersion} disabled={submitButtonDisable} />
              </div>
            </form>
          )}
        />
        <DialogModal {...showDialog} onDismiss={handleDismiss} />

        <Toast ref={toast} position="top-right" />
      </div>
    </LayoutContainer>
  )
}

export default Loader(AddMobile)
