import './styles.scss'
import LayoutContainer from 'Components/LayoutComponent'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'
import { useTranslation } from 'react-i18next'
import { TabView, TabPanel } from 'primereact/tabview'
import { ROUTE_STRINGS } from 'Utils/Constants'
import AdminMenu from './AdminMenu'
import ClientMenu from './ClientMenu'

const ListMenuGroup = () => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const params = new URLSearchParams()
  const searchParams = new URLSearchParams(location.search)
  const tab = searchParams.get('tab')

  const [activeTab, setActiveTab] = useState(0)

  const updateHistoryAndSetActiveTab = (newTab, tabIndex) => {
    params.set('tab', newTab)
    history.replace({ pathname: ROUTE_STRINGS.listmenu, search: params.toString() })
    setActiveTab(tabIndex)
  }

  useEffect(() => {
    const defaultTab = tab || 'admin_menu'
    const tabIndex = defaultTab === 'admin_menu' ? 0 : 1

    updateHistoryAndSetActiveTab(defaultTab, tabIndex)
  }, [tab])

  const handleAddMenu = () => {
    const newTab = tab || 'admin_menu'

    params.set('tab', newTab)
    history.push({ pathname: ROUTE_STRINGS.addmenu, search: params.toString() })
  }

  const handleTabChange = (index) => {
    const newTab = index === 0 ? 'admin_menu' : 'client_menu'

    updateHistoryAndSetActiveTab(newTab, index)
  }

  return (
    <LayoutContainer title={'MENU_LIST'} breadCrumbsTitle={'listMenu'}>
      <div className="menu-tab-container">
        <TabView activeIndex={activeTab} onTabChange={(e) => handleTabChange(e.index)}>
          <TabPanel header={t('ADMIN_MENU')}>
            <AdminMenu handleAddMenu={handleAddMenu} />
          </TabPanel>
          <TabPanel header={t('CLIENT_MENU')}>
            <ClientMenu handleAddMenu={handleAddMenu} />
          </TabPanel>
        </TabView>
      </div>
    </LayoutContainer>
  )
}

export default ListMenuGroup
