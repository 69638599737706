import './styles.scss'
import React, { useEffect, useState } from 'react'
import breadcrumbData from 'Utils/Constants/breadCrumbsData'

const Breadcrumbs = ({ crumbsKey }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const crumbs = breadcrumbData[crumbsKey] || []

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className="breadcrumbs-container" data-testid="breadcrumbs-title">
      <ul className="breadcrumb-list">
        {crumbs.map((crumb, index) => (
          <li key={index} className="breadcrumb-item">
            {index === 0 && windowWidth <= 768 ? (
              <a href={crumb.link} className="breadcrumb-link">
                <span className="dashboard-icon">
                  <icon className="pi pi-home" />
                </span>
              </a>
            ) : (
              <a href={crumb.link} className="breadcrumb-link">
                {crumb.label}
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Breadcrumbs
