import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import { v4 as uuid } from 'uuid'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import { Dropdown } from 'primereact/dropdown'
import { InputSwitch } from 'primereact/inputswitch'
import { Dialog } from 'primereact/dialog'
import { Checkbox } from 'primereact/checkbox'
import { Paginator } from 'primereact/paginator'
import { Divider } from 'primereact/divider'
import { ApprovedIcon, RejectedIcon, PendingIcon, PreviewIcon, ReviewIcon, RemarkReviewIcon } from 'Assets/Icons'
import LayoutContainer from 'Components/LayoutComponent'
import { ButtonComponent } from 'Components/UIComponents'
import DialogModal from 'Components/UIComponents/DialogModal'
import Loader from 'Components/Loader'
import { commonMethods } from 'Utils/commonMethods'
import { REG_EX, ROUTE_STRINGS, TABLE_CONST } from 'Utils/Constants'
import { getLocalUserDetails } from 'Utils/LocalStorageHandler'
import { getUserRoleBasedFeature } from 'Utils/RolesPermission'
import Validation from 'Utils/Validation'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import NewsDeleteModal from 'Screens/NewsFeed/Components/DeleteNewsModal'

const ListNewsFeed = ({ setLoading, mockSetLoading, mockNewsData, mockIsTrendingNewsDialogOpen, mockOtherInputVisible, mockFlagListNewsFeed, mockOpenOtpDialog }) => {
  const history = useHistory()
  const location = useLocation()

  const userRoleBasedFeature = getUserRoleBasedFeature()
  const showAddButton = userRoleBasedFeature?.functionality?.newsFeed?.showAddButton ?? true
  const editNewsIconDisable = userRoleBasedFeature?.functionality?.newsFeed?.editNewsIconDisable ?? false
  const deleteNewsIconDisable = userRoleBasedFeature?.functionality?.newsFeed?.deleteNewsIconDisable ?? false
  const freeNewsToggleDisable = userRoleBasedFeature?.functionality?.newsFeed?.freeNewsToggleDisable ?? false
  const trendingNewsToggleDisable = userRoleBasedFeature?.functionality?.newsFeed?.trendingNewsToggleDisable ?? false

  const userDetails = getLocalUserDetails()
  const tabId = sessionStorage.tabId ? sessionStorage.tabId : (sessionStorage.tabId = Math.random())
  const { t } = useTranslation()
  const params = new URLSearchParams()
  const searchParams = new URLSearchParams(location.search)
  const [deletedNews, setDeletedNews] = useState(0)
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [selectedLanguage, setSelectedLanguage] = useState(null)
  const [selectTrendingNewsDropdown, setSelectTrendingNewsDropdown] = useState(null)
  const [selectedNewsStatus, setSelectedNewsStatus] = useState()
  const [trendingNewsHoursField, setTrendingNewsHoursField] = useState(null)
  const [trendingNewsData, setTrendingNewsData] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [fireBaseToken, setFireBaseToken] = useState('')
  const [eachRowData, setEachRowData] = useState({})
  const [initialEachRowData, setInitialEachRowData] = useState({})
  const [languages, setLanguages] = useState([])
  const [newsData, setNewsData] = useState([])
  const [newsDataForOperations, setNewsDataForOperations] = useState([])
  const [errorMessageForHours, setErrorMessageForHours] = useState(false)
  const [openOtpDialog, setOpenOtpDialog] = useState(false)
  const [otherInputVisible, setOtherInputVisible] = useState(false)
  const [otpMismatchMessage, setOtpMismatchMessage] = useState(false)
  const [isTrendingNewsDialogOpen, setIsTrendingNewsDialogOpen] = useState(false)
  const [errorState, setErrorState] = useState({ editNewsOtp: false })
  const [submitOtp, setSubmitOtp] = useState({ sessionId: '', editNewsOtp: '' })
  const [openRemarkDialog, setOpenRemarkDialog] = useState(false)
  const [showFreeNewsModal, setShowFreeNewsModal] = useState(false)
  const [isCheckBoxDisabled, setIsCheckBoxDisabled] = useState(true)
  const [showTableMessage, setShowTableMessage] = useState(false)
  const [newsTotalRecord, setNewsTotalRecord] = useState('')
  const [newsFilterTypes, setNewsFilterTypes] = useState([])
  const [pageNumberSelected, setPageNumberSelected] = useState(0)
  const [pageMount, setPageMount] = useState(false)

  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onConfirmation: () => {} })
  let paginationButtons = TABLE_CONST.paginationButtonCount
  const rowCount = 5

  const trendingNewsTime = [
    { label: 2, hours: '2 hrs' },
    { label: 4, hours: '4 hrs' },
    { label: 6, hours: '6 hrs' },
    { label: 8, hours: '8 hrs' },
    { label: 10, hours: '10 hrs' },
    { label: 'Other', hours: 'Other' }
  ]
  const allLanguageObject = [{ idLanguage: 0, languageName: t('ALL_LANGUAGE') }]

  useEffect(() => {
    init()
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    setIsCheckBoxDisabled(eachRowData?.freeNews === initialEachRowData.freeNews && eachRowData?.showFullfreeNews === initialEachRowData.showFullfreeNews)
  }, [eachRowData, initialEachRowData])

  useEffect(() => {
    const newNewsData = newsData.filter(({ id }) => id !== deletedNews)
    const newNewsDataOperations = newsDataForOperations.filter(({ id }) => id !== deletedNews)

    setNewsDataForOperations(newNewsDataOperations)
    setNewsData(newNewsData)
  }, [deletedNews])

  useEffect(() => {
    const pageNumber = 1
    const newsFilter = searchParams.get('newsFilter')
    const language = searchParams.get('language')

    let debounceHandler

    if (searchQuery !== '' || pageMount) {
      debounceHandler = setTimeout(() => {
        fetchFilterNews(newsFilter, language, pageNumber, rowCount, searchQuery)
        setPageMount(true)
        updateUrlParams(newsFilter, language, pageNumber)
      }, 3000)
    }

    return () => {
      clearTimeout(debounceHandler)
    }
  }, [searchQuery])

  useEffect(() => {
    if (mockIsTrendingNewsDialogOpen) {
      setLoading(mockSetLoading)
      setNewsData(mockNewsData)
      setIsTrendingNewsDialogOpen(mockIsTrendingNewsDialogOpen)
      setOtherInputVisible(mockOtherInputVisible)
      setOpenOtpDialog(mockOpenOtpDialog)
    } else if (mockFlagListNewsFeed) {
      rejectTrendingNews(12, true, 2)
    }
  }, [])

  const init = async () => {
    setShowDialog({ ...showDialog, showModal: false })
    setShowTableMessage(true)
    setLoading(true)
    const uuidString = uuid()

    setFireBaseToken(uuidString)

    const pageNumber = searchParams.get('page') || 1
    const newsFilter = searchParams.get('newsFilter') || 1
    const language = searchParams.get('language') || 0

    updateUrlParams(newsFilter, language, pageNumber)

    try {
      const getAllTypeOfNews = apiAdapterCoffeeWeb.getAllNewsType()
      const languagesForNews = apiAdapterCoffeeWeb.languageForNews()
      const filterNews = apiAdapterCoffeeWeb.getFilterNews(newsFilter, language, pageNumber, rowCount, searchQuery)
      const [getAllTypeOfNewsResponse, languagesForNewsResponse, filterNewsResponse] = await Promise.all([getAllTypeOfNews, languagesForNews, filterNews])
      const { returnLst } = getAllTypeOfNewsResponse.data
      const availableLanguages = [...allLanguageObject, ...languagesForNewsResponse.data.returnLst]
      const { statusCode, returnLst: newsResponse, totalRecords } = filterNewsResponse.data

      setNewsFilterTypes(getAllTypeOfNewsResponse.data.returnLst)
      setLanguages(availableLanguages)
      setSelectedNewsStatus(returnLst.find((item) => item.id === +newsFilter))
      setSelectedLanguage(availableLanguages.find((el) => el.idLanguage === Number(language)))

      if (statusCode === 200) {
        const transformedNewsData = newsResponse.map((item) => {
          const newsDate = item.publishedOn === '1900-01-01T00:00:00' ? commonMethods.utcToLocalTime(item.createdOn) : commonMethods.utcToLocalTime(item.publishedOn)
          const subject = ReactHtmlParser(item.subject)

          return {
            ...item,
            subject,
            newsDate
          }
        })

        setNewsTotalRecord(totalRecords)
        setNewsDataForOperations(transformedNewsData)
        setNewsData(transformedNewsData)
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('NO_DATA_FOUND'),
        onRetry: () => init(),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
      setShowTableMessage(false)
    }
  }

  const fetchFilterNews = async (newsFilter, language, pageNumber, rowCount, searchQuery) => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getFilterNews(newsFilter, language, pageNumber, rowCount, searchQuery)
      const { statusCode, returnLst, totalRecords } = response.data

      if (statusCode === 200) {
        const transformedNewsData = returnLst.map((item) => {
          const newsDate = item.publishedOn === '1900-01-01T00:00:00' ? commonMethods.utcToLocalTime(item.createdOn) : commonMethods.utcToLocalTime(item.publishedOn)
          const subject = ReactHtmlParser(item.subject)

          return {
            ...item,
            subject,
            newsDate
          }
        })

        setNewsTotalRecord(totalRecords)
        setNewsDataForOperations(transformedNewsData)
        setNewsData(transformedNewsData)
      } else if (statusCode !== 200 && !returnLst.length) {
        setNewsTotalRecord(totalRecords)
        setNewsDataForOperations(returnLst)
        setNewsData(returnLst)
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('NO_DATA_FOUND'),
        onRetry: () => fetchFilterNews(newsFilter, language, pageNumber, rowCount, searchQuery),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }
  const rejectTrendingNews = async (newsId, trendingNews, hours) => {
    handleDismiss()
    setLoading(true)
    try {
      await apiAdapterCoffeeWeb.activeAndInActiveTrendingNews({
        id: newsId,
        IsActive: trendingNews,
        hours
      })

      const updatedList = newsData.map((element) => {
        if (element.id === newsId) {
          return {
            ...element,
            trendingNews: !element.trendingNews
          }
        }

        return element
      })

      setNewsData(updatedList)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('FAILED_TO_UPDATE_STATUS'),
        onRetry: () => rejectTrendingNews(newsId, trendingNews, 0),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
      setSelectTrendingNewsDropdown('')
      setTrendingNewsHoursField('')
    }
  }

  const requestOtpForNewsEdit = async (rowData) => {
    setShowDialog({ ...showDialog, showModal: false })
    const { email } = userDetails
    const id = rowData.id

    setLoading(true)
    try {
      const requestOTPResponse = await apiAdapterCoffeeWeb.requestOTPForEditNews({ email, newsId: id })

      if (requestOTPResponse.status === 200 || requestOTPResponse.status === 201) {
        setLoading(false)
        setOpenOtpDialog(true)
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('SORRY_UNABLE_TO_SEND_OTP_PLEASE_TRY_AGAIN_LATER'),
        onRetry: () => requestOtpForNewsEdit(rowData),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const handleSubmitOtp = async () => {
    const { editNewsOtp } = submitOtp
    const { email } = userDetails

    const postData = {
      emailId: email,
      otp: editNewsOtp,
      includeMenu: true,
      isMobile: false,
      deviceId: tabId,
      fbToken: fireBaseToken,
      isAndroiodDevice: false,
      byPassOldDevice: true
    }

    setLoading(true)
    try {
      const emailOtpResponse = await apiAdapterCoffeeWeb.authenticateByEmailOtp(postData)

      if (emailOtpResponse.status === 200) {
        if (emailOtpResponse.data.error !== 'OTP Mismatch') {
          const { id } = eachRowData

          history.push(`${ROUTE_STRINGS.editNewsFeed}/${id}`)
          setOpenOtpDialog(false)
        } else {
          setSubmitOtp({
            ...submitOtp,
            editNewsOtp: ''
          })
          setOtpMismatchMessage(true)
        }
      }
    } catch {
      setOpenOtpDialog(false)
    } finally {
      setLoading(false)
    }
  }
  const updateFreeNewsStatus = async () => {
    setShowFreeNewsModal(false)
    setIsCheckBoxDisabled(false)
    setLoading(true)

    const { freeNews, showFullfreeNews, id } = eachRowData

    try {
      await apiAdapterCoffeeWeb.makeFreeNews({ newsId: id, isFree: freeNews })
      if (freeNews) {
        await apiAdapterCoffeeWeb.makeFullFreeNews({ newsId: id, isFree: showFullfreeNews })
      }

      const updatedNewsList = newsData.map((element) => {
        if (element.id === id) {
          return {
            ...element,
            freeNews,
            showFullfreeNews
          }
        }

        return element
      })

      setNewsData(updatedNewsList)
    } catch (error) {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('FAILED_TO_UPDATE_FREE_NEWS_STATUS'),
        onRetry: null
      })
    } finally {
      setLoading(false)
    }
  }

  if (windowSize < TABLE_CONST.maxWidthTab) {
    paginationButtons = TABLE_CONST.mobilePaginationButtonCount
  }

  const viewNewsRedirect = (rowData) => {
    history.push(`${ROUTE_STRINGS.newsreadmore}/${rowData.id}`)
  }

  const adminReviewRedirect = (rowData) => {
    localStorage.removeItem('rejectRemarkData')
    localStorage.setItem(
      'adminPreviewData',
      JSON.stringify({
        pathname: `${ROUTE_STRINGS.editNewsFeed}/${rowData.id}`,
        titleName: 'ADMIN_PREVIEW',
        breadCrumbTitle: 'adminPreview'
      })
    )

    history.push({
      pathname: `${ROUTE_STRINGS.editNewsFeed}/${rowData.id}`,
      state: true
    })
  }

  const remarkReviewRedirect = (rowData) => {
    localStorage.removeItem('adminPreviewData')
    localStorage.setItem(
      'rejectRemarkData',
      JSON.stringify({
        pathname: `${ROUTE_STRINGS.editNewsFeed}/${rowData.id}`,
        titleName: 'EDIT_NEWS_FEED_REMARK',
        breadCrumbTitle: 'newsFeedRemark'
      })
    )

    history.push({
      pathname: `${ROUTE_STRINGS.editNewsFeed}/${rowData.id}`,
      state: true
    })
  }

  const editNewsRedirect = (rowData) => {
    localStorage.removeItem('adminPreviewData')
    localStorage.removeItem('rejectRemarkData')

    setEachRowData(rowData)

    if (rowData.isApproved === true) {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'info',
        message: t('ARE_YOU_SURE_YOU_WANT_TO_EDIT_THIS_NEWS'),
        onConfirmation: () => requestOtpForNewsEdit(rowData),
        onHide: handleDismiss
      })
    } else {
      history.push({
        pathname: `${ROUTE_STRINGS.editNewsFeed}/${rowData.id}`,
        state: false,
        titleName: 'EDIT_NEWS_FEED',
        breadCrumbTitle: 'editNewsFeed'
      })
    }
  }

  const deleteConfirmation = (rowData) => {
    setShowDialog({
      ...showDialog,
      showModal: true,
      modalType: 'info',
      message: t('NEWS_DELETION_CONFIRMATION'),
      onConfirmation: () => openRejectRemarkDialog(rowData),
      onHide: handleDismiss
    })
  }

  const handleNewsType = (e) => {
    const { value } = e.target

    setSearchQuery('')
    setSelectedNewsStatus(value)

    const newsFilter = value.id
    const pageNumber = 1
    const language = searchParams.get('language')

    fetchFilterNews(newsFilter, language, pageNumber, rowCount, '')
    updateUrlParams(newsFilter, language, pageNumber)
  }

  const handleLanguageType = (e) => {
    const { value } = e.target

    setSelectedLanguage(value)
    setSearchQuery('')

    const language = value.idLanguage
    const pageNumber = 1
    const newsFilter = searchParams.get('newsFilter')

    fetchFilterNews(newsFilter, language, pageNumber, rowCount, '')
    updateUrlParams(newsFilter, language, pageNumber)
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const handleAddButtonClick = () => {
    history.push({
      pathname: `${ROUTE_STRINGS.addnewsfeed}`
    })
  }

  const handleDismiss = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  const onSubmit = (form) => {
    form.restart()
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === 'editNewsOtp') {
      setSubmitOtp({
        ...submitOtp,
        editNewsOtp: value
      })
      if (Validation.checkEmptyField(value)) {
        setErrorState({
          ...errorState,
          editNewsOtp: true
        })
      } else {
        setErrorState({
          ...errorState,
          editNewsOtp: false
        })
      }
      setOtpMismatchMessage(false)
    } else if (name === 'trendingNewsHoursInputField') {
      if (Validation.numberValidation(value) || value === '') {
        if ((value > 0 && value <= 200) || value === '') {
          setTrendingNewsHoursField(value)
          setErrorMessageForHours(false)
        } else {
          setErrorMessageForHours(true)
        }
      }
    }
  }

  const validate = (data) => {
    const errors = {}
    const { editNewsOtp } = data

    if (!editNewsOtp) {
      errors.editNewsOtp = t('PLEASE_ENTER_OTP')
    }

    if (!selectTrendingNewsDropdown) {
      errors.trendingNewsHoursDropdown = t('SELECT_TRENDING_NEWS')
    }
    if (!trendingNewsHoursField) {
      errors.trendingNewsHoursInputField = t('ENTER_TRENDING_NEWS')
    }

    return errors
  }

  const closeOtpDialog = () => {
    setErrorState({
      editNewsOtp: false
    })
    setSubmitOtp({ ...submitOtp, editNewsOtp: '' })
    setOpenOtpDialog(false)
  }

  const openConfirmDialog = (options) => {
    if (options.trendingNews === true) {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'info',
        message: t('ARE_YOU_SURE_YOU_WANT_TO_REJECT_TRENDING_NEWS'),
        onConfirmation: () => rejectTrendingNews(options.id, false, 0),
        onHide: handleDismiss
      })
      setEachRowData(options)
    } else {
      setIsTrendingNewsDialogOpen(true)
      setEachRowData(options)
      setTrendingNewsData(options)
    }
  }

  const freeNewsDialog = (options) => {
    setShowFreeNewsModal(true)
    setEachRowData(options)
    setInitialEachRowData(options)
  }

  const openRejectRemarkDialog = (rowData) => {
    setEachRowData(rowData)
    setShowDialog({ ...showDialog, showModal: false })

    setOpenRemarkDialog(true)
  }
  const statusTemplate = (rowData) => (
    <div className="custom-status-container">
      {rowData.isApproved && (
        <Button type="button" className="custom-status-image-button" tooltip={t('APPROVED')} tooltipOptions={{ position: 'top' }}>
          <ApprovedIcon fill="#00ff00" className="custom-approved-status-image" />
        </Button>
      )}
      {rowData.isRejected && (
        <Button type="button" className="custom-status-image-button" tooltip={t('REJECTED')} tooltipOptions={{ position: 'top' }}>
          <RejectedIcon fill="#FF0000" className="custom-rejected-status-image" />
        </Button>
      )}
      {rowData.isPending && (
        <Button type="button" className="custom-status-image-button" tooltip={t('PENDING')} tooltipOptions={{ position: 'top' }}>
          <PendingIcon fill="#ffc107" className="custom-pending-status-image" />
        </Button>
      )}
      {rowData.isSprAdminPending && (
        <Button type="button" className="custom-status-image-button" tooltip={t('PENDING_SA_APPROVAL')} tooltipOptions={{ position: 'top' }}>
          <PendingIcon fill="#FF5D00" className="custom-pending-status-image" />
        </Button>
      )}
    </div>
  )

  const trendingNewsActive = (options) => (
    <div className="toggle-switch">
      <InputSwitch checked={options.trendingNews} onChange={() => openConfirmDialog(options)} disabled={trendingNewsToggleDisable} />
    </div>
  )

  const freeNewsActive = (options) => (
    <div className="toggle-switch">
      <InputSwitch checked={options.freeNews || options.showFullfreeNews} onChange={() => freeNewsDialog(options)} disabled={freeNewsToggleDisable} />
    </div>
  )

  const actionTemplate = (rowData) => {
    const isRejected = rowData.isRejected

    return (
      <div className="action-container">
        <div>
          <Button type="button" className="user-preview-icon" tooltip={t('TOOLTIP_USER_PREVIEW')} onClick={() => viewNewsRedirect(rowData)} tooltipOptions={{ position: 'top' }}>
            <PreviewIcon className="user-preview-image" />
          </Button>
        </div>
        <div>
          <Button type="button" className="admin-review-icon" tooltip={t('TOOLTIP_ADMIN_PREVIEW')} onClick={() => adminReviewRedirect(rowData)} tooltipOptions={{ position: 'top' }}>
            <ReviewIcon className="admin-review-image" />
          </Button>
        </div>

        <div>
          {isRejected ? (
            <Button type="button" className="reject-remark-icon" tooltip={t('TOOLTIP_REJECT_REMARK_PREVIEW')} onClick={() => remarkReviewRedirect(rowData)} tooltipOptions={{ position: 'top' }}>
              <RemarkReviewIcon className="remark-preview-image" />
            </Button>
          ) : (
            <Button type="button" className="empty-button" />
          )}
        </div>

        <Button type="button" icon="pi pi-pencil" className="pi-edit-icon" tooltip={t('EDIT')} onClick={() => editNewsRedirect(rowData)} tooltipOptions={{ position: 'top' }} disabled={editNewsIconDisable} data-testid="edit-button" />
        <Button type="button" icon="pi pi-trash" className="pi-delete-icon" tooltip={t('DELETE')} onClick={() => deleteConfirmation(rowData)} tooltipOptions={{ position: 'top' }} disabled={deleteNewsIconDisable} data-testid="delete-button" />
      </div>
    )
  }

  const columns = [
    {
      body: statusTemplate,
      text: t('Status'),
      className: 'news-status-column'
    },
    {
      dataField: 'subject',
      text: t('NEWS_SUBJECT'),
      className: 'news-subject-column',
      sortable: true
    },
    {
      dataField: 'author',
      text: t('NEWS_AUTHOR'),
      className: 'news-author-column',
      sortable: true
    },
    {
      dataField: 'newsDate',
      text: t('PUBLISHED_DATA'),
      className: 'news-date-column'
    },
    {
      body: trendingNewsActive,
      dataField: 'trendingNews',
      text: t('TRENDING_NEWS'),
      className: 'news-trending-column'
    },
    {
      body: freeNewsActive,
      dataField: 'freeNews',
      text: t('FREE_NEWS'),
      className: 'news-trending-column'
    },
    {
      dataField: 'views',
      text: t('VIEWS'),
      className: 'news-views-column'
    },
    {
      body: actionTemplate,
      text: t('ACTIONS'),
      className: 'news-actions-column'
    }
  ]

  const handleTimeChange = (selectedValue) => {
    setErrorMessageForHours(false)
    setTrendingNewsHoursField('')
    setSelectTrendingNewsDropdown(selectedValue)
    if (selectedValue?.label === 'Other') {
      setOtherInputVisible(true)
    } else {
      setOtherInputVisible(false)
    }
  }

  const handleSubmitTrendingNewsTime = () => {
    const trendingNewsHours = selectTrendingNewsDropdown?.hours === 'Other' ? trendingNewsHoursField : selectTrendingNewsDropdown?.label || trendingNewsHoursField

    setErrorMessageForHours(false)
    rejectTrendingNews(trendingNewsData?.id, true, trendingNewsHours)
    setIsTrendingNewsDialogOpen(false)
    setOtherInputVisible(false)
  }

  const handleCancel = () => {
    setIsTrendingNewsDialogOpen(false)
    setSelectTrendingNewsDropdown('')
    setTrendingNewsHoursField('')
    setOtherInputVisible(false)
    setErrorMessageForHours(false)
    setShowFreeNewsModal(false)
    setIsCheckBoxDisabled(true)
  }

  const isSubmitButtonDisabled = !selectTrendingNewsDropdown || otherInputVisible ? !trendingNewsHoursField || errorMessageForHours : ''

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  const handleCheckBox = (e) => {
    const { freeNews: initialFreeNews, showFullfreeNews: initialShowFullfreeNews } = initialEachRowData

    const { name, checked } = e.target

    if (name === 'free-news') {
      setIsCheckBoxDisabled(initialFreeNews === checked)
      setEachRowData((previousStates) => ({ ...previousStates, freeNews: checked }))
      if (!checked) {
        setEachRowData((previousStates) => ({ ...previousStates, showFullfreeNews: checked }))
      }
    } else if (name === 'full-free-news') {
      setIsCheckBoxDisabled(!(initialFreeNews !== checked) && initialShowFullfreeNews !== checked)

      setEachRowData((previousStates) => ({ ...previousStates, showFullfreeNews: checked }))
    }
  }

  const tableResponseMessage = () => <div>{showTableMessage ? t('FETCHING') : t('NO_RESULTS_FOUND')}</div>

  const onPageChange = ({ page: pageNumber }) => {
    fetchUrlParams(pageNumber + 1)
  }

  const fetchUrlParams = (pageNumber) => {
    const newsFilter = searchParams.get('newsFilter')
    const language = searchParams.get('language')

    updateUrlParams(newsFilter, language, pageNumber)
    fetchFilterNews(newsFilter, language, pageNumber, rowCount, searchQuery)
  }

  const updateUrlParams = (filterValue, languageValue, pageNumber) => {
    setPageNumberSelected(pageNumber)
    params.set('newsFilter', filterValue)
    params.set('language', languageValue)
    params.set('page', pageNumber)
    history.replace({ pathname: ROUTE_STRINGS.listNewsFeed, search: params.toString() })
  }

  return (
    <div className="list-news-feed-layout">
      <LayoutContainer title={'LIST_NEWS_FEED'} breadCrumbsTitle={'listNewsFeed'} showAddButton={showAddButton} handleAddButtonClick={handleAddButtonClick} data-testid="breadcrumbs-title">
        <div className="list-news-feed-container">
          <div className="search-dropdown-wrapper">
            <div className="dropdown-container">
              <div className="status-filter-dropdown">
                <Dropdown value={selectedNewsStatus} options={newsFilterTypes} optionLabel="typeOfNews" onChange={(e) => handleNewsType(e)} placeholder={t('FILTER_NEWS')} data-testid="allNewsDropdown" />
              </div>
              <div className="language-dropdown">
                <Dropdown options={languages} optionLabel="languageName" value={selectedLanguage} onChange={(e) => handleLanguageType(e)} placeholder={t('FILTER_BY_LANGUAGE')} data-testid="allLanguageDropdown" />
              </div>
            </div>
            <div className="search-field p-input-icon-left">
              <i className="pi pi-search" data-testid="search-icon" />
              <InputText value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder={t('SEARCH')} data-testid="searchInput" />
            </div>
          </div>
          <DataTable value={newsData} first={0} rows={rowCount} removableSort pageLinkSize={paginationButtons} emptyMessage={tableResponseMessage} className="list-news-table">
            {columns.map((column, index) => (
              <Column key={index} field={column.dataField} header={column.text} className={column.className} body={column.body} sortable={column.sortable} />
            ))}
          </DataTable>
          {newsData?.length > 0 && (
            <>
              <Paginator first={(pageNumberSelected - 1) * rowCount} rows={rowCount} totalRecords={+newsTotalRecord} pageLinkSize={paginationButtons} onPageChange={onPageChange} />
              <Divider className="pagination-divide" />
            </>
          )}
        </div>

        <Dialog
          className="edit-approved-news-dialog"
          visible={openOtpDialog}
          breakpoints={{ '1669px': '26vw', '1300px': '35vw', '960px': '50vw', '768px': '50vw', '641px': '75vw' }}
          header={t('ENTER_OTP_FOR_EDIT_NEWS')}
          onHide={() => setOpenOtpDialog(false)}
          footer={
            <div className="edit-approved-news-dialog-footer">
              <ButtonComponent type="cancel" onClick={() => closeOtpDialog()} />
              <ButtonComponent type="submit" disabled={!submitOtp.editNewsOtp} onClick={handleSubmitOtp} />
            </div>
          }
        >
          <div className="edit-news-otp-field">
            <Form
              onSubmit={onSubmit}
              initialValues={submitOtp}
              validate={validate}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="p-fluid">
                  <div className="field">
                    <Field
                      name="editNewsOtp"
                      render={({ input, meta }) => (
                        <div className="edit-news-otp">
                          <span className="p-float-label">
                            <InputText {...input} id="editNewsOtp" className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} value={submitOtp.editNewsOtp} onChange={handleChange} placeholder={t('ENTER_OTP')} data-testid="optField" />
                            <label htmlFor="editNewsOtp" className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                              {t('OTP')}
                              <span className="span-text-color">*</span>
                            </label>
                          </span>

                          {otpMismatchMessage ? <div className="otp-error-message">{t('OTP_MISS_MATCH')}</div> : <div className="error-message"> {getFormErrorMessage(meta)} </div>}
                        </div>
                      )}
                    />
                  </div>
                </form>
              )}
            />
          </div>
        </Dialog>
        <DialogModal {...showDialog} onDismiss={handleDismiss} />

        <Dialog header={t('TRENDING_NEWS_TIME_DURATION')} visible={isTrendingNewsDialogOpen} onHide={handleCancel} className="trending-news-dialog">
          <div data-testid="trendingNewsTimeDuration-form">
            <Form
              onSubmit={onSubmit}
              initialValues={{ selectTrendingNewsDropdown, trendingNewsHoursField }}
              validate={validate}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="p-fluid">
                  <Field
                    name="trendingNewsHoursDropdown"
                    render={({ input, meta }) => (
                      <div className="field">
                        <span className="p-float-label">
                          <Dropdown {...input} id="trendingNewsHoursDropdown" options={trendingNewsTime} optionLabel="hours" onChange={(e) => handleTimeChange(e.value)} value={selectTrendingNewsDropdown} className={classNames('trending-news-time-dropdown ', { 'p-invalid': isFormFieldValid(meta) })} data-testid="trendingNewsTimeDropdown" />
                          <label htmlFor="trendingNewsHoursDropdown" className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                            {t('SELECT_DURATION')} <span className="required-star"> *</span>
                          </label>
                        </span>
                        <div className="error-message">{getFormErrorMessage(meta)}</div>{' '}
                      </div>
                    )}
                  />
                  <div className="trending-news-div">
                    {otherInputVisible && (
                      <Field
                        name="trendingNewsHoursInputField"
                        render={({ input, meta }) => (
                          <div className="field">
                            <span className="p-float-label">
                              <InputText id="trendingNewsHoursInputField" {...input} onChange={handleChange} value={trendingNewsHoursField} className={classNames('trending-news-time-input-field', { 'p-invalid': isFormFieldValid(meta) })} placeholder={t('ENTER_DURATION')} data-testid="enterDurationField" />
                              <label htmlFor="trendingNewsHoursInputField" className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                                {t('ENTER_DURATION')} <span className="required-star"> *</span>
                              </label>
                            </span>
                            <div className="error-message">{getFormErrorMessage(meta)}</div>{' '}
                          </div>
                        )}
                      />
                    )}
                    {errorMessageForHours && <p className="error-for-hours">{t('HOURS_SHOULD_BE_LESS_THAN_200')}</p>}
                  </div>
                </form>
              )}
            />
          </div>

          <div className="button-container">
            <ButtonComponent type="cancel" onClick={handleCancel} />
            <ButtonComponent type="submit" onClick={handleSubmitTrendingNewsTime} disabled={isSubmitButtonDisabled} />
          </div>
        </Dialog>
        <Dialog
          className="free-news-dialog"
          visible={showFreeNewsModal}
          breakpoints={{ '1669px': '26vw', '1300px': '35vw', '960px': '50vw', '768px': '50vw', '641px': '75vw' }}
          header={t('SELECT_FREE_NEWS_STATUS')}
          onHide={handleCancel}
          footer={
            <div className="free-news-approved-news-dialog-footer">
              <ButtonComponent type="cancel" onClick={handleCancel} />
              <ButtonComponent type="submit" onClick={updateFreeNewsStatus} disabled={isCheckBoxDisabled} />
            </div>
          }
        >
          <div className="free-news-input-wrapper">
            <div className="free-news-checkbox">
              <Checkbox name="free-news" checked={eachRowData?.freeNews} onChange={handleCheckBox} data-testid="freeNewsCheckbox" />
              <label htmlFor="free-news">{t('FREE_NEWS')}</label>
            </div>
            <div className="free-news-checkbox">
              <Checkbox name="full-free-news" checked={eachRowData?.showFullfreeNews} onChange={handleCheckBox} disabled={!eachRowData?.freeNews} data-testid="fullNewsCheckbox" />
              <label htmlFor="full-free-news">{t('FULL_FREE_NEWS')}</label>
            </div>
          </div>
        </Dialog>
        <div>
          <NewsDeleteModal setDeletedNews={setDeletedNews} openRemarkDialog={openRemarkDialog} setOpenRemarkDialog={setOpenRemarkDialog} eachRowData={eachRowData} />
        </div>
      </LayoutContainer>
    </div>
  )
}

export default Loader(ListNewsFeed)
