import './styles.scss'
import React, { useEffect, useState, useRef } from 'react'
import * as XLSX from 'xlsx'
import { useTranslation } from 'react-i18next'
import { FileUpload } from 'primereact/fileupload'
import { Button } from 'primereact/button'
import { Tooltip } from 'primereact/tooltip'
import { Card } from 'primereact/card'
import { Dropdown } from 'primereact/dropdown'
import { Divider } from 'primereact/divider'
import { Dialog } from 'primereact/dialog'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import LayoutContainer from 'Components/LayoutComponent'
import DialogModal from 'Components/UIComponents/DialogModal'
import Loader from 'Components/Loader'
import { getLocalUserDetails } from 'Utils/LocalStorageHandler'
import { commonMethods } from 'Utils/commonMethods'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { ButtonComponent } from 'Components/UIComponents'

const GlobalPrice = ({ setLoading }) => {
  const fileUploadRef = useRef(null)
  const { t } = useTranslation()

  const [xlData, setXlData] = useState([])
  const [countryList, setCountryList] = useState([])
  const [flagForRawCoffeePrice, setFlagForRawCoffeePrice] = useState('')
  const [flagForDifferential, setFlagForDifferential] = useState(null)
  const [flagForFreightRate, setFlagForFreightRate] = useState('')
  const [fileUploadKey, setFileUploadKey] = useState(0)
  const [dropdownError, setDropdownError] = useState(false)
  const [dataEdited, setDataEdited] = useState(false)
  const [countryCodeForRowCoffeePrice, setCountryCodeForRowCoffeePrice] = useState('')
  const [countryCodeForGlobalDifferential, setCountryCodeForGlobalDifferential] = useState('')
  const [countryCodeForFreightRates, setCountryCodeForFreightRates] = useState('')
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false)
  const [showPreviewPopup, setShowPreviewPopup] = useState(false)
  const [modalState, setModalState] = useState({
    showModal: false,
    message: '',
    modalType: '',
    softAction: ''
  })

  useEffect(() => {
    getAllCountryData()
  }, [])

  useEffect(() => {
    const hasEmptyCell = checkForEmptyElement(xlData)

    setIsSubmitButtonDisabled(hasEmptyCell)
  }, [xlData])

  function checkForEmptyElement(xlData) {
    const hasEmptyElement = xlData?.some((ele) => Object.keys(ele)?.some((field) => ele[field] === ''))

    return hasEmptyElement
  }

  const onCropYearChange = (e, rowData) => {
    const updatedData = xlData?.map((dataItem) => {
      if (dataItem === rowData) {
        return { ...dataItem, CropYear: e.target.value }
      }

      return dataItem
    })

    setXlData(updatedData)
  }

  const handleCropYearBlur = (e, rowData) => {
    setDataEdited(true)
    divWithData(rowData)
  }

  const cropYearBodyTemplate = (rowData, column) => {
    if (rowData.isCropYear && column.rowIndex === xlData.length - 1) {
      return <InputText type="text" value={rowData[column.field]} onBlur={(e) => handleCropYearBlur(e, rowData)} onChange={(e) => onCropYearChange(e, rowData, column)} autoFocus />
    }

    return rowData[column.field]
  }

  const divWithData = (rowData) => <div>{rowData.CropYear}</div>

  const columns = [
    { className: 'rowReorder', rowReorder: true },
    { field: 'CountryName', header: t('COUNTRY_NAME'), className: 'countryName' },
    { field: 'CropYear', header: t('CROP_YEAR'), className: 'cropYear', body: dataEdited ? divWithData : cropYearBodyTemplate },
    { field: 'QualityName', header: t('QUALITY_NAME'), className: 'qualityName' },
    { field: 'QualityCode', header: t('QUALITY_CODE'), className: 'qualityCode' },
    { field: 'UpdatedDate', header: t('UPDATED_DATE'), className: 'UpdatedDate' },
    { field: 'Terminal', header: t('TERMINAL'), className: 'termial' },
    { field: 'TerminalLevel', header: t('TERMINAL_LEVEL'), className: 'termialLevel' },
    { field: 'RangeStart', header: t('RANGE_STARTS'), className: 'rangeStart' },
    { field: 'RangeEnd', header: t('RANGE_ENDS'), className: 'rangeEnd' },
    { field: 'Remark', header: t('REMARK'), className: 'remark' },
    { field: 'QualityGroup', header: t('QUALITY_GROUP'), className: 'qualityGroup' },
    { field: 'Moisture', header: t('MOISTURE'), className: 'moisture' },
    { field: 'ScreenRetention', header: t('SCREEN_RETENTION'), className: 'screenRetention' },
    { field: 'Defects', header: t('DEFECTS'), className: 'defects' },
    { field: 'FobPort', header: t('FOB_PORT'), className: 'fobPort' },
    { field: 'ForexValue', header: t('FOREX_VALUE'), className: 'forexValue' },
    { field: 'ForexType', header: t('FOREX_TYPE'), className: 'forexType' }
  ]

  const getAllCountryData = async () => {
    try {
      setLoading(true)
      const { data } = await apiAdapterCoffeeWeb.getAllCountries()
      const sellerCountries = data.filter((ele) => ele.isCoffeeProducingCountry)

      setCountryList(sellerCountries)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader()

      fileReader.readAsArrayBuffer(file)
      fileReader.onload = (e) => {
        const bufferArray = e.target.result
        const workbook = XLSX.read(bufferArray, { type: 'buffer' })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const data = XLSX.utils.sheet_to_json(worksheet)

        resolve(data)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })

    promise.then((data) => {
      setXlData(data)
    })
  }

  const handleExcelFileUpload = (e) => {
    const file = e.files[0]

    readExcel(file, setXlData)

    if (flagForDifferential === null) {
      setDropdownError(true)
    }
  }

  const flagDropdown = (option) => (
    <div className="flag-image-container">
      <img alt="flag" src={option.flagsUrl} className="flag-image" />
      <span>{option.currencyName}</span>
    </div>
  )

  const flagCountryDropdown = (option) => (
    <div className="flag-image-container">
      <img alt="flag" src={option.flagsUrl} className="flag-image" />
      <span>{option.nicename}</span>
    </div>
  )

  const handleChangeForRawCoffeePrice = (e) => {
    setCountryDetails(e, setFlagForRawCoffeePrice, setCountryCodeForRowCoffeePrice)
  }

  const handleChangeForGlobalDifferential = (e) => {
    setCountryDetails(e, setFlagForDifferential, setCountryCodeForGlobalDifferential)
  }

  const handleChangeForFreightRate = (e) => {
    setCountryDetails(e, setFlagForFreightRate, setCountryCodeForFreightRates)
  }

  const setCountryDetails = (e, setFlag, setCountryCode) => {
    setFlagForRawCoffeePrice(null)
    setCountryCodeForRowCoffeePrice(null)
    setFlagForDifferential(null)
    setCountryCodeForGlobalDifferential(null)
    setFlagForFreightRate(null)
    setCountryCodeForFreightRates(null)

    setFlag(e.value)
    setCountryCode(e?.value?.id)
  }

  const chooseOptions = { icon: 'pi pi-fw pi-file', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' }
  const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' }
  const cancelOptions = {
    icon: 'pi pi-fw pi-times',
    iconOnly: true,
    className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'
  }
  const footerContent = <ButtonComponent type="custom" label="SUBMIT" variant="action" onClick={() => postData()} disabled={xlData?.length === 0 || isSubmitButtonDisabled} />

  const cellEditor = (options) => {
    if (options.field === 'CountryName') return countryName(options)

    return textEditor(options)
  }

  const onInputBlur = (options) => {
    const modifiedData = xlData.map((ele, index) => (options.rowIndex === index ? { ...ele, [options.field]: options.value } : ele))

    setXlData(modifiedData)
  }

  const textEditor = (options) => {
    const { CountryName, CropYear, Defects, Moisture, QualityCode, QualityGroup, QualityName, UpdatedDate, Terminal, TerminalLevel, RangeEnd, RangeStart, Remark, ScreenRetention, FobPort, ForexValue, ForexType } = options.rowData

    if (!CountryName || !CropYear || !Defects || !Moisture || !QualityCode || !QualityGroup || !UpdatedDate || !Terminal || !TerminalLevel || !QualityName || !RangeEnd || !RangeStart || !Remark || !ScreenRetention || !FobPort || !ForexValue || !ForexType) {
      setIsSubmitButtonDisabled(true)
    } else {
      setIsSubmitButtonDisabled(false)
    }

    if (options.rowData) return <InputText type="text" value={options.value} onBlur={() => onInputBlur(options)} onChange={(e) => options.editorCallback(e.target.value)} />
  }

  const countryName = (options) => {
    const defaultCountry = countryList.find((ele) => {
      if (ele.nicename === options.rowData.CountryName) {
        return ele
      }
    })

    return <Dropdown value={defaultCountry} options={countryList} onChange={(e) => handleChangeCountry(e, options)} optionLabel="nicename" editable placeholder="Select a City" />
  }

  const handleChangeCountry = (e, options) => {
    const { id } = e.target.value
    const findCountry = countryList.find((ele) => ele.id === id)
    const modifiedData = xlData.map((ele, index) => {
      if (options.rowIndex === index) {
        return {
          ...ele,
          [options.field]: findCountry.nicename
        }
      }

      return ele
    })

    setXlData(modifiedData)
  }

  const addRowForGlobalDifferentials = () => {
    const newObject = {
      CountryName: 'India',
      CropYear: '',
      isCropYear: true,
      Defects: '',
      Moisture: '',
      QualityCode: '',
      UpdatedDate: '',
      Terminal: '',
      TerminalLevel: '',
      QualityGroup: '',
      QualityName: '',
      RangeEnd: '',
      RangeStart: '',
      Remark: '',
      ScreenRetention: '',
      FobPort: '',
      ForexValue: '',
      ForexType: ''
    }

    setIsSubmitButtonDisabled(true)
    setDataEdited(false)
    setXlData([...xlData, newObject])
  }

  const deleteRow = (options) => {
    setModalState({
      showModal: true,
      message: t('ARE_YOU_SURE_WANT_TO_DELETE_THIS_ROW'),
      modalType: 'info',
      confirmAction: () => confirmDeleteRow(options.rowIndex)
    })
  }

  const confirmDeleteRow = (itemIndex) => {
    const updatedData = xlData.filter((ele, index) => index !== itemIndex)

    setXlData(updatedData)
    setModalState({ showModal: false })
  }

  const postData = () => {
    if (flagForRawCoffeePrice) uploadRawCoffeePriceData()
    else if (flagForDifferential) uploadGlobalDifferentialData()
    else uploadGlobalFreightRatesData()
  }

  const uploadRawCoffeePriceData = async () => {
    setLoading(true)
    try {
      const { id: idUser } = getLocalUserDetails()

      const postData = xlData.map((xlItem) => {
        const { Currency, CropYear, ProducingCountry, QualityName, Low, High, BagWeight, Mc, OutTurn, Remarks } = xlItem

        return {
          id: 0,
          idUser,
          idCurrency: countryCodeForRowCoffeePrice,
          currency: Currency,
          cropYear: CropYear,
          producingCountry: ProducingCountry,
          qualityName: QualityName,
          low: Low?.toString(),
          high: High?.toString(),
          bagWeight: BagWeight,
          mc: Mc?.toString(),
          outTurn: OutTurn?.toString(),
          remarks: Remarks
        }
      })

      const response = await apiAdapterCoffeeWeb.postInsertCoffeePriceAdmin(postData)

      if (response.data === true) {
        setFileUploadKey(fileUploadKey + 1)
      }
    } finally {
      setLoading(false)
    }
  }

  const uploadGlobalDifferentialData = async () => {
    setLoading(true)
    try {
      const { id: idUser } = getLocalUserDetails()
      const postData = xlData.map((xlItem) => {
        const { CountryName, QualityName, QualityCode, UpdatedDate, Terminal, TerminalLevel, RangeStart, RangeEnd, Remark, QualityGroup, Moisture, ScreenRetention, Defects, CropYear, FobPort, ForexValue, ForexType } = xlItem

        return {
          id: 0,
          idUser,
          idCountry: countryCodeForGlobalDifferential,
          countryName: CountryName,
          qualityName: QualityName?.toString(),
          qualityCode: QualityCode?.toString(),
          updatedDate: UpdatedDate?.toString(),
          terminal: Terminal?.toString(),
          terminalLevel: TerminalLevel?.toString(),
          rangeStart: RangeStart?.toString(),
          rangeEnd: RangeEnd?.toString(),
          remark: Remark?.toString(),
          qualityGroup: QualityGroup?.toString(),
          moisture: Moisture?.toString(),
          screenRetention: ScreenRetention?.toString(),
          defects: Defects?.toString(),
          cropFor: CropYear?.toString(),
          orderBy: 0,
          fobPort: FobPort?.toString(),
          forexValue: ForexValue?.toString(),
          forexType: ForexType?.toString()
        }
      })

      const response = await apiAdapterCoffeeWeb.postInsertGlobalDifferentialAdmin(postData)

      if (response.data === true) {
        setFileUploadKey(fileUploadKey + 1)
        setDropdownError(false)
        setModalState({
          showModal: true,
          message: t('EXCEL_DATA_ADDED_SUCCESSFULLY'),
          modalType: 'success'
        })
        setXlData(null)
        setShowPreviewPopup(false)
        setFlagForDifferential(null)
        setCountryCodeForGlobalDifferential(null)
      } else {
        setModalState({
          showModal: true,
          message: t('SOMETHING_WENT_WRONG'),
          modalType: 'error'
        })
      }
    } catch {
      setModalState({
        showModal: true,
        message: t('FAILED_TO_ADD_GLOBAL_DIFFERENTIALS'),
        modalType: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const uploadGlobalFreightRatesData = async () => {
    setLoading(true)
    try {
      const { id: userId } = getLocalUserDetails()

      const postData = xlData.map(({ LoadingPort, DestinationPort, DestinationPortCountry, FreightRateFrom, FreightRateTo, TransitPeriod, Others }) => ({
        userId,
        baseCountryCode: countryCodeForFreightRates,
        LoadingPort,
        DestinationPort,
        destinationPortCountry: DestinationPortCountry?.toString(),
        freightRateFrom: FreightRateFrom?.toString(),
        freightRateTo: FreightRateTo?.toString(),
        transitPeriod: TransitPeriod?.toString(),
        otherInfo: Others?.toString()
      }))

      const response = await apiAdapterCoffeeWeb.postInsertFreightRatesAdmin(postData)

      if (response.data === true) {
        setFileUploadKey(fileUploadKey + 1)
      }
    } finally {
      setLoading(false)
    }
  }

  const onHide = () => setModalState({ showModal: false })

  const onDismissFunction = () => setModalState({ showModal: false })

  const onSuccess = () => setModalState({ showModal: false })

  const handleBlur = (e) => {
    const { name, value } = e.target

    if (name === 'country-dropdown' && !value) {
      setDropdownError(true)
    }
  }

  return (
    <>
      <LayoutContainer title={t('GLOBAL_DATA')} breadCrumbsTitle="globalData">
        <div className="global-data">
          <div className="card-container">
            {/* on hold- required in future */}
            {/* <div className="card">
              <Card>
                <div className="card-header-container">
                  <h3 className="title">{t('GLOBAL_RAW_COFFEE_PRICE')}</h3>
                  <a href="https://coffeeweb.s3.ap-south-1.amazonaws.com/Excel+Templates/GlobalRawCoffeePriceTemplate.xlsx" download>
                    <Button icon="pi pi-download" tooltipOptions={{ position: 'top' }} className="download-templates-button" tooltip="Download Templates" />
                  </a>
                </div>
                <Divider />
                <div className="card-content">
                  <div className="card-dropdown">
                    <Dropdown filter id="country-select-for-raw-coffee-price" itemTemplate={flagDropdown} value={flagForRawCoffeePrice} options={countryList} optionLabel="currencyName" placeholder={t('CHOOSE_A_CURRENCY')} onChange={handleChangeForRawCoffeePrice} showClear={!!flagForRawCoffeePrice} disabled />
                  </div>
                  <div className="template-upload-div">
                    <p>{t('UPLOAD_FILE_HERE')}</p>
                  </div>
                  <div className="file-upload">
                    <Tooltip target=".custom-choose-btn" content={t('BROWSE_FILES')} position="top" />
                    <Tooltip target=".custom-upload-btn" content={t('UPLOAD_FILE')} position="top" />
                    <Tooltip target=".custom-cancel-btn" content={'CLEAR'} position="top" />
                    <FileUpload key={fileUploadKey} ref={fileUploadRef} accept=".xlsx, .xls" onSelect={handleExcelFileUpload} chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} disabled />
                    {flagForRawCoffeePrice && (
                      <div className="preview-section">
                        <Button className="primary-buttom-style" label={t('PREVIEW')} size="small" onClick={() => setShowPreviewPopup(true)} />
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            </div> */}

            <div className="card">
              <Card>
                <div className="card-header-container">
                  <h3 className="title">{t('GLOBAL_DIFFERENTIAL')}</h3>
                  <a href="https://coffeeweb.s3.ap-south-1.amazonaws.com/Excel+Templates/GlobalDifferentialTemplate.xlsx" download>
                    <Button icon="pi pi-download" tooltipOptions={{ position: 'top' }} className="download-templates-button" tooltip="Download Templates" />
                  </a>
                </div>
                <Divider />

                <div className="card-content">
                  <div className="card-dropdown">
                    <span className="p-float-label w-full md:w-14rem">
                      <Dropdown filter filterPlaceholder="Search Country" inputId="country-list" value={flagForDifferential} options={countryList} name="country-dropdown" optionLabel="nicename" showClear={!!flagForDifferential} className={!flagForDifferential && dropdownError ? 'p-invalid' : 'custom-dropdown'} onChange={handleChangeForGlobalDifferential} onBlur={handleBlur} />
                      <label inputId="country-list" className="country-dropdown-label">
                        {t('CHOOSE_A_COUNTRY')}
                        <span className="star-required">*</span>{' '}
                      </label>
                    </span>
                    {!flagForDifferential && dropdownError && <span className="error-message">{t('PLEASE_SELECT_COUNTRY')}</span>}
                  </div>
                  <div className="template-upload-div">
                    <p>{t('UPLOAD_FILE_HERE')}</p>
                  </div>

                  <div className="file-upload">
                    <div>
                      <div>
                        <Tooltip target=".custom-choose-btn" content={t('BROWSE_FILES')} position="top" />
                        <Tooltip target=".custom-upload-btn" content={t('UPLOAD_FILE')} position="top" />
                        <Tooltip target=".custom-cancel-btn" content={t('CLEAR')} position="top" />
                        <FileUpload key={fileUploadKey} ref={fileUploadRef} accept=".xlsx, .xls" onSelect={handleExcelFileUpload} chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
                        {flagForDifferential && (
                          <div className="preview-section">
                            <ButtonComponent type="custom" label="PREVIEW" variant="action" onClick={() => setShowPreviewPopup(true)} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>

            {/* on hold- required in future */}
            {/* <div className="card">
              <Card>
                <div className="card-header-container">
                  <h3 className="title">{t('GLOBAL_FREIGHT_RATES')}</h3>
                  <a href="https://coffeeweb.s3.ap-south-1.amazonaws.com/Excel+Templates/GlobalFreightRateTemplate.xltx" download>
                    <Button icon="pi pi-download" tooltipOptions={{ position: 'top' }} className="download-templates-button" tooltip="Download Templates" />
                  </a>
                </div>
                <Divider />

                <div className="card-content">
                  <div className="card-dropdown">
                    <Dropdown filter id="country-select-for-freight-rate" value={flagForFreightRate} options={countryList} optionLabel="nicename" placeholder={t('CHOOSE_A_COUNTRY')} onChange={handleChangeForFreightRate} itemTemplate={flagCountryDropdown} showClear={!!flagForFreightRate} disabled />
                  </div>
                  <div className="template-upload-div">
                    <p>{t('UPLOAD_FILE_HERE')}</p>
                  </div>

                  <div className="file-upload">
                    <div>
                      <div>
                        <Tooltip target=".custom-choose-btn" content={t('BROWSE_FILES')} position="top" />
                        <Tooltip target=".custom-upload-btn" content={t('UPLOAD_FILE')} position="top" />
                        <Tooltip target=".custom-cancel-btn" content={t('CLEAR')} position="top" />
                        <FileUpload key={fileUploadKey} ref={fileUploadRef} accept=".xlsx, .xls" onSelect={handleExcelFileUpload} chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} disabled />
                        {flagForFreightRate && (
                          <div className="preview-section">
                            <Button className="primary-buttom-style" label={t('PREVIEW')} size="small" onClick={() => setShowPreviewPopup(true)} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div> */}

            <Dialog className="global-data-modal-popup" header="Global Differentials" visible={showPreviewPopup} style={{ width: '95vw', height: '100vh' }} onHide={() => setShowPreviewPopup(false)} footer={footerContent}>
              <div className="sub-header">
                <div className="title"></div>
                <div>
                  <ButtonComponent type="custom" label="ADD" variant="action" icon="pi pi-plus" onClick={() => addRowForGlobalDifferentials()} />
                </div>
              </div>

              <div className="card draggable-table">
                <DataTable className="card" scrollable scrollHeight="60vh" reorderableRows onRowReorder={(e) => setXlData(e.value)} value={xlData} editMode="cell" tableStyle={{ minWidth: '50rem' }}>
                  {columns.map(({ field, header, className, rowReorder, body }) => (
                    <Column key={field} field={field} header={header} rowReorder={rowReorder} className={className} body={body} editor={(options) => cellEditor(options)} />
                  ))}
                  <Column body={(rowData, options) => <Button icon="pi pi-trash" onClick={() => deleteRow(options)} />} />
                </DataTable>
              </div>
            </Dialog>
          </div>
        </div>
        <DialogModal {...modalState} onDismiss={onDismissFunction} onRetry={commonMethods.handleRetry} onHide={onHide} onSuccess={onSuccess} onConfirmation={modalState?.confirmAction} />
      </LayoutContainer>
    </>
  )
}

export default Loader(GlobalPrice)
