import './styles.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from 'primereact/card'
import LayoutContainer from 'Components/LayoutComponent'

const Dashboard = () => {
  const { t } = useTranslation()

  return (
    <LayoutContainer breadCrumbsTitle={'dashBoard'}>
      <Card className="container">
        <h1 className="">{t('welcomeToCW')}</h1>
      </Card>
    </LayoutContainer>
  )
}

export default Dashboard
