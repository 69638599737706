import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { classNames } from 'primereact/utils'
import { InputText } from 'primereact/inputtext'
import LayoutContainer from 'Components/LayoutComponent'
import Loader from 'Components/Loader'
import CountrySelector from 'Components/UIComponents/CountrySelector'
import { getLocalUserDetails } from 'Utils/LocalStorageHandler'
import { ROUTE_STRINGS } from 'Utils/Constants'
import Validation from 'Utils/Validation'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'

const EditLanguage = ({ setLoading, isMockLanguageNameExists }) => {
  const { state } = useLocation()
  const history = useHistory()
  const { id } = getLocalUserDetails()
  const { t } = useTranslation()

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
  const [allLanguageData, setAllLanguageNameData] = useState([])
  const [isLanguageNameExists, setIsLanguageNameExists] = useState(false)
  const [isLocalNameExists, setIsLocalNameExists] = useState(false)
  const [editLanguageData, setEditLanguageData] = useState({
    idLanguage: state.idLanguage,
    country: state.nicename,
    languageName: state.languageName,
    countryId: state.countryId,
    localLanguageName: state.localLanguageName,
    isActive: state.isActive
  })
  const [dialogModalStates, setDialogModalStates] = useState({
    showModal: '',
    modalType: '',
    message: '',
    onHide: '',
    onDismiss: '',
    onRetry: ''
  })

  // useEffect to initialize state for testing purposes
  useEffect(() => {
    if (isMockLanguageNameExists) {
      setIsLanguageNameExists(isMockLanguageNameExists)
    }
  }, [])

  const { idLanguage, languageName, countryId, localLanguageName, isActive, country } = editLanguageData
  const { nicename: initialCountryName, languageName: initialLanguageName, localLanguageName: initialLocalLanguageName, countryId: initialCountryId } = state

  const languageList = async () => {
    try {
      const response = await apiAdapterCoffeeWeb.getAllLanguageList()
      const languageData = response.data.returnLst

      setAllLanguageNameData(languageData)
    } catch {
      setDialogModalStates({
        ...dialogModalStates,
        showModal: true,
        modalType: 'error',
        message: 'NO_DATA_FOUND',
        onHide: handleDismiss,
        onDismiss: handleDismiss
      })
    }
  }

  const UpdateLanguageData = async () => {
    setLoading(true)
    const { lastupdatedDate } = state
    const dateOnly = new Date().toISOString().slice(0, 10)
    const timeOnly = new Date().toString().substr(16, 8)

    try {
      const postLanguageData = {
        idLanguage,
        languageName,
        countryId,
        isActive,
        forNews: true,
        localLanguageName,
        profileSettings: true,
        userId: id,
        createdDate: `${dateOnly}T${timeOnly}`,
        lastupdatedDate
      }

      const { data } = await apiAdapterCoffeeWeb.UpdateLanguage(postLanguageData)

      if (data.statusCode === 200 || data.statusCode === 201) {
        setDialogModalStates({
          ...dialogModalStates,
          showModal: true,
          modalType: 'success',
          message: 'LANGUAGE_DETAILS_UPDATED_SUCCESSFULLY',
          onHide: () => history.push(ROUTE_STRINGS.listLanguage)
        })
      }
    } catch {
      setDialogModalStates({
        ...dialogModalStates,
        showModal: true,
        modalType: 'error',
        message: 'SORRY_UNABLE_TO_UPDATE_LANGUAGE_DETAILS',
        onHide: () => setDialogModalStates({ ...dialogModalStates, showModal: false }),
        onDismiss: () => setDialogModalStates({ ...dialogModalStates, showModal: false }),
        onRetry: rePostData
      })
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (event) => {
    const { value, name } = event.target

    setSubmitButtonDisabled(false)
    if (name === 'languageName' && Validation.languageValidation(value)) {
      setEditLanguageData({ ...editLanguageData, languageName: value })
      if (+initialCountryId === +countryId) {
        const filteredLanguageName = allLanguageData.filter(({ idLanguage: itemId }) => itemId !== +state.idLanguage).map((ele) => ele)
        const filteredCountryLanguage = filteredLanguageName.filter(({ countryId: itemId }) => itemId === +countryId).map(({ languageName }) => languageName.toLowerCase())

        setIsLanguageNameExists(filteredCountryLanguage.some((item) => item.toLowerCase() === value.toLowerCase()))
      } else {
        const filteredCountryLanguage = allLanguageData.filter(({ countryId: itemId }) => itemId === +countryId).map(({ languageName }) => languageName)

        setIsLanguageNameExists(filteredCountryLanguage.some((item) => item.toLowerCase() === value.toLowerCase()))
      }
    } else if (name === 'localLanguageName' && (Validation.languageValidation(value) || value === '')) {
      setEditLanguageData({ ...editLanguageData, localLanguageName: value })

      if (+initialCountryId === +countryId) {
        const filteredLocalLanguageName = allLanguageData.filter(({ idLanguage: itemId }) => itemId !== +state.idLanguage).map((ele) => ele)
        const filteredCountryLocalLanguageName = filteredLocalLanguageName.filter(({ countryId: itemId }) => itemId === +countryId).map(({ localLanguageName }) => localLanguageName)

        setIsLocalNameExists(filteredCountryLocalLanguageName.some((item) => item.toLowerCase() === value.toLowerCase()))
      } else {
        const filteredCountryLocalLanguageName = allLanguageData.filter(({ countryId: itemId }) => itemId === +countryId).map(({ localLanguageName }) => localLanguageName)

        setIsLocalNameExists(filteredCountryLocalLanguageName.some((item) => item.toLowerCase() === value.toLowerCase()))
      }
    }
  }

  const handleChangeCountry = (countryId, countryData) => {
    const { nicename } = countryData

    setSubmitButtonDisabled(false)
    setEditLanguageData({ ...editLanguageData, country: nicename, countryId })
    if (+initialCountryId === +countryId) {
      const filterData = allLanguageData.filter(({ idLanguage: itemId }) => itemId !== +state.idLanguage).map((ele) => ele)
      const filteredCountryLanguage = filterData.filter(({ countryId: itemId }) => itemId === +countryId).map(({ languageName }) => languageName.toLowerCase())
      const filteredCountryLocalLanguageName = filterData.filter(({ countryId: itemId }) => itemId === +countryId).map(({ localLanguageName }) => localLanguageName)

      setIsLanguageNameExists(filteredCountryLanguage.some((item) => item.toLowerCase() === editLanguageData.languageName.toLowerCase()))
      setIsLocalNameExists(filteredCountryLocalLanguageName.some((item) => item.toLowerCase() === editLanguageData.localLanguageName.toLowerCase()))
    } else {
      const filteredCountryLanguage = allLanguageData.filter(({ countryId: itemId }) => +itemId === +countryId).map(({ languageName }) => languageName)
      const filteredCountryLocalLanguageName = allLanguageData.filter(({ countryId: itemId }) => itemId === +countryId).map(({ localLanguageName }) => localLanguageName)

      setIsLocalNameExists(filteredCountryLocalLanguageName.some((item) => item.toLowerCase() === editLanguageData.localLanguageName.toLowerCase()))
      setIsLanguageNameExists(filteredCountryLanguage.some((item) => item.toLowerCase() === editLanguageData.languageName.toLowerCase()))
    }
  }

  const rePostData = () => {
    setDialogModalStates({ ...dialogModalStates, showModal: false, modalType: 'error', message: 'SORRY_UNABLE_TO_UPDATE_LANGUAGE_DETAILS' })
    setTimeout(() => {
      UpdateLanguageData()
    }, 300)
  }

  const handleDismiss = () => {
    setDialogModalStates({ ...dialogModalStates, showModal: false })
  }

  const onSubmit = (form) => {
    form.restart()
  }

  const validate = (data) => {
    const requiredFields = {
      nicename: 'EDIT_COUNTRY_IS_REQUIRED',
      languageName: 'LANGUAGE_IS_REQUIRED',
      localLanguageName: 'LOCAL_LANGUAGE_IS_REQUIRED'
    }

    const errors = Object.keys(requiredFields).reduce((acc, field) => {
      if (!data[field]) {
        acc[field] = t(requiredFields[field])
      }

      return acc
    }, {})

    return errors
  }

  const isFieldCheck = country === initialCountryName && languageName?.toLowerCase() === initialLanguageName?.toLowerCase() && localLanguageName === initialLocalLanguageName
  const isButtonDisabled = isLocalNameExists || isLanguageNameExists || isFieldCheck || !languageName || !localLanguageName
  const isDisable = submitButtonDisabled || isButtonDisabled

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  return (
    <LayoutContainer title="EDIT_LANGUAGE" breadCrumbsTitle="editLanguage">
      <div className="edit-language" data-testid="editLanguage-form">
        <div className="country-name" data-testid="country-name" onClick={allLanguageData.length === 0 ? languageList : null}>
          <label htmlFor="countryName">
            {t('COUNTRY_NAME')}
            <span className="field-required"> *</span>
          </label>
          <CountrySelector isFormComponent={true} countryId={state.countryId} selectCurrency={handleChangeCountry} />
        </div>
        <Form
          onSubmit={onSubmit}
          initialValues={editLanguageData}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="p-fluid">
              <Field
                name="languageName"
                render={({ input, meta }) => (
                  <div className="language-name">
                    <span className="p-float-label">
                      <InputText {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) || isLanguageNameExists ? 'p-invalid-hover' : 'field-label')} value={editLanguageData.languageName || ''} onChange={handleChange} placeholder={t('ENTER_LANGUAGE')} id="languageName" onFocus={allLanguageData.length === 0 ? languageList : null} data-testid="languageName" />
                      <label htmlFor="languageName" className={classNames({ 'p-error': isFormFieldValid(meta) || isLanguageNameExists })}>
                        {t('LANGUAGE_NAME')}
                        <span className="field-required"> *</span>
                      </label>
                    </span>
                    {isLanguageNameExists ? <small className="p-error">{t('DATA_ALREADY_EXISTS')}</small> : <div className="error-message">{getFormErrorMessage(meta)}</div>}{' '}
                  </div>
                )}
              />
              <Field
                name="localLanguageName"
                render={({ input, meta }) => (
                  <div className="local-language-name">
                    <span className="p-float-label">
                      <InputText {...input} id="localLanguageName" className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) || isLocalNameExists ? 'p-invalid-hover' : 'field-label')} value={editLanguageData.localLanguageName || ''} onChange={handleChange} placeholder={t('LOCAL_LANGUAGE_NAME')} onFocus={allLanguageData.length === 0 ? languageList : null} data-testid="localLanguageName" />
                      <label htmlFor="localLanguageName" className={classNames(`${isFormFieldValid(meta) || isLocalNameExists ? 'p-error' : 'field-label'}`)}>
                        {t('LOCAL_LANGUAGE_NAME')}
                        <span className="field-required"> *</span>
                      </label>
                    </span>
                    {isLocalNameExists ? <small className="p-error">{t('DATA_ALREADY_EXISTS')}</small> : <div className="error-message">{getFormErrorMessage(meta)}</div>}{' '}
                  </div>
                )}
              />

              <div className="button-container">
                <ButtonComponent type={'cancel'} onClick={() => history.push(ROUTE_STRINGS.listLanguage)} />
                <ButtonComponent type={'submit'} onClick={UpdateLanguageData} disabled={isDisable} />
              </div>
            </form>
          )}
        />
        <DialogModal {...dialogModalStates} onSuccess={() => history.push(ROUTE_STRINGS.listLanguage)}></DialogModal>
      </div>
    </LayoutContainer>
  )
}

export default Loader(EditLanguage)
