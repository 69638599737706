import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Form, Field } from 'react-final-form'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { classNames } from 'primereact/utils'
import CountryCallingCodeSelector from 'Components/UIComponents/CountryCallingCodeSelector'
import DialogModal from 'Components/UIComponents/DialogModal'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { commonMethods } from 'Utils/commonMethods'
import { getLocalUserDetails } from 'Utils/LocalStorageHandler'
import Validation from 'Utils/Validation'
import Loader from 'Components/Loader'
import { ButtonComponent } from 'Components/UIComponents'

const EditProfileInformation = ({ setLoading }) => {
  const { i18n, t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const userDetails = getLocalUserDetails()
  const {
    state: {
      rowData: { id, userType }
    }
  } = location
  const [userProfile, setUserProfile] = useState({
    occupationList: [],
    statesList: [],
    rolesList: [],
    selectedOccupation: null,
    selectedRole: null,
    selectedState: null
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true)
  const [emailError, setEmailError] = useState(false)

  const [userData, setUserData] = useState({
    userId: 0,
    firstName: '',
    lastName: '',
    countryId: 0,
    phone: 0,
    countryPhoneCode: '',
    email: '',
    occupationName: '',
    occupationId: 0,
    idState: 0,
    states: '',
    city: '',
    doB: '',
    userRole: 0,
    isAllowedToConnect: true,
    idtimezone: 0,
    headLine: null,
    showMobileNo: false,
    showEmailId: false
  })
  const [modalState, setModalState] = useState({
    modalType: '',
    message: '',
    softAction: '',
    hardAction: '',
    showModal: false,
    onHide: () => {},
    onSuccess: () => {},
    onDismiss: () => {}
  })

  const { modalType } = modalState
  const { selectedOccupation, selectedRole, selectedState, occupationList, statesList, rolesList } = userProfile
  const { userId, firstName, lastName, countryId, phone, countryPhoneCode, email, occupationName, occupationId, idState, states, city, doB, userRole, isAllowedToConnect, idtimezone, headLine, showMobileNo, showEmailId } = userData

  useEffect(() => {
    getUserByUserId()
  }, [])

  const getUserByUserId = async () => {
    setModalState({ ...modalState, showModal: false })
    setLoading(true)
    try {
      const { data } = await apiAdapterCoffeeWeb.getUserByUserId(id)
      const { firstName, lastName, countryId, phone, countryPhoneCode, email, occupationName, occupationId, idState, states, city, doB, userRole, idtimezone, headLine, showMobileNo, showEmailId } = data

      setUserData((previousState) => ({ ...previousState, id: userId, firstName, lastName, countryId, phone, countryPhoneCode, email, occupationName, occupationId, idState, states, city, doB, userRole, idtimezone, headLine, showMobileNo, showEmailId }))
      init(occupationName, userRole, states)
    } catch {
      setModalState({ ...modalState, modalType: 'error', message: 'NO_DATA_FOUND', showModal: true, onHide: handleDismiss, onDismiss: handleDismiss, onRetry: getUserByUserId })
    } finally {
      setLoading(false)
    }
  }

  const init = async (occupationName, userRole, states) => {
    setModalState({ ...modalState, showModal: false })
    setLoading(true)
    const fetchOccupationList = apiAdapterCoffeeWeb.occupationType()
    const fetchStatesList = apiAdapterCoffeeWeb.getStateList()
    const fetchRolesList = apiAdapterCoffeeWeb.getAllRoles()

    try {
      const [occupationListResponse, statesListResponse, userRolesResponse] = await Promise.all([fetchOccupationList, fetchStatesList, fetchRolesList])

      setUserProfile((previousState) => ({ ...previousState, occupationList: occupationListResponse.data.returnLst, statesList: statesListResponse.data, rolesList: userRolesResponse.data.returnLst }))

      const selectedOccupation = occupationListResponse.data.returnLst.find(({ occupationTypeName }) => occupationTypeName === occupationName)
      const selectedRole = userRolesResponse.data.returnLst.find(({ idRole }) => idRole === userRole)

      const selectedState = statesListResponse.data.find(({ stateName }) => stateName === states)

      setUserProfile((previousState) => ({ ...previousState, selectedOccupation, selectedRole, selectedState }))
    } catch {
      setModalState({ ...modalState, modalType: 'error', message: 'NO_DATA_FOUND', showModal: true, onHide: handleDismiss, onDismiss: handleDismiss, onRetry: init })
    } finally {
      setLoading(false)
    }
  }

  const handleKeyDownName = (event) => {
    const keyCode = event.keyCode || event.which
    const keyValue = String.fromCharCode(keyCode)

    if (keyCode === 8 || keyCode === 32) {
      return
    }

    if (!Validation.userNameValidation(keyValue)) {
      event.preventDefault()
    }
  }

  const onSubmit = (form) => {
    form.restart()
  }

  const onReceivingPhoneCode = (PhoneCode, countryCode) => {
    setUserData({ ...userData, countryPhoneCode: PhoneCode, countryId: countryCode })
  }

  const handleDetailsChange = (event) => {
    const { value, name } = event.target

    const isValueEmpty = value === '' || value === null

    let isValidEmail = true

    if (name === 'email') {
      isValidEmail = Validation.emailValidation(value) || isValueEmpty
      setEmailError(isValidEmail ? '' : true)
      if (isValidEmail) {
        setUserData({ ...userData, email: value })
      }
    }

    if (name === 'occupationName') {
      const { occupationTypeName: occupationName, idOccupationType: occupationId } = value

      setUserProfile((previousState) => ({ ...previousState, selectedOccupation: value }))
      setUserData({
        ...userData,
        occupationName,
        occupationId
      })
    } else if (name === 'userRoleName') {
      const { idRole: userRole } = value

      setUserProfile((previousState) => ({ ...previousState, selectedRole: value }))
      setUserData({
        ...userData,
        userRole
      })
    } else if (name === 'userStateName') {
      const { stateName: states } = value

      setUserProfile((previousState) => ({ ...previousState, selectedState: value }))
      setUserData({
        ...userData,
        idState,
        states
      })
    } else {
      setUserData((previousData) => ({
        ...previousData,
        [name]: value
      }))
    }
    setIsSubmitButtonDisabled(isValueEmpty || !isValidEmail)
  }

  const handleSaveAction = async (e) => {
    e.preventDefault()
    setLoading(true)

    const postData = {
      id,
      firstName,
      lastName,
      countryId,
      occupationId,
      occupationName,
      updatedBy: userDetails.updatedBy,
      city,
      states,
      doB,
      showMobileNo,
      showEmailId,
      idtimezone,
      idState,
      isAllowedToConnect,
      headLine,
      userRole,
      userEmail: email
    }

    try {
      let response

      if (userType) {
        response = await apiAdapterCoffeeWeb.updateOnlyUser(postData)
      } else {
        response = await apiAdapterCoffeeWeb.updateOnlyAdmin(postData)
      }
      const { status } = response

      if (status === 200) {
        setLoading(false)
        setModalState({
          ...modalState,
          modalType: 'success',
          message: 'UPDATE_USER_SUCCESS',
          softAction: 'OK',
          showModal: true,
          onHide: handleSuccess,
          onSuccess: handleSuccess
        })
      }
    } catch {
      setModalState({
        ...modalState,
        showModal: true,
        modalType: 'error',
        message: 'UPDATE_USER_FAILED',
        onHide: handleDismiss,
        onDismiss: handleDismiss,
        onRetry: handleSaveAction
      })
    } finally {
      setLoading(false)
    }
  }

  const checkPhoneNumber = async () => {
    const { phone, countryPhoneCode } = userData

    if (phone.length <= 9) {
      setErrorMessage(t('INVALID_PHONE_NUMBER'))
    } else {
      try {
        if (phone && phone.length >= 7) {
          const { data } = await apiAdapterCoffeeWeb.isPhoneExist({ phoneNo: phone, countryPhoneCode })

          if (data === true) {
            setErrorMessage(t('PHONE_NUMBER_EXIST'))
          } else {
            setErrorMessage(null)
          }
        } else {
          setErrorMessage(null)
        }
      } catch {
        setModalState({
          ...modalState,
          showModal: true,
          modalType: 'error',
          message: 'NO_DATA_FOUND',
          onHide: commonMethods.handleRetry
        })
      }
    }
  }

  const handleDismiss = () => {
    if (modalType === 'success') {
      setModalState({ ...modalState, showModal: false })
      history.push(ROUTE_STRINGS.listUsers)
    }
    setModalState({ ...modalState, showModal: false })
  }

  const handleSuccess = () => {
    setModalState({ ...modalState, showModal: false })
    history.push(`${ROUTE_STRINGS.listUsers}?tab=contributors`)
  }

  const handelCancelAction = () => {
    history.push({
      pathname: ROUTE_STRINGS.listUsers,
      search: '?tab=contributors'
    })
  }

  const validate = (data) => {
    const errors = {}

    if (!data.firstName) {
      errors.firstName = t('FIRST_NAME_IS_REQUIRED')
    }

    if (!data.lastName) {
      errors.lastName = t('LAST_NAME_IS_REQUIRED')
    }

    if (!data.email) {
      errors.email = t('EMAIL_IS_REQUIRED')
    }

    if (!data.occupationName) {
      errors.occupationName = t('OCCUPATION_IS_REQUIRED')
    }
    if (!data.userRole) {
      errors.occupationName = t('USER_ROLE_IS_REQUIRED')
    }

    if (!userData.states) {
      errors.userStateName = t('STATE_IS_REQUIRED')
    }

    return errors
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)

  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  return (
    <div className="profileInformation edit-profile-information">
      <div className="profileInformation-card">
        <Form
          onSubmit={onSubmit}
          initialValues={userData}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="firstName"
                render={({ input, meta }) => (
                  <div className="field firstName-label">
                    <span className="p-float-label">
                      <InputText disabled id="name" {...input} value={firstName} onChange={(e) => handleDetailsChange(e)} onKeyDown={handleKeyDownName} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid' : '')} />
                      <label htmlFor="name" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'add-user-label'}`)}>
                        {t('FIRST_NAME')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}
              />

              <Field
                name="lastName"
                render={({ input, meta }) => (
                  <div className="field last-name-field">
                    <span className="p-float-label">
                      <InputText disabled id="lastName" {...input} value={lastName} onChange={(e) => handleDetailsChange(e)} onKeyDown={handleKeyDownName} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid' : '')} />
                      <label htmlFor="lastName" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'add-user-label'}`)}>
                        {t('LAST_NAME')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}
              />
              <Field
                name="country"
                render={({ input }) => (
                  <div className="field country-phone-field">
                    <span className="p-float-label">
                      <CountryCallingCodeSelector isRadioChecked={true} id="country" {...input} inputName={'phone'} value={phone} onValueChange={handleDetailsChange} onSecondaryValueChange={handleDetailsChange} onPhoneInputBlur={checkPhoneNumber} onGetPhoneCode={onReceivingPhoneCode} secondaryInputName={'country'} secondaryValue={countryId} onResetCountryPhoneCode={countryPhoneCode} phoneExist={errorMessage} />
                    </span>
                  </div>
                )}
              />
              <Field
                name="email"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label p-input-icon-right">
                      <i className="pi pi-envelope" />
                      <InputText disabled={userType} id="email" {...input} value={email} onChange={(e) => handleDetailsChange(e)} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid' : '')} />
                      <label htmlFor="email" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'add-user-label'}`)}>
                        {t('EMAIL')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    {emailError && !meta.error ? (
                      <div className="error-message">
                        <span className="error-message">{t('PLEASE_ENTER_A_VALID_EMAIL')}</span>
                      </div>
                    ) : (
                      meta.error && meta.touched && <small className="p-error">{meta.error}</small>
                    )}
                  </div>
                )}
              />

              <Field
                name="occupationName"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className={`p-float-label ${isFormFieldValid(meta) ? 'p-invalid' : ''}`}>
                      <Dropdown {...input} disabled={userType} id="occupation" placeholder={t('SELECT_OCCUPATION')} onChange={(e) => handleDetailsChange(e)} value={selectedOccupation} className={`profile-information-fields ${isFormFieldValid(meta) ? 'p-invalid' : ''} ${isFormFieldValid(meta) ? 'p-invalid-hover' : ''}`} options={occupationList} optionLabel="occupationTypeName" />
                      <label htmlFor="occupation" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'add-user-label'}`)}>
                        {t('OCCUPATION')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}
              />
              <Field
                name="userRoleName"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className={`p-float-label ${isFormFieldValid(meta) ? 'p-invalid' : ''}`}>
                      <Dropdown {...input} disabled={userType} id="userRole" placeholder={t('SELECT_USER_ROLE')} onChange={(e) => handleDetailsChange(e)} value={selectedRole} className={`profile-information-fields ${isFormFieldValid(meta) ? 'p-invalid' : ''} ${isFormFieldValid(meta) ? 'p-invalid-hover' : ''}`} options={rolesList} optionLabel="roleName" />
                      <label htmlFor="userRole" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'add-user-label'}`)}>
                        {t('USER_ROLE')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}
              />

              <Field
                name="userStateName"
                render={({ input, meta }) => (
                  <div className="field userState-input-field">
                    <span className={`p-float-label ${isFormFieldValid(meta) ? 'p-invalid' : ''}`}>
                      <Dropdown id="userState" {...input} disabled={userType} className={classNames('profile-information-fields', { 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : '')} placeholder={t('SELECT_STATE_NAME')} value={selectedState} options={statesList} optionLabel="stateName" onChange={(e) => handleDetailsChange(e)} />
                      <label htmlFor="userState" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'add-user-label'}`)}>
                        {t('STATE_NAME')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}
              />

              {userType === 0 && (
                <div className="button-group">
                  <ButtonComponent type="cancel" onClick={handelCancelAction} />
                  <ButtonComponent type="submit" onClick={handleSaveAction} disabled={isSubmitButtonDisabled} />
                </div>
              )}
            </form>
          )}
        />

        <DialogModal {...modalState}></DialogModal>
      </div>
    </div>
  )
}

export default Loader(EditProfileInformation)
