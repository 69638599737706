import './styles.scss'
import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import { Toast } from 'primereact/toast'
import { Dropdown } from 'primereact/dropdown'
import Loader from 'Components/Loader'
import DialogModal from 'Components/UIComponents/DialogModal'
import LayoutContainer from 'Components/LayoutComponent'
import Validation from 'Utils/Validation'
import { ROUTE_STRINGS, SUBSCRIPTION_PARAMETERS } from 'Utils/Constants'
import { getLocalUserDetails } from 'Utils/LocalStorageHandler'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { ButtonComponent } from 'Components/UIComponents'

const AddSubscription = ({ setLoading }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const toast = useRef()
  const params = new URLSearchParams()
  const [selectedProcess, setSelectedProcess] = useState('')
  const [modalState, setModalState] = useState({
    modalType: '',
    message: '',
    softAction: '',
    hardAction: '',
    showModal: false,
    onHide: () => {},
    onSuccess: () => {},
    onDismiss: () => {},
    onRetry: () => {},
    onSubmit: () => {}
  })

  const renewProcessOption = [
    {
      processLabel: 'Renew'
    },
    {
      processLabel: 'Upgrade'
    }
  ]

  const [subscriptionValues, setSubscriptionValues] = useState({
    subscription: '',
    amount: '',
    basePrice: '',
    rupees: '',
    idCountry: '102',
    subscriptionCode: '',
    igst: '',
    appPurchase: '',
    amountInDollars: '',
    basePriceForIos: '',
    renewProcess: '',
    subscriptionColor: ''
  })

  const submitSubscriptionData = async () => {
    setLoading(true)
    const { id } = getLocalUserDetails()
    const { subscription, amount, rupees, idCountry, subscriptionCode, basePriceForIos, appPurchase, amountInDollars, renewProcess, subscriptionColor, basePrice } = subscriptionValues
    const { sgst, cgst, igst } = SUBSCRIPTION_PARAMETERS

    const postSubscription = {
      name: subscription,
      basePrice: parseInt(basePrice, 10),
      createdBy: parseInt(id, 10),
      updatedBy: id,
      amount: amount,
      isTrader: true,
      currencyType: rupees,
      idCountry,
      subscriptionCode,
      basePriceForIos,
      qrCodePaymentLink: 'NA',
      inAppPurchaseId: appPurchase,
      amountInDollars,
      shareNews: true,
      renewProcess,
      sgst,
      cgst,
      igst,
      subscriptionColor
    }

    try {
      const { data } = await apiAdapterCoffeeWeb.postSubscriptionType(postSubscription)

      if (data.statusCode === 201) {
        setModalState((previousState) => ({
          ...previousState,
          showModal: true,
          modalType: 'success',
          message: t('SUBSCRIPTION_TYPE_ADDED_SUCCESSFULLY', { subscriptionName: data.returnLst.name }),
          multipleMessage: { firstMessage: t('ADD_SUBSCRIPTION_DETAILS') },
          softAction: 'cancel',
          hardAction: 'Continue',
          onSuccess: handleSuccess,
          onHide: () => handleSuccess(data.returnLst),
          onSubmit: () => handleModalSubmit(data.returnLst)
        }))
      }
    } catch (error) {
      const { statusCode } = error.response.data

      if (statusCode === 404) {
        setLoading(false)
        toast.current.show({ severity: t('ERROR_TOAST'), summary: t('DATA_ALREADY_EXISTS'), detail: t('PLEASE_ENTER_ANOTHER_DATA') })
      } else {
        setModalState((previousState) => ({
          ...previousState,
          showModal: true,
          modalType: 'error',
          message: 'SORRY_COULD_NOT_ADD_DATA_PLEASE_TRY_AGAIN_LATER',
          onDismiss: handleDismiss,
          onRetry: postRetry,
          onHide: handleDismiss
        }))
      }
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (event) => {
    const { value, name } = event.target

    if (name === 'subscription' && (Validation.nameValidation(value) || value === '')) {
      setSubscriptionValues({ ...subscriptionValues, subscription: value })
    } else if (name === 'subscriptionCode' && (Validation.nameValidation(value) || value === '')) {
      setSubscriptionValues({ ...subscriptionValues, subscriptionCode: value })
    } else if (name === 'basePrice') {
      const basePriceValue = parseFloat(value)

      if (!Number.isNaN(basePriceValue) || value === '') {
        const basePrice = Number.isNaN(basePriceValue) ? 0 : basePriceValue
        const gstPercentage = 18

        const gstAmount = (basePrice * gstPercentage) / 100
        const totalAmount = basePrice + gstAmount

        setSubscriptionValues({
          ...subscriptionValues,
          basePrice: value,
          igst: gstPercentage.toFixed(2),
          amount: totalAmount.toFixed(2)
        })
      } else {
        setSubscriptionValues({
          ...subscriptionValues,
          basePrice: '',
          igst: '18.00',
          amount: ''
        })
      }
    } else if (name === 'amount' && (Validation.phoneNumberValidation(value) || value === '')) {
      setSubscriptionValues({ ...subscriptionValues, amount: value })
    } else if (name === 'basePriceForIos' && (Validation.phoneNumberValidation(value) || value === '')) {
      setSubscriptionValues({ ...subscriptionValues, basePriceForIos: value })
    } else if (name === 'appPurchase') {
      setSubscriptionValues({ ...subscriptionValues, appPurchase: value })
    } else if (name === 'amountInDollars' && (Validation.phoneNumberValidation(value) || value === '')) {
      setSubscriptionValues({ ...subscriptionValues, amountInDollars: value })
    } else if (name === 'renewProcess') {
      setSelectedProcess(value)
      setSubscriptionValues({ ...subscriptionValues, renewProcess: value.processLabel })
    } else if (name === 'subscriptionColor') {
      setSubscriptionValues({ ...subscriptionValues, subscriptionColor: value })
    }
  }

  const handleSuccess = () => {
    setModalState({ ...modalState, showModal: false })
    history.push(ROUTE_STRINGS.getSubscription)
  }

  const handleModalSubmit = (newSubscriptionData) => {
    setModalState({ ...modalState, showModal: false })
    params.set('tab', 'subscription_features')

    history.push({ pathname: `${ROUTE_STRINGS.subscriptionConfig}`, search: params.toString(), state: newSubscriptionData })
  }

  const handleDismiss = () => {
    setModalState((previousState) => ({
      ...previousState,
      showModal: false
    }))
  }

  const postRetry = () => {
    setModalState((previousState) => ({ ...previousState, showModal: false }))
    submitSubscriptionData()
  }

  const onSubmit = (data) => {
    setSubscriptionValues(data)
  }

  const handleSubmitSubscription = () => {
    submitSubscriptionData()
  }

  const { subscription, amount, subscriptionCode, basePrice, basePriceForIos, appPurchase, amountInDollars, renewProcess, subscriptionColor } = subscriptionValues

  const isButtonDisabled = !subscription || !amount || !basePrice || !subscriptionCode || !basePriceForIos || !appPurchase || !amountInDollars || !selectedProcess || !subscriptionColor

  const validateEmptyCheck = (data) => {
    const errors = {}

    const requiredFields = {
      subscription: t('SUBSCRIPTION_IS_REQUIRED'),
      amount: t('AMOUNT_IS_REQUIRED'),
      subscriptionCode: t('SUBSCRIPTION_CODE_IS_REQUIRED'),
      basePriceForIos: t('BASE_PRICE_FOR_IOS_IS_REQUIRED'),
      appPurchase: t('APP_PURCHASE_IS_REQUIRED'),
      amountInDollars: t('AMOUNT_IN_DOLLARS_IS_REQUIRED'),
      basePrice: t('BASE_PRICE_IS_REQUIRED'),
      renewProcess: t('RENEW_IS_REQUIRED'),
      subscriptionColor: t('COLOR_IS_REQUIRED')
    }

    Object.keys(requiredFields).forEach((field) => {
      if (!data[field]) {
        errors[field] = requiredFields[field]
      }
    })

    return errors
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  return (
    <LayoutContainer title={'ADD_SUBSCRIPTION'} breadCrumbsTitle={'addSubscription'}>
      <div className="add-subscription-container">
        <div className="add-subscription-details-form">
          <Form
            onSubmit={onSubmit}
            initialValues={subscriptionValues}
            validate={validateEmptyCheck}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="subscription"
                  render={({ input, meta }) => (
                    <div className="field">
                      <span className="p-float-label">
                        <InputText id="subscription-name" {...input} value={subscriptionValues.subscription} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
                        <label htmlFor="subscription-name" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'subscription-label'}`)}>
                          {t('SUBSCRIPTION')}
                          <span className="required-field">*</span>
                        </label>
                      </span>
                      <span>
                        <div className="error-message">{getFormErrorMessage(meta)}</div>
                      </span>
                    </div>
                  )}
                />

                <Field
                  name="subscriptionCode"
                  render={({ input, meta }) => (
                    <div className="field">
                      <div>
                        <span className="p-float-label">
                          <InputText id="subscription-code" {...input} value={subscriptionValues.subscriptionCode} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
                          <label htmlFor="subscription-code" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'subscription-label'}`)}>
                            {t('SUBSCRIPTION_CODE')}
                            <span className="required-field">*</span>
                          </label>
                        </span>
                        <div className="error-message">{getFormErrorMessage(meta)}</div>
                      </div>
                    </div>
                  )}
                />

                <Field
                  name="basePrice"
                  render={({ input, meta }) => (
                    <div className="field">
                      <div>
                        <span className="p-float-label">
                          <InputText id="base-price-field" {...input} value={subscriptionValues.basePrice} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
                          <label htmlFor="base-price-field" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'subscription-label'}`)}>
                            {t('BASE_PRICE_WITHOUT_GST')}
                            <span className="required-field">*</span>
                          </label>
                        </span>
                        <div className="error-message">{getFormErrorMessage(meta)}</div>
                      </div>
                    </div>
                  )}
                />

                <Field
                  name="amount"
                  render={({ input, meta }) => (
                    <div className="field">
                      <span className="p-float-label">
                        <InputText id="amount-field" {...input} value={subscriptionValues.amount} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} disabled />
                        <label htmlFor="amount-field" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'subscription-label'}`)}>
                          {t('AMOUNT_INCLUDING_GST')}
                          <span className="required-field">*</span>
                        </label>
                      </span>
                      <div className="error-message">{getFormErrorMessage(meta)}</div>
                    </div>
                  )}
                />

                <Field
                  name="basePriceForIos"
                  render={({ input, meta }) => (
                    <div className="field">
                      <div>
                        <span className="p-float-label">
                          <InputText id="base-price-field" {...input} value={subscriptionValues.basePriceForIos} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
                          <label htmlFor="base-price-field" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'subscription-label'}`)}>
                            {t('BASE_PRICE_FOR_IOS')}
                            <span className="required-field">*</span>
                          </label>
                        </span>
                        <div className="error-message">{getFormErrorMessage(meta)}</div>
                      </div>
                    </div>
                  )}
                />

                <Field
                  name="appPurchase"
                  render={({ input, meta }) => (
                    <div className="field">
                      <span className="p-float-label">
                        <InputText id="app-purchase-id" {...input} value={subscriptionValues.appPurchase} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
                        <label htmlFor="app-purchase-id" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'subscription-label'}`)}>
                          {t('IN_APP_PURCHASE_ID')}
                          <span className="required-field">*</span>
                        </label>
                      </span>
                      <div className="error-message">{getFormErrorMessage(meta)}</div>
                    </div>
                  )}
                />

                <Field
                  name="amountInDollars"
                  render={({ input, meta }) => (
                    <div className="field">
                      <div>
                        <span className="p-float-label">
                          <InputText id="amount-in-dollars-id" {...input} value={subscriptionValues.amountInDollars} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
                          <label htmlFor="amount-in-dollars-id" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'subscription-label'}`)}>
                            {t('AMOUNT_IN_DOLLARS')}
                            <span className="required-field">*</span>
                          </label>
                        </span>
                        <div className="error-message">{getFormErrorMessage(meta)}</div>
                      </div>
                    </div>
                  )}
                />

                <Field
                  name="renewProcess"
                  render={({ input, meta }) => (
                    <div className="field">
                      <div>
                        <span className="p-float-label">
                          <Dropdown id="renew-process" {...input} value={selectedProcess} options={renewProcessOption} optionLabel="processLabel" onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
                          <label htmlFor="renew-process" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'subscription-label'}`)}>
                            {t('RENEW_PROCESS')}
                            <span className="required-field">*</span>
                          </label>
                        </span>
                        <div className="error-message">{getFormErrorMessage(meta)}</div>
                      </div>
                    </div>
                  )}
                />

                <Field
                  name="subscriptionColor"
                  render={({ input, meta }) => (
                    <div className="field">
                      <div>
                        <span className="p-float-label">
                          <InputText id="subscription-color" {...input} value={subscriptionValues.subscriptionColor} onChange={handleChange} placeholder={t('#ffffff, #4f4f4f, #f26356')} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
                          <label htmlFor="subscription-color" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'subscription-label'}`)}>
                            {t('SUBSCRIPTION_COLOR')}
                            <span className="required-field">*</span>
                          </label>
                        </span>
                        <div className="error-message">{getFormErrorMessage(meta)}</div>
                      </div>
                    </div>
                  )}
                />

                <div className="button-container">
                  <ButtonComponent type={'submit'} onClick={handleSubmitSubscription} disabled={isButtonDisabled} />
                </div>
              </form>
            )}
          />
        </div>
      </div>

      <DialogModal {...modalState}></DialogModal>

      <Toast ref={toast} position="top-right" />
    </LayoutContainer>
  )
}

export default Loader(AddSubscription)
