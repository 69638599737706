import { getLocalUserDetails } from 'Utils/LocalStorageHandler'

export const getUserRoleBasedFeature = () => {
  const userDetails = getLocalUserDetails()

  const rolesPermissions = [
    {
      idRole: 1,
      roleName: 'super-admin',
      functionality: {
        newsFeed: { showAddButton: false, editNewsIconDisable: true, deleteNewsIconDisable: false, freeNewsToggleDisable: true, trendingNewsToggleDisable: true, languageDropdownDisable: false, filterDropdownDisable: false, isPreviewButtonDisabled: false, isRemarkButtonDisabled: false, isAdminPreviewButtonDisabled: false, approveRejectButtonDisabled: false },
        terminalDetails: { showAddButton: true, editTerminalDetailsButton: false, isActiveSwitchDisabled: false, filterDropDownDisabled: false, addTerminalDataButton: false }
      }
    },
    {
      idRole: 2,
      roleName: 'admin',
      functionality: {
        newsFeed: { showAddButton: true, editNewsIconDisable: false, deleteNewsIconDisable: false, freeNewsToggleDisable: false, trendingNewsToggleDisable: false, languageDropdownDisable: false, filterDropdownDisable: false, isPreviewButtonDisabled: false, isRemarkButtonDisabled: false, isAdminPreviewButtonDisabled: false, approveRejectButtonDisabled: false },
        terminalDetails: { showAddButton: true, editTerminalDetailsButton: false, isActiveSwitchDisabled: false, filterDropDownDisabled: false, addTerminalDataButton: false }
      }
    },
    {
      idRole: 5,
      roleName: 'news-creator',
      functionality: {
        newsFeed: { showAddButton: true, editNewsIconDisable: false, deleteNewsIconDisable: true, trendingNewsToggleDisable: false, languageDropdownDisable: false, filterDropdownDisable: false, isPreviewButtonDisabled: false, isRemarkButtonDisabled: false, isAdminPreviewButtonDisabled: false, approveRejectButtonDisabled: true }
      }
    },
    {
      idRole: 10154,
      roleName: 'news-approver',
      functionality: {
        newsFeed: { showAddButton: false, editNewsIconDisable: true, deleteNewsIconDisable: false, freeNewsToggleDisable: true, trendingNewsToggleDisable: true, languageDropdownDisable: false, filterDropdownDisable: false, isPreviewButtonDisabled: false, isRemarkButtonDisabled: false, isAdminPreviewButtonDisabled: false, approveRejectButtonDisabled: false }
      }
    },
    {
      idRole: 10173,
      roleName: 'news-publisher',
      functionality: {
        newsFeed: { showAddButton: false, editNewsIconDisable: true, deleteNewsIconDisable: false, freeNewsToggleDisable: true, trendingNewsToggleDisable: true, languageDropdownDisable: false, filterDropdownDisable: false, isPreviewButtonDisabled: false, isRemarkButtonDisabled: false, isAdminPreviewButtonDisabled: false, approveRejectButtonDisabled: false }
      }
    },
    {
      idRole: 10158,
      roleName: 'contract-creator',
      functionality: {
        terminalDetails: { showAddButton: true, editTerminalDetailsButton: false, isActiveSwitchDisabled: false, filterDropDownDisabled: false, addTerminalDataButton: true }
      }
    },
    {
      idRole: 10170,
      roleName: 'Terminal-Data-creator',
      functionality: {
        terminalDetails: { showAddButton: false, editTerminalDetailsButton: true, isActiveSwitchDisabled: true, filterDropDownDisabled: false, addTerminalDataButton: false }
      }
    }
  ]

  const foundItem = rolesPermissions.find((item) => item.idRole === userDetails.userRole)

  return foundItem
}
