import COFFEEWEB_STORAGE_KEYS from './LocalStorage'
import REG_EX from './RegEx'
import { ROUTE_STRINGS, TAB_MENUS } from './RouteStrings'
import COLOR_CONSTANTS from './Color'

const IMAGE_SOURCE_URLS = {
  checkImage: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/coffeeweb_menu_icons/img_check.png',
  cancelImage: 'https://coffeeweb.s3.ap-south-1.amazonaws.com/coffeeweb_menu_icons/img_cancel.png'
}

const KEYBOARD_KEYS = {
  backSpaceKey: 8,
  deleteKeyCode: 46
}

const SUBSCRIPTION_PARAMETERS = {
  cgst: 9,
  sgst: 9,
  igst: 18
}

const TABLE_CONST = {
  rowsCount: 10,
  maxWidthTab: 992,
  paginationButtonCount: 5,
  mobilePaginationButtonCount: 1,
  mobileRowCount: 8,
  paymentRowCount: 7
}

export { COFFEEWEB_STORAGE_KEYS, REG_EX, ROUTE_STRINGS, IMAGE_SOURCE_URLS, COLOR_CONSTANTS, KEYBOARD_KEYS, SUBSCRIPTION_PARAMETERS, TABLE_CONST, TAB_MENUS }
