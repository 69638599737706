import './styles.scss'
import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Form, Field } from 'react-final-form'
import { InputSwitch } from 'primereact/inputswitch'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import { Toast } from 'primereact/toast'
import Loader from 'Components/Loader'
import DialogModal from 'Components/UIComponents/DialogModal'
import LayoutContainer from 'Components/LayoutComponent'
import { ROUTE_STRINGS } from 'Utils/Constants'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import ButtonComponent from 'Components/UIComponents/ButtonComponent'

const AddRoles = ({ setLoading }) => {
  let modalCancel
  const { t } = useTranslation()
  const toast = useRef(null)
  const history = useHistory()
  const [selectedOptions, setSelectedOptions] = useState(false)
  const [showDialog, setShowDialog] = useState({ showModal: false, modalCancel: false, modalType: '', message: '', errorMessagePostApiFails: false, onDismiss: () => {}, onRetry: () => {} })
  const [roleUser, setRoleUser] = useState({
    roleName: '',
    roleCode: ''
  })

  const userRoleMaster = async () => {
    setShowDialog({
      ...showDialog,
      showModal: false
    })
    const { roleName, roleCode } = roleUser

    const screenRoleData = {
      roleName,
      roleCode,
      isBackendRole: selectedOptions
    }

    setLoading(true)

    try {
      const roleCreationResult = await apiAdapterCoffeeWeb.postRoleMasters(screenRoleData)

      if (roleCreationResult.data.statusCode === 201) {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: 'ROLE_ADDED_SUCCESSFUL',
          modalCancel: false,
          errorMessagePostApiFails: true
        })
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === 404) {
        toast.current.show({ severity: 'error', summary: t('DATA_ALREADY_EXIST'), detail: t('PLEASE_ENTER_ANOTHER_ROLE') })
      } else {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'error',
          message: 'SORRY_COULD_NOT_ADD_DATA_PLEASE_TRY_AGAIN_LATER',
          modalCancel: false,
          errorMessagePostApiFails: false,
          onDismiss: handleDismiss,
          onRetry: handleSaveButton
        })
      }
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (event) => {
    const { value, name } = event.target

    setRoleUser((previousData) => ({
      ...previousData,
      [name]: value
    }))
  }

  const handleInputChange = () => {
    setSelectedOptions(!selectedOptions)
  }

  const handleSuccess = () => {
    history.push(ROUTE_STRINGS.listRoles)
  }

  const handleDismiss = () => {
    setShowDialog(false)
  }

  const handleSaveButton = () => {
    userRoleMaster()
  }

  const handleKeyDown = (event) => {
    const keyCode = event.keyCode || event.which

    if (!(keyCode === 32 || (keyCode >= 65 && keyCode <= 90)) && keyCode !== 8 && keyCode !== 46) {
      event.preventDefault()
    }
  }

  const onSubmit = (data) => {
    setRoleUser(data)
  }

  const { roleName, roleCode } = roleUser

  const isSubmitButtonDisabled = !roleName || !roleCode

  const validate = (data) => {
    const { roleName, roleCode } = data
    const errors = {}

    if (!roleName) {
      errors.roleName = t('ROLE_NAME_IS_REQUIRED')
    }

    if (!roleCode) {
      errors.roleCode = t('ROLE_CODE_IS_REQUIRED')
    }

    return errors
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  if (showDialog.errorMessagePostApiFails) {
    modalCancel = () => history.push(ROUTE_STRINGS.listRoles)
  } else {
    modalCancel = () => handleDismiss()
  }

  return (
    <LayoutContainer title={'ADD_ROLES'} breadCrumbsTitle={'addRoles'}>
      <div className="add-roles-form">
        <Form
          onSubmit={onSubmit}
          initialValues={roleUser}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="roleName"
                render={({ input, meta }) => (
                  <div className="add-roles-field">
                    <span className="p-float-label label">
                      <InputText id="name" data-testid="role-name" autoComplete="off" name="roleName" {...input} value={roleUser.roleName} onChange={handleChange} onKeyDown={handleKeyDown} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                      <label htmlFor="name" className={classNames({ 'error-message-roles': isFormFieldValid(meta) })}>
                        {t('ROLE_NAME')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}
              />
              <Field
                name="roleCode"
                render={({ input, meta }) => (
                  <div className="add-roles-field">
                    <span className="p-float-label">
                      <InputText autoComplete="off" name="roleCode" id="code" data-testid="role-code" {...input} value={roleUser.roleCode} onChange={handleChange} onKeyDown={handleKeyDown} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                      <label htmlFor="code" className={classNames({ 'error-message-roles': isFormFieldValid(meta) })}>
                        {t('ROLE_CODE')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}
              />
              <div className="role-toggle-switches">
                <div>
                  <label>{t('ADMIN_ROLE')}</label>
                </div>
                <InputSwitch checked={selectedOptions} onChange={handleInputChange} />
              </div>
              <div className="button-container">
                <ButtonComponent type={'submit'} onClick={handleSaveButton} disabled={isSubmitButtonDisabled} />
              </div>
            </form>
          )}
        />
      </div>

      <DialogModal {...showDialog} onSuccess={handleSuccess} onHide={modalCancel}></DialogModal>
      <Toast ref={toast} className="add-role-toast" />
    </LayoutContainer>
  )
}

export default Loader(AddRoles)
