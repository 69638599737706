import './styles.scss'
import React, { Suspense, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import ListAppSetting from 'Screens/AppSetting'
import { AddUser, EditUser, ListUsers } from 'Screens/Users'
import { AddMobile, ListMobile } from 'Screens/MobileAppUpdate'
import { UploadPriceProcess, CoffeePriceUpload } from 'Screens/UploadPriceProcess'
import { ListPaymentCountry, AddPaymentCountry } from 'Screens/PaymentCountryMapping'
import { ListScreenLanguage, ListMapping, EditMapping, AddMapping } from 'Screens/ScreenControlMapping'
import { EditNewsFeed, AddNewsFeed, ListNewsFeed, NewsReadMore } from 'Screens/NewsFeed'
import { ApproveGlobalData, GlobalRawDataPreview, GlobalDifferentialPreview, GlobalPrice, FreightRateEntry, DifferentialsEntry, CoffeePriceEntry } from 'Screens/ExcelUpload'
import { AddScreen, EditScreen, ListScreen, AddLanguage, EditLanguage, ListLanguage, AddPayment, EditPayment, AddSubscription, EditSubscription, ListPayment, Subscription, EditMenuGroup, RoleFeature, ListRoles, AddRoles, AddMenuGroup, ListMenuGroup, SubscriptionConfig } from 'Screens/MasterScreen'
import Loader from 'Components/Loader'
import Dashboard from 'Screens/Dashboard'
import { ROUTE_STRINGS } from 'Utils/Constants'
import Header from 'Components/Routes/Components/Header'
import AddTerminalDetails from 'Screens/TerminalMaster/Pages/AddTerminalDetails'
import EditTerminalDetails from 'Screens/TerminalMaster/Pages/EditTerminalDetails'
import ListTerminalDetails from 'Screens/TerminalMaster/Pages/ListTerminalDetails'
import SubscriptionFeature from 'Screens/MasterScreen/Pages/Subscription/SubscriptionConfig/Pages/SubscriptionFeature'
import PaymentReports from 'Screens/PaymentReports'
import MapMenu from 'Screens/MasterScreen/Pages/Subscription/SubscriptionConfig/Pages/MapMenu'
import { AddSubscriptionPaymentMapping, EditSubscriptionPaymentMapping, ListSubscriptionPaymentMapping } from 'Screens/MasterScreen/Pages/Subscription/SubscriptionConfig/Pages/SubscriptionPaymentMapping'
import Profile from 'Screens/AuthScreens/Components/Profile'
import UserStats from 'Screens/UserStats'

import CoffeeQuotes from 'Screens/CoffeeQuotes'
import ReconciledReports from 'Screens/PaymentReports/ReconciledReport'
import SideBar from './Components/SideBar'

const Routes = () => {
  const [toggleDrawer, setToggleDrawer] = useState(false)

  return (
    <>
      <Suspense fallback={Loader}>
        <div className="dashboard-main-div">
          <SideBar drawerState={toggleDrawer} handleToggleDrawer={() => setToggleDrawer(!toggleDrawer)} />
          <div className="dashboard_path">
            <Header handleToggleDrawer={() => setToggleDrawer(!toggleDrawer)} />
            <Switch>
              <Route exact path={ROUTE_STRINGS.coffeequotes} component={Dashboard} />
              <Route exact path={ROUTE_STRINGS.profile} component={Profile} />
              <Route exact path={ROUTE_STRINGS.coffeeentries} component={CoffeePriceEntry} />
              <Route exact path={ROUTE_STRINGS.differentialsentry} component={DifferentialsEntry} />
              <Route exact path={ROUTE_STRINGS.freightrateentry} component={FreightRateEntry} />
              <Route exact path={ROUTE_STRINGS.listNewsFeed} component={ListNewsFeed} />
              <Route exact path={ROUTE_STRINGS.addnewsfeed} component={AddNewsFeed} />
              <Route exact path={`${ROUTE_STRINGS.editNewsFeed}/:id`} component={EditNewsFeed} />
              <Route exact path={`${ROUTE_STRINGS.newsreadmore}/:id`} component={NewsReadMore} />
              <Route exact path={ROUTE_STRINGS.listUsers} component={ListUsers} />
              <Route exact path={`${ROUTE_STRINGS.editUser}/:id`} component={EditUser} />
              <Route exact path={ROUTE_STRINGS.userStats} component={UserStats} />
              <Route exact path={ROUTE_STRINGS.listmenu} component={ListMenuGroup} />
              <Route exact path={ROUTE_STRINGS.addmenu} component={AddMenuGroup} />
              <Route exact path={ROUTE_STRINGS.addRoles} component={AddRoles} />
              <Route exact path={ROUTE_STRINGS.listRoles} component={ListRoles} />
              <Route exact path={ROUTE_STRINGS.roleFeature} component={RoleFeature} />
              <Route exact path={`${ROUTE_STRINGS.editmenugroup}/:id`} component={EditMenuGroup} />
              <Route exact path={ROUTE_STRINGS.globalprice} component={GlobalPrice} />
              <Route exact path={ROUTE_STRINGS.getSubscription} component={Subscription} />
              <Route exact path={ROUTE_STRINGS.subscriptionConfig} component={SubscriptionConfig} />
              <Route exact path={ROUTE_STRINGS.addSubscription} component={AddSubscription} />
              <Route exact path={`${ROUTE_STRINGS.editSubscription}/:id`} component={EditSubscription} />
              <Route exact path={ROUTE_STRINGS.subscriptionfeatures} component={SubscriptionFeature} />
              <Route exact path={ROUTE_STRINGS.listPayment} component={ListPayment} />
              <Route exact path={ROUTE_STRINGS.addUser} component={AddUser} />
              <Route exact path={ROUTE_STRINGS.mapmenu} component={MapMenu} />
              <Route exact path={ROUTE_STRINGS.addPayment} component={AddPayment} />
              <Route exact path={`${ROUTE_STRINGS.editPayment}/:id`} component={EditPayment} />
              <Route exact path={ROUTE_STRINGS.addmobile} component={AddMobile} />
              <Route exact path={ROUTE_STRINGS.listMobile} component={ListMobile} />
              <Route exact path={ROUTE_STRINGS.addLanguage} component={AddLanguage} />
              <Route exact path={`${ROUTE_STRINGS.editLanguage}/:id`} component={EditLanguage} />
              <Route exact path={ROUTE_STRINGS.listLanguage} component={ListLanguage} />
              <Route exact path={ROUTE_STRINGS.listappsetting} component={ListAppSetting} />
              <Route exact path={ROUTE_STRINGS.addScreen} component={AddScreen} />
              <Route exact path={ROUTE_STRINGS.listScreen} component={ListScreen} />
              <Route exact path={`${ROUTE_STRINGS.editScreen}/:id`} component={EditScreen} />
              <Route exact path={ROUTE_STRINGS.addMapping} component={AddMapping} />
              <Route exact path={`${ROUTE_STRINGS.editMapping}/:id`} component={EditMapping} />
              <Route exact path={ROUTE_STRINGS.listMapping} component={ListMapping} />
              <Route exact path={ROUTE_STRINGS.listscreenlanguage} component={ListScreenLanguage} />
              <Route exact path={ROUTE_STRINGS.companyapproved} component={UploadPriceProcess} />
              <Route exact path={ROUTE_STRINGS.coffeepriceupload} component={CoffeePriceUpload} />
              <Route exact path={ROUTE_STRINGS.approveGlobalData} component={ApproveGlobalData} />
              <Route exact path={ROUTE_STRINGS.globaldifferentialpreview} component={GlobalDifferentialPreview} />
              <Route exact path={ROUTE_STRINGS.globalrawdatapreview} component={GlobalRawDataPreview} />
              <Route exact path={ROUTE_STRINGS.addPaymentCountry} component={AddPaymentCountry} />
              <Route exact path={ROUTE_STRINGS.listPaymentCountry} component={ListPaymentCountry} />
              <Route exact path={ROUTE_STRINGS.addTerminalDetails} component={AddTerminalDetails} />
              <Route exact path={`${ROUTE_STRINGS.editTerminalDetails}/:id`} component={EditTerminalDetails} />
              <Route exact path={ROUTE_STRINGS.listTerminalDetails} component={ListTerminalDetails} />
              <Route exact path={ROUTE_STRINGS.listSubscriptionPaymentMapping} component={ListSubscriptionPaymentMapping} />
              <Route exact path={ROUTE_STRINGS.addSubscriptionPaymentMapping} component={AddSubscriptionPaymentMapping} />
              <Route exact path={`${ROUTE_STRINGS.editSubscriptionPaymentMapping}/:id`} component={EditSubscriptionPaymentMapping} />
              <Route exact path={ROUTE_STRINGS.paymentReports} component={PaymentReports} />
              <Route exact path={ROUTE_STRINGS.coffeeQuotesIceMarket} component={CoffeeQuotes} />
              <Route exact path={ROUTE_STRINGS.reconciledReports} component={ReconciledReports} />
            </Switch>
          </div>
        </div>
      </Suspense>
    </>
  )
}

export default Routes
