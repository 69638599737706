import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DataTable } from 'primereact/datatable'
import { Button } from 'primereact/button'
import { InputSwitch } from 'primereact/inputswitch'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import Loader from 'Components/Loader'
import LayoutContainer from 'Components/LayoutComponent'
import { commonMethods } from 'Utils/commonMethods'
import { ROUTE_STRINGS, TABLE_CONST } from 'Utils/Constants'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { DialogModal } from 'Components/UIComponents'
import { act } from '@testing-library/react'

const ListLanguage = ({ setLoading, listLanguageMockData }) => {
  const history = useHistory()
  const { t } = useTranslation()
  let paginationButtons = TABLE_CONST.paginationButtonCount
  const columns = [
    { dataField: 'nicename', text: t('COUNTRY_NAME'), className: 'column-headers' },
    { dataField: 'languageName', text: t('LANGUAGE_NAME'), className: 'column-headers' },
    { dataField: 'localLanguageName', text: t('LOCAL_LANGUAGE_NAME'), className: 'local-column' }
  ]
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [languageData, setLanguageData] = useState([])
  const [currentLanguageData, setCurrentLanguageData] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [showTableMessage, setShowTableMessage] = useState(false)
  const [modalState, setModalState] = useState({
    showModal: false,
    modalType: '',
    message: '',
    softAction: '',
    hardAction: '',
    onRetry: () => {},
    onConfirmation: () => {}
  })

  useEffect(() => {
    languageList()

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  // useEffect to initialize state for testing purposes
  useEffect(() => {
    if (listLanguageMockData) {
      setLanguageData(listLanguageMockData)
    }
  }, [])

  if (windowSize < TABLE_CONST.maxWidthTab) {
    paginationButtons = TABLE_CONST.mobilePaginationButtonCount
  }

  const languageList = async () => {
    setLoading(true)
    setShowTableMessage(true)
    try {
      const response = await apiAdapterCoffeeWeb.getAllLanguageList()
      const {
        data: { returnLst: languageDataResponse }
      } = response

      setLanguageData(languageDataResponse)
      setCurrentLanguageData(languageDataResponse)
    } catch {
      act(() => {
        setModalState({
          ...modalState,
          showModal: true,
          modalType: 'error',
          message: 'ERROR_FETCHING_LIST_LANGUAGE_PLEASE_TRY_AGAIN_LATER'
        })
      })
    } finally {
      act(() => {
        setLoading(false)
        setShowTableMessage(false)
      })
    }
  }

  const handleNewsLanguageToggle = (option) => {
    if (option.forNews) {
      setModalState({ ...modalState, showModal: true, modalType: 'info', message: 'ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE_NEWS', softAction: 'CANCEL', hardAction: 'DEACTIVATE', onConfirmation: () => toggleNewsLanguage(option) })
    } else {
      toggleNewsLanguage(option)
    }
  }

  const toggleNewsLanguage = async (index) => {
    const isActive = !index.forNews

    setLoading(true)
    setModalState({ ...modalState, showModal: false })
    try {
      await apiAdapterCoffeeWeb.activateLanguageForNews(index.idLanguage, isActive)
      updateStateOnToggle(languageData, setLanguageData, index, 'forNews')
      updateStateOnToggle(currentLanguageData, setCurrentLanguageData, index, 'forNews')
    } catch {
      act(() => {
        setModalState({
          ...modalState,
          showModal: true,
          modalType: 'error',
          message: 'FAILED_TO_ACTIVATE_INACTIVATE_LANGUAGE'
        })
      })
    } finally {
      act(() => {
        setLoading(false)
      })
    }
  }

  const handleLanguageStatusToggle = (option) => {
    if (option.isActive) {
      setModalState({ ...modalState, showModal: true, modalType: 'info', message: 'ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE_LANGUAGE', softAction: 'CANCEL', hardAction: 'DEACTIVATE', onConfirmation: () => toggleLanguageStatus(option) })
    } else {
      toggleLanguageStatus(option)
    }
  }

  const toggleLanguageStatus = async (item) => {
    setModalState({ ...modalState, showModal: false })
    setLoading(true)
    try {
      if (!item.isActive) {
        await apiAdapterCoffeeWeb.activateLanguage(item.idLanguage)
      } else {
        await apiAdapterCoffeeWeb.deactivateLanguage(item.idLanguage)
      }

      updateStateOnToggle(languageData, setLanguageData, item, 'isActive')
      updateStateOnToggle(currentLanguageData, setCurrentLanguageData, item, 'isActive')
    } catch {
      act(() => {
        setModalState({
          ...modalState,
          showModal: true,
          modalType: 'error',
          message: 'FAILED_TO_ACTIVATE_INACTIVATE_LANGUAGE'
        })
      })
    } finally {
      act(() => {
        setLoading(false)
      })
    }
  }

  const updateStateOnToggle = (state, stateUpdater, item, key) => {
    const modifiedData = state.map((el) => (el.idLanguage === item.idLanguage ? { ...el, [key]: !item[key] } : el))

    stateUpdater(modifiedData)
  }

  const searchInData = (e) => {
    const searchTerm = e.target.value.toLowerCase()

    setSearchQuery(searchTerm)
    const filtered = currentLanguageData.filter((item) => {
      const { nicename, languageName } = item

      if (nicename.toLowerCase().includes(searchTerm) || languageName.toLowerCase().includes(searchTerm)) return item
    })

    setLanguageData(filtered)
  }

  const handleAddButtonClick = () => {
    history.push(ROUTE_STRINGS.addLanguage)
  }

  const handleEditLanguage = (eachRowData) => {
    const { idLanguage } = eachRowData

    history.push({
      pathname: `${ROUTE_STRINGS.editLanguage}/${idLanguage}`,
      state: eachRowData
    })
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const handleDismiss = () => {
    setModalState({ ...modalState, showModal: false })
  }

  const newsLanguageToggleSwitch = (option) => (
    <div className="toggle-switch">
      <InputSwitch checked={option.forNews} onChange={() => handleNewsLanguageToggle(option)} />
    </div>
  )

  const languageStatusToggleSwitch = (option) => (
    <div className="toggle-switch">
      <InputSwitch checked={option.isActive} onChange={() => handleLanguageStatusToggle(option)} />
    </div>
  )

  const editScreenRedirect = (option) => <Button type="button" icon="pi pi-pencil" tooltip={t('EDIT')} tooltipOptions={{ position: 'top' }} className="edit-button" onClick={() => handleEditLanguage(option)} data-testid="edit-button" />

  const tableResponseMessage = () => <div>{showTableMessage ? t('FETCHING') : t('NO_RESULTS_FOUND')}</div>

  return (
    <div className="list-language">
      <LayoutContainer showAddButton={true} title={'LANGUAGE_LIST'} breadCrumbsTitle={'listLanguage'} handleAddButtonClick={handleAddButtonClick}>
        <div className="search-wrapper">
          <div className="search-field p-input-icon-left">
            <i className="pi pi-search" data-testid="search-icon" />
            <InputText value={searchQuery} onChange={(e) => searchInData(e)} placeholder={t('SEARCH')} />
          </div>
        </div>
        <div className="list-language-data-table">
          <DataTable value={languageData} paginator={languageData?.length > 0} rows={9} pageLinkSize={paginationButtons} emptyMessage={tableResponseMessage}>
            {columns.map((column, index) => (
              <Column key={index} field={column.dataField} header={column.text} className={column.className} />
            ))}
            <Column field="isActive" header={t('IS_ENABLED')} body={languageStatusToggleSwitch} className="toggle-column" />
            <Column field="isActive" header={t('FOR_NEWS')} body={newsLanguageToggleSwitch} className="toggle-column" />
            <Column body={editScreenRedirect} header={t('ACTIONS')} className="action-column" />
          </DataTable>
        </div>

        <DialogModal {...modalState} onDismiss={handleDismiss} onHide={handleDismiss} onRetry={commonMethods.handleRetry} />
      </LayoutContainer>
    </div>
  )
}

export default Loader(ListLanguage)
