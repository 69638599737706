import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { DataTable } from 'primereact/datatable'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import Loader from 'Components/Loader'

const ClientUser = ({ setLoading, rowCount, paginationButtons, columns, actionTemplate, toggleTemplate, openErrorModal, clientData, setClientData, clientFilterData, setClientFilterData }) => {
  const { i18n, t } = useTranslation()

  const [searchQuery, setSearchQuery] = useState('')
  const [showTableMessage, setShowTableMessage] = useState(false)

  useEffect(() => {
    getUsersClientData()
  }, [])

  const getUsersClientData = async () => {
    openErrorModal(false)
    setLoading(true)
    setShowTableMessage(true)
    try {
      const { data } = await apiAdapterCoffeeWeb.getAllClientUsers()

      setClientData(data)
      setClientFilterData(data)
    } catch {
      openErrorModal(true, getUsersClientData)
    } finally {
      setLoading(false)
      setShowTableMessage(false)
    }
  }

  const searchInClientData = (e) => {
    const searchTerm = e.target.value.toLowerCase()

    setSearchQuery(searchTerm)
    const filteredData = clientFilterData.filter((item) => {
      const { fullName, subscriptionTypeName, occupationName, userRoleName, phone, email, states, countryName } = item

      if (fullName.toLowerCase().includes(searchTerm) || subscriptionTypeName.toLowerCase().includes(searchTerm) || occupationName.toLowerCase().includes(searchTerm) || userRoleName.toLowerCase().includes(searchTerm) || phone.toLowerCase().includes(searchTerm) || email.toLowerCase().includes(searchTerm) || states.toLowerCase().includes(searchTerm) || countryName.toLowerCase().includes(searchTerm)) return item
    })

    setClientData(filteredData)
  }

  const tableResponseMessage = () => <div>{showTableMessage ? t('FETCHING') : t('NO_RESULTS_FOUND')}</div>

  return (
    <div>
      <div className="search-wrapper">
        <div className="search-field p-input-icon-left">
          <i className="pi pi-search" />
          <InputText value={searchQuery} onChange={(e) => searchInClientData(e)} placeholder={t('SEARCH')} />
        </div>
      </div>
      <DataTable value={clientData} removableSort paginator={clientData.length > 0} rows={rowCount} pageLinkSize={paginationButtons} emptyMessage={tableResponseMessage} className="user-table">
        {columns.map((col, index) => (
          <Column key={index} field={col.dataField} header={col.text} sortable={col.sortable} className={col.className} />
        ))}
        <Column field="isActive" header={t('IS_ACTIVE')} body={toggleTemplate} className="toggle-switch-column" />
        <Column body={actionTemplate} header={t('ACTIONS')} className="action-column" />
      </DataTable>
    </div>
  )
}

export default Loader(ClientUser)
