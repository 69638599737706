import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button'
import { useLocation } from 'react-router-dom'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import Loader from 'Components/Loader'
import { REG_EX } from 'Utils/Constants'
import { commonMethods } from 'Utils/commonMethods'
import DialogModal from 'Components/UIComponents/DialogModal'
import { ButtonComponent } from 'Components/UIComponents'

const WorkBookData = ({ setLoading, setChangesMade, index }) => {
  const { t } = useTranslation()
  const location = useLocation()

  const [editSettings, setEditSettings] = useState({
    editMode: false,
    submitButton: false,
    editIcon: true,
    workbookEditIcon: true,
    toggle: false,
    editText: false
  })
  const [newWorkBook, setNewWorkBook] = useState('')
  const [isEditActive, setIsEditActive] = useState(false)
  const [error, setError] = useState({
    newWorkBook: false
  })
  const [addButtonDisable, setAddButtonDisable] = useState(true)
  const [submitButtonDisable, setSubmitButtonDisable] = useState(true)
  const [workBookData, setWorkBookData] = useState('')
  const [isNumberExist, setIsNumberExist] = useState(false)
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onSuccess: () => {} })
  const [workbookAvailableNumbers, setWorkbookAvailableNumbers] = useState([])
  const [workbookAvailableEmails, setWorkbookAvailableEmails] = useState([])

  useEffect(() => {
    getAllAppSetting(location.state)
  }, [])

  useEffect(() => {
    const debounceInputSearch = setTimeout(() => {
      validateInput(newWorkBook)
    }, 300)

    return () => clearTimeout(debounceInputSearch)
  }, [newWorkBook])

  const getAllAppSetting = async () => {
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.appSetting()
      const data = response.data

      const workbookAvailableData = data.workbookAvailable.split(',')

      setWorkBookData(workbookAvailableData)

      const onlyNumber = workbookAvailableData.filter((ele) => REG_EX.CHECK_ONLY_NUMBER.test(ele))

      setWorkbookAvailableNumbers(onlyNumber)

      const onlyEmail = workbookAvailableData.filter((ele) => REG_EX.EMAIL_REG_EX.test(ele))

      setWorkbookAvailableEmails(onlyEmail)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('NO_DATA_FOUND'),
        onRetry: commonMethods.handleRetry,
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const workBookUpdate = async () => {
    try {
      setLoading(true)
      const response = await apiAdapterCoffeeWeb.updateWorkbookData(workBookData)

      if (response.data.statusCode === 200) {
        setChangesMade(null)
        setEditSettings({
          editMode: false,
          submitButton: false,
          editIcon: true
        })
        setIsEditActive(false)

        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: t('WORK_BOOK_DATA_UPDATED'),
          onHide: handleDismiss,
          onSuccess: handleDismiss
        })
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('WORK_BOOK_DATA_UPDATE_FAIL'),
        onRetry: modalRetry,
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const checkNumberRegistrationStatus = async (phone, countryPhoneCode) => {
    try {
      const { data } = await apiAdapterCoffeeWeb.isPhoneExist({ phoneNo: phone, countryPhoneCode })

      setIsNumberExist(data)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('NO_DATA_FOUND'),
        onRetry: () => validateInput(phone),
        onHide: handleDismiss
      })
    }
  }
  const checkEmailRegistrationStatus = async (email) => {
    try {
      const { data } = await apiAdapterCoffeeWeb.isEmailExist({ email })

      setIsNumberExist(data)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('NO_DATA_FOUND'),
        onRetry: () => validateInput(email),
        onHide: handleDismiss
      })
    }
  }

  const validateInput = (newWorkBookData) => {
    if (REG_EX.CHECK_ONLY_NUMBER.test(newWorkBookData) && newWorkBook.length > 6) {
      checkNumberRegistrationStatus(newWorkBookData, 91)
    } else {
      REG_EX.EMAIL_REG_EX.test(newWorkBookData) && checkEmailRegistrationStatus(newWorkBookData)
    }
  }

  const modalRetry = () => {
    setShowDialog({
      ...showDialog,
      showModal: false
    })
    setTimeout(() => {
      workBookUpdate()
    }, 300)
  }

  const handleEditButtonClick = () => {
    setIsEditActive((prevIsEditActive) => !prevIsEditActive)
    setEditSettings((prevEditSettings) => ({
      ...prevEditSettings,
      editMode: !prevEditSettings.editMode,
      editIcon: false,
      submitButton: true
    }))

    setSubmitButtonDisable(true)
    setNewWorkBook('')
  }

  const handleReset = () => {
    setError(false)
    setEditSettings({
      editMode: false,
      submitButton: false,
      editIcon: true
    })
    setIsEditActive(false)
    setNewWorkBook('')
    setChangesMade(false)
    setAddButtonDisable(true)
    getAllAppSetting()
  }

  const hasErrors = Object.values(error).some((value) => value)
  const handleInput = (event) => {
    const { name, value } = event.target

    if (name === 'email') {
      if (value === '') {
        setNewWorkBook(value)
        setError((previousState) => ({ ...previousState, newWorkBook: true, errorMessage: t('EMAIL_OR_PHONE_NUMBER_IS_REQUIRED') }))
      } else if (!REG_EX.CHECK_ONLY_NUMBER.test(value)) {
        if (!REG_EX.EMAIL_REG_EX.test(value)) {
          setNewWorkBook(value)
          setError((previousState) => ({ ...previousState, newWorkBook: true, errorMessage: t('PLEASE_ENTER_VALID_EMAIL') }))
        } else {
          setNewWorkBook(value)
          setAddButtonDisable(false)
          setError((previousState) => ({ ...previousState, newWorkBook: false, errorMessage: '' }))
        }
      } else {
        if (value.length > 0) {
          setError((previousState) => ({ ...previousState, newWorkBook: false, errorMessage: '' }))
        }
        if (value.length > 6) {
          setAddButtonDisable(false)
        }
        if (value.length < 15) {
          setNewWorkBook(value)
        }
      }
    }

    setChangesMade(index)
  }

  const handleAdd = (newWorkBook) => {
    if (REG_EX.CHECK_ONLY_NUMBER.test(newWorkBook)) {
      setWorkbookAvailableNumbers([[newWorkBook], ...workbookAvailableNumbers])
    } else if (REG_EX.EMAIL_REG_EX.test(newWorkBook)) {
      setWorkbookAvailableEmails([[newWorkBook], ...workbookAvailableEmails])
    }
    setNewWorkBook('')
    setSubmitButtonDisable(false)
    setAddButtonDisable(true)
    setWorkBookData([[newWorkBook], ...workbookAvailableNumbers, ...workbookAvailableEmails])
  }

  const handleDismiss = () => {
    setShowDialog({
      ...showDialog,
      showModal: false
    })
  }

  const handleDelete = (selectedItem) => {
    if (isEditActive) {
      if (REG_EX.CHECK_ONLY_NUMBER.test(selectedItem)) {
        const onlyNumber = workbookAvailableNumbers.filter((ele) => ele !== selectedItem)

        setWorkbookAvailableNumbers(onlyNumber)
        setWorkBookData([...workbookAvailableEmails, ...onlyNumber])
      } else if (REG_EX.EMAIL_REG_EX.test(selectedItem)) {
        const onlyEmail = workbookAvailableEmails.filter((ele) => ele !== selectedItem)

        setWorkbookAvailableEmails(onlyEmail)
        setWorkBookData([...workbookAvailableNumbers, ...onlyEmail])
      }

      setSubmitButtonDisable(false)
    }
  }

  const isExistingNumber = !workBookData.includes(newWorkBook)
  const isButtonDisable = addButtonDisable || hasErrors || !isExistingNumber || !isNumberExist

  return (
    <div className="work-book-data">
      <div className="work-book-input-container">
        {editSettings.editMode && <InputText name="email" placeholder={t('ADD_EMAIL_OR_PHONE_NUMBER')} className={`${error.newWorkBook ? 'p-invalid custom-hover-color' : 'custom-hover-color'} add-work-book-data`} value={newWorkBook} disabled={!editSettings.editMode} onChange={(event) => handleInput(event, 'setNewWorkBook')} keyfilter="email" />}

        <div className={!isEditActive ? 'edit' : ''}>
          {editSettings.editMode ? (
            <>
              <ButtonComponent type={'add'} disabled={isButtonDisable} onClick={() => handleAdd(newWorkBook)} />
            </>
          ) : (
            <div className="work-book-edit-conatiner">
              <ButtonComponent type={'edit'} onClick={handleEditButtonClick} />
            </div>
          )}
        </div>
      </div>
      {error.newWorkBook && <div className="error-message">{error.errorMessage}</div>}

      <div className="work-book-number">
        {workbookAvailableNumbers.map((ele, index) => (
          <div key={index} className="work-book-item-container">
            <div className="work-book-item">{ele}</div>
            {workbookAvailableNumbers && (
              <Button
                icon="pi pi-trash"
                tooltip={t('DELETE')}
                tooltipOptions={{ position: 'top' }}
                onClick={() => handleDelete(ele)}
                className={classNames('p-button-rounded p-button-text', {
                  'p-button-danger': isEditActive,
                  'p-button-secondary': !isEditActive
                })}
                disabled={!isEditActive}
              />
            )}
          </div>
        ))}
      </div>
      {workbookAvailableEmails.length > 0 && (
        <div className="work-book-email">
          {workbookAvailableEmails.map((ele, index) => (
            <div key={index} className="work-book-item-container-email">
              <div className="work-book-item">{ele}</div>
              {workbookAvailableEmails && (
                <div className="work-book-delete-button">
                  <Button
                    icon="pi pi-trash delete-icon"
                    tooltip={t('DELETE')}
                    tooltipOptions={{ position: 'top' }}
                    onClick={() => handleDelete(ele)}
                    className={classNames('p-button-rounded p-button-text', {
                      'p-button-danger': isEditActive,
                      'p-button-secondary': !isEditActive
                    })}
                    disabled={!isEditActive}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      <div className="work-book-button-conatiner">
        {editSettings.submitButton && (
          <div className="work-book-buttons">
            <ButtonComponent type={'cancel'} onClick={handleReset} />
            <ButtonComponent type={'submit'} onClick={() => workBookUpdate(workBookData)} disabled={submitButtonDisable || hasErrors} />
          </div>
        )}
      </div>
      <DialogModal {...showDialog} onDismiss={handleDismiss} />
    </div>
  )
}

export default Loader(WorkBookData)
