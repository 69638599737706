import './styles.scss'
import { React, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Form, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import Loader from 'Components/Loader'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'
import { getLocalUserDetails } from 'Utils/LocalStorageHandler'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const ChangePasswordDialog = ({ setLoading, isDialogOpen, setIsDialogOpen }) => {
  const { t } = useTranslation()
  const { id } = getLocalUserDetails()

  const [isPasswordMismatch, setIsPasswordMismatch] = useState(false)
  const [inputValues, setInputValues] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  })
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false
  })
  const [modalState, setModalState] = useState({
    showModal: false,
    modalType: '',
    message: '',
    onDismiss: () => {},
    onHide: () => {}
  })

  const { currentPassword, newPassword, confirmPassword } = inputValues
  const isSubmitButtonDisabled = !currentPassword || !newPassword || !confirmPassword || isPasswordMismatch

  const changePassword = async () => {
    setIsDialogOpen(false)
    setLoading(true)

    try {
      const response = await apiAdapterCoffeeWeb.changePassword({ userid: id, password: newPassword, CurrentPassword: currentPassword })

      if (response.status === 200) {
        if (response.data.isError === true) {
          setModalState({
            ...modalState,
            showModal: true,
            modalType: 'error',
            message: response.data.error,
            onDismiss: handlePopupDialog,
            onHide: handlePopupDialog
          })
        } else {
          handleCancel()
          setModalState({
            ...modalState,
            showModal: true,
            modalType: 'success',
            message: 'NEW_PASSWORD_UPDATED_SUCCESSFULLY',
            onHide: handleDismiss
          })
        }
      }
    } catch {
      setModalState({
        ...modalState,
        showModal: true,
        modalType: 'error',
        message: 'SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER',
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === 'newPassword' || name === 'confirmPassword') {
      const { newPassword, confirmPassword } = { ...inputValues, [name]: value }

      setIsPasswordMismatch(confirmPassword !== '' && newPassword !== confirmPassword)
    }

    setInputValues((previousValue) => ({
      ...previousValue,
      [name]: value
    }))
  }

  const handleTogglePassword = (field) => {
    setShowPassword((prevVisibility) => ({
      ...prevVisibility,
      [field]: !prevVisibility[field]
    }))
  }

  const handleDismiss = () => {
    setModalState({ ...modalState, showModal: false })
  }

  const handleCancel = () => {
    setIsDialogOpen(false)
    setIsPasswordMismatch(false)
    setShowPassword((previousState) => ({ ...previousState, currentPassword: false, newPassword: false, confirmPassword: false }))
    setInputValues((previousState) => ({ ...previousState, currentPassword: '', newPassword: '', confirmPassword: '' }))
  }

  const handlePopupDialog = () => {
    setIsDialogOpen(true)
    handleDismiss()
  }

  const onSubmit = (data) => {
    setInputValues(data)
  }

  const validate = (data) => {
    const errors = {}
    const { currentPassword, newPassword, confirmPassword } = data

    if (!newPassword) {
      errors.newPassword = t('NEW_PASSWORD_IS_REQUIRED')
    }
    if (!currentPassword) {
      errors.currentPassword = t('CURRENT_PASSWORD_IS_REQUIRED')
    }

    if (!confirmPassword) {
      errors.confirmPassword = t('CONFIRM_PASSWORD_IS_REQUIRED')
    }

    return errors
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  return (
    <>
      <Dialog header={t('CHANGE_PASSWORD')} className="change-password-dialog" visible={isDialogOpen} onHide={handleCancel}>
        <div className="change-password-container">
          <Form
            onSubmit={onSubmit}
            initialValues={inputValues}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className="p-fluid">
                <div>
                  <div className="otp-password-div">
                    <div className="current-password">
                      <Field
                        name="currentPassword"
                        render={({ input, meta }) => (
                          <div className="field">
                            <div className="p-inputgroup flex-1">
                              <span className="p-inputgroup-addon">
                                <i className="pi pi-key" />
                              </span>
                              <span className="p-input-icon-right">
                                {showPassword.currentPassword ? <i className="pi pi-eye" onClick={() => handleTogglePassword('currentPassword')} /> : <i className="pi pi-eye-slash" onClick={() => handleTogglePassword('currentPassword')} />}
                                <span className="p-float-label">
                                  <InputText {...input} type={!showPassword.currentPassword ? 'password' : 'text'} id="currentPassword" name="currentPassword" placeholder={t('CURRENT_PASSWORD')} value={inputValues.currentPassword} onChange={handleChange} feedback={false} className={classNames('password-input', { 'p-invalid': isFormFieldValid(meta) })} />
                                  <label htmlFor="currentPassword" className={classNames('label-text', { 'p-error': isFormFieldValid(meta) })}>
                                    {t('CURRENT_PASSWORD')} <span className="required-star"> *</span>
                                  </label>
                                </span>
                              </span>
                            </div>
                            <div className="error-message">{getFormErrorMessage(meta)}</div>
                          </div>
                        )}
                      />
                    </div>
                    <div className="new-password-container">
                      <div className="new-password">
                        <Field
                          name="newPassword"
                          render={({ input, meta }) => (
                            <div className="field">
                              <div className="p-inputgroup flex-1">
                                <span className="p-inputgroup-addon">
                                  <i className="pi pi-key" />
                                </span>
                                <span className="p-input-icon-right">
                                  {showPassword.newPassword ? <i className="pi pi-eye" onClick={() => handleTogglePassword('newPassword')} /> : <i className="pi pi-eye-slash" onClick={() => handleTogglePassword('newPassword')} />}
                                  <span className="p-float-label">
                                    <InputText {...input} type={!showPassword.newPassword ? 'password' : 'text'} id="newPassword" name="newPassword" value={inputValues.newPassword} onChange={handleChange} placeholder={t('NEW_PASSWORD')} feedback={false} className={classNames('password-input', { 'p-invalid': isFormFieldValid(meta) })} />
                                    <label htmlFor="newPassword" className={classNames('label-text', { 'p-error': isFormFieldValid(meta) })}>
                                      {t('NEW_PASSWORD')} <span className="required-star"> *</span>
                                    </label>
                                  </span>
                                </span>
                              </div>
                              <div className="error-message">{getFormErrorMessage(meta)}</div>
                            </div>
                          )}
                        />
                      </div>
                      <div className="confirm-password">
                        <Field
                          name="confirmPassword"
                          render={({ input, meta }) => (
                            <div className="field">
                              <div className="p-inputgroup flex-1">
                                <span className="p-inputgroup-addon">
                                  <i className="pi pi-key" />
                                </span>
                                <span className="p-input-icon-right">
                                  {showPassword.confirmPassword ? <i className="pi pi-eye" onClick={() => handleTogglePassword('confirmPassword')} /> : <i className="pi pi-eye-slash" onClick={() => handleTogglePassword('confirmPassword')} />}
                                  <span className="p-float-label">
                                    <InputText {...input} type={!showPassword.confirmPassword ? 'password' : 'text'} id="confirmPassword" name="confirmPassword" value={inputValues.confirmPassword} onChange={handleChange} placeholder={t('CONFIRM_PASSWORD')} feedback={false} className={classNames('password-input', { 'p-invalid': isFormFieldValid(meta) })} />
                                    <label htmlFor="confirmPassword" className={classNames('label-text', { 'p-error': isFormFieldValid(meta) })}>
                                      {t('CONFIRM_PASSWORD')} <span className="required-star"> *</span>
                                    </label>
                                  </span>
                                </span>
                              </div>
                              {isPasswordMismatch ? (
                                <div>
                                  <small className="p-error mismatch-password-error">{t('NEW_PASSWORD_AND_CONFIRM_PASSWORD_SHOULD_BE_SAME')}</small>
                                </div>
                              ) : (
                                <div className="error-message">{getFormErrorMessage(meta)}</div>
                              )}
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          />
          <div className="button-container">
            <ButtonComponent type="cancel" onClick={handleCancel} />
            <ButtonComponent type="submit" onClick={changePassword} disabled={isSubmitButtonDisabled} />
          </div>
        </div>
      </Dialog>
      <DialogModal {...modalState} onSuccess={handleDismiss} />
    </>
  )
}

export default Loader(ChangePasswordDialog)
