import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Field } from 'react-final-form'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import { commonMethods } from 'Utils/commonMethods'
import DialogModal from 'Components/UIComponents/DialogModal'
import Loader from 'Components/Loader'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const CountryCallingCodeSelector = ({ setLoading, value, onValueChange, isRadioChecked, inputName, onGetPhoneCode, onPhoneInputBlur, secondaryInputName, secondaryValue, onSecondaryValueChange, onResetCountryPhoneCode, phoneExist }) => {
  const { t } = useTranslation()

  const prefilledId = secondaryValue
  const [selectedCountryDetails, setSelectedCountryDetails] = useState({})
  const [countries, setCountries] = useState([])
  const [isPhoneFieldDisabled, setPhoneFieldDisabled] = useState(true)
  const [countryFetchError, setCountryFetchError] = useState(false)
  const [onDismissError, setOnDismissError] = useState(false)
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', modalCancel: '', onHide: () => {} })

  useEffect(() => {
    secondaryValue && !countries.length && fetchAndInitializeCountryData()

    return () => {
      localStorage.removeItem('hasApiFailedOnceCountry')
    }
  }, [secondaryValue])

  const fetchAndInitializeCountryData = async () => {
    setLoading(true)
    try {
      const { data: countryData } = await apiAdapterCoffeeWeb.getAllCountries()

      setCountries(countryData)
      const defaultCountry = countryData.find(({ id }) => id === (prefilledId || 102))

      setCountryFetchError(false)
      if (defaultCountry) {
        setSelectedCountryDetails(defaultCountry)
      }
    } catch {
      if (!localStorage.getItem('hasApiFailedOnceCountry')) {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'error',
          message: 'NO_DATA_FOUND',
          modalCancel: false,
          onHide: commonMethods.handleRetry
        })

        localStorage.setItem('hasApiFailedOnceCountry', 'true')
      } else {
        setCountryFetchError(true)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleSelect = (e) => {
    const {
      value,
      value: { id, phonecode }
    } = e.target

    if (id !== 0) {
      setSelectedCountryDetails(value)
      onGetPhoneCode(phonecode, id)
    }

    onSecondaryValueChange(e)
    setPhoneFieldDisabled(false)
  }

  const onPhoneInputBlurEvent = (e) => {
    onPhoneInputBlur(e)
  }

  const handlePhoneChange = (e) => {
    onValueChange(e)
  }

  const handleDismiss = () => {
    setOnDismissError(true)
    setShowDialog({ ...showDialog, showModal: false })
  }

  const validate = (value) => {
    const errors = {}

    if (!selectedCountryDetails.nicename) {
      errors.country = t('COUNTRY_IS_REQUIRED')
    }

    if (!value.phone) {
      errors.phone = t('PHONE_IS_REQUIRED')
    }

    return errors
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  const onSubmit = (data) => {
    setSelectedCountryDetails(data)
  }

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        initialValues={{ phone: value, country: '' }}
        validate={validate}
        render={() => (
          <div className="country-code-selector">
            <div className="p-input-group">
              <Field
                name="country"
                render={({ input, meta }) => (
                  <div className="country-field">
                    <span className="p-float-label">
                      <Dropdown
                        id="country"
                        {...input}
                        filter
                        resetFilterOnHide
                        filterPlaceholder={t('SEARCH_COUNTRY')}
                        className={`country-dropdown ${isFormFieldValid(meta) ? 'p-invalid' : ''} ${isFormFieldValid(meta) ? 'p-invalid-hover' : ''}`}
                        disabled={isRadioChecked || countryFetchError || onDismissError}
                        value={input.value || selectedCountryDetails}
                        placeholder={t('SELECT_COUNTRY')}
                        name={secondaryInputName}
                        onChange={(event) => {
                          input.onChange(event.value)
                          handleSelect(event)
                        }}
                        options={countries}
                        optionLabel="name"
                      />

                      <label htmlFor="countryDropdown" className={isFormFieldValid(meta) ? 'p-error' : ''}>
                        {t('COUNTRY')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    {onDismissError && (
                      <div className="error-message">
                        <span className="error-message">{t('COUNTRY_FETCH_FAILED')}</span>
                      </div>
                    )}
                    {!onDismissError && countryFetchError && (
                      <div className="error-message">
                        <span className="error-message">{t('COUNTRY_FETCH_FAILED')}</span>
                      </div>
                    )}
                    {!onDismissError && !countryFetchError && getFormErrorMessage(meta)}
                  </div>
                )}
              />
            </div>

            <Field
              name="phone"
              render={({ input, meta }) => (
                <div>
                  <div className="phone-field">
                    <span className="p-inputgroup-addon">
                      {'+'}
                      {!onResetCountryPhoneCode ? selectedCountryDetails.phonecode : onResetCountryPhoneCode}
                    </span>
                    <div className="country-phone-field">
                      <span className="p-float-label">
                        <InputText
                          {...input}
                          keyfilter="int"
                          name={inputName}
                          id="phone"
                          maxLength={12}
                          value={value}
                          disabled={isRadioChecked || isPhoneFieldDisabled}
                          onChange={handlePhoneChange}
                          onBlur={(e) => {
                            input.onBlur(e)
                            onPhoneInputBlurEvent(e)
                          }}
                          className={classNames('phone-input-field', { 'p-invalid': isFormFieldValid(meta), 'phone-field-disabled': isRadioChecked })}
                        />
                        <label htmlFor="phone" className={isFormFieldValid(meta) ? 'p-error' : ''}>
                          {t('PHONE')}
                          <span className="required-field">*</span>
                        </label>
                      </span>
                    </div>
                  </div>
                  {getFormErrorMessage(meta)}
                  <div className="error-message">{value !== '' && phoneExist}</div>
                </div>
              )}
            />
          </div>
        )}
      />
      <DialogModal {...showDialog} onDismiss={handleDismiss} onRetry={commonMethods.handleRetry} />
    </div>
  )
}

export default Loader(CountryCallingCodeSelector)
