import './styles.scss'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'

const DialogModal = (props) => {
  const { t } = useTranslation()
  const { modalType, showModal, message, onRetry, onDismiss, onSuccess, onConfirmation, onHide, onSubmit, multipleMessage } = props

  const [modalState, setModalState] = useState({
    showDialog: showModal,
    error: 'ERROR',
    success: 'SUCCESS',
    info: 'CONFIRMATION'
  })

  const actionButtons = [
    // ERROR MODAL
    {
      softAction: props?.softAction ? props?.softAction : 'DISMISS',
      hardAction: props?.hardAction ? props?.hardAction : 'RETRY'
    },
    // SUCCESS MODAL
    {
      softAction: props?.softAction ? props?.softAction : 'DISMISS',
      hardAction: props?.hardAction ? props?.hardAction : 'OK'
    },
    // INFO MODAL
    {
      softAction: props?.softAction ? props?.softAction : 'NO',
      hardAction: props?.hardAction ? props?.hardAction : 'YES'
    }
  ]

  useEffect(() => {
    setModalState({ ...modalState, showDialog: showModal })
  }, [showModal])

  const ErrorButtons = () => (
    <div className="dialog-component-footer">
      <Button label={t(actionButtons[0].softAction).toUpperCase()} onClick={() => onDismiss()} className="neutral-button" />
      {onRetry && <Button label={t(actionButtons[0].hardAction).toUpperCase()} onClick={() => onRetry()} className="retry-button" />}
    </div>
  )

  const SuccessButtons = () => (
    <div className="dialog-component-footer">
      <Button label={t(actionButtons[1].softAction).toUpperCase()} onClick={() => onSuccess()} className="neutral-button" />
      {onSubmit && <Button label={t(actionButtons[0].hardAction).toUpperCase()} onClick={() => onSubmit()} className="retry-button" />}
    </div>
  )

  const InfoButtons = () => (
    <div className="dialog-component-footer">
      <Button label={t(actionButtons[2].softAction).toUpperCase()} onClick={() => onDismiss()} className="neutral-button" />
      <Button label={t(actionButtons[2].hardAction).toUpperCase()} onClick={() => onConfirmation()} className="confirm-action-button" />
    </div>
  )

  const FooterMessage = () => (
    <>
      <div className="dialog-component-message">{t(message)}</div>
      {multipleMessage && (
        <div>
          <div>{t(multipleMessage.firstMessage)}</div>
          <div>{t(multipleMessage.secondMessage)}</div>
        </div>
      )}
    </>
  )

  return (
    <div data-testid="dialogModal">
      <Dialog
        visible={modalState.showDialog}
        onHide={() => onHide()}
        className="dialog-component"
        header={<span className={`dialog-component-${modalType}`}>{t(modalState[modalType])}</span>}
        footer={
          <>
            {modalType === 'error' && <ErrorButtons />}
            {modalType === 'success' && <SuccessButtons />}
            {modalType === 'info' && <InfoButtons />}
          </>
        }
      >
        {modalType === 'error' && <FooterMessage />}
        {modalType === 'success' && <FooterMessage />}
        {modalType === 'info' && (
          <div className="dialog-component-message-confirmation">
            <div className="pi pi-exclamation-triangle"></div>
            <FooterMessage />
          </div>
        )}
      </Dialog>
    </div>
  )
}

export default DialogModal
