import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SelectButton } from 'primereact/selectbutton'
import { CoffeeWebLogo, devEnvLogo } from 'Assets/Icons'
import { ROUTE_STRINGS } from 'Utils/Constants'
import { getLocalUserDetails, getLocalAuthToken } from 'Utils/LocalStorageHandler'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import Header from 'Components/Routes/Components/Header'
import Loader from 'Components/Loader'
import DialogModal from 'Components/UIComponents/DialogModal'
import ResetPasswordModal from './Components/ResetPasswordModal'
import LoginComponent from './Components/LoginComponent'
import LoginWithOTP from './Components/LoginWithOTP'

const LoginScreen = ({ setLoading }) => {
  const history = useHistory()

  const { t } = useTranslation()
  const userDetails = getLocalUserDetails()
  const accessToken = getLocalAuthToken()
  const tabId = sessionStorage.tabId ? sessionStorage.tabId : (sessionStorage.tabId = Math.random())
  const options = ['Password', 'OTP']

  window.sessionStorage.setItem('tabId', tabId)

  const [passwordResetVia, setPasswordResetVia] = useState('phone')
  const [loginType, setLoginType] = useState('Password')
  const [generatedToken, setGeneratedToken] = useState('')
  const [resetModal, setResetModal] = useState(false)
  const [resetError, setResetError] = useState(true)
  const [modalState, setModalState] = useState({
    showModal: false,
    modalType: '',
    message: ''
  })

  const params = new URLSearchParams(history.location.search)
  const authModParam = params.get('authMod')

  if (userDetails && accessToken && !authModParam) {
    history.push(ROUTE_STRINGS.coffeequotes)
  } else if (authModParam) {
    localStorage.clear()
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    const search = history.location.search

    if (search === `${'?'}${ROUTE_STRINGS.resetViaPhone}`) {
      setResetModal(true)
    } else if (search === `${'?'}${ROUTE_STRINGS.resetViaEmail}` || search.startsWith(`${'?'}${ROUTE_STRINGS.resetViaEmail}/`)) {
      setResetModal(true)
    } else {
      setResetModal(false)
    }
  }, [history.location.search])

  const init = async () => {
    try {
      setLoading(true)
      const response = await apiAdapterCoffeeWeb.generateAuthToken()
      const {
        data: { token }
      } = response

      setGeneratedToken(token)
    } catch {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const handleDismiss = () => {
    setModalState({
      ...modalState,
      showModal: false
    })
  }

  const handleLoginTypeChange = (event) => {
    setResetError(true)
    if (event.value !== null) {
      setLoginType(event.value)
    } else {
      setLoginType(loginType)
    }
  }

  const handleResetPasswordClick = () => {
    setResetError(false)
    setResetModal(true)
    const url = {
      pathname: history.location.pathname,
      search: ROUTE_STRINGS.resetViaPhone
    }

    history.push(url)
  }

  return (
    <>
      <Header />
      <div className="auth-screen">
        <div className="auth-screen-container">
          <div className="auth-screen-logo">
            <img alt="" src={process.env.REACT_APP_NODE_ENV === 'production' ? CoffeeWebLogo : devEnvLogo} />
          </div>
          <div className="auth-screen-tabs">
            <div className="card auth-tabs">
              <p className="login-text">{t('Login Using')}</p>
              <div>
                <div className="select-button">
                  <SelectButton value={loginType} className="select-type" onChange={handleLoginTypeChange} options={options} />
                </div>
                {loginType === 'Password' && <LoginComponent generatedToken={generatedToken} setModalState={setModalState} resetError={resetError} />}
                {loginType === 'OTP' && <LoginWithOTP generatedToken={generatedToken} setModalState={setModalState} resetError={resetError} />}
              </div>
              <div>{resetModal && <ResetPasswordModal setResetError={setResetError} resetError={resetError} setResetModal={setResetModal} generatedToken={generatedToken} resetModal={resetModal} setModalState={setModalState} setPasswordResetVia={setPasswordResetVia} passwordResetVia={passwordResetVia} />}</div>

              <div className="card-footer-auth-screen">
                <div className="forgot-password-container">
                  <div className="forgot-password-text" onClick={handleResetPasswordClick}>
                    {t('FORGOT_PASSWORD')}
                  </div>
                </div>{' '}
              </div>
            </div>
          </div>
          <DialogModal {...modalState} onDismiss={handleDismiss} onHide={handleDismiss} onSuccess={handleDismiss}></DialogModal>
        </div>
      </div>
    </>
  )
}

export default Loader(LoginScreen)
