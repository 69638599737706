import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button'
import { useLocation } from 'react-router-dom'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import Loader from 'Components/Loader'
import { REG_EX } from 'Utils/Constants'
import DialogModal from 'Components/UIComponents/DialogModal'
import { commonMethods } from 'Utils/commonMethods'
import { ButtonComponent } from 'Components/UIComponents'

const CoffeeQuotesData = ({ setLoading, setChangesMade, index }) => {
  const { t } = useTranslation()
  const location = useLocation()

  const [newCoffeeQuote, setNewCoffeeQuote] = useState('')
  const [coffeeQuotesData, setCoffeeQuotesData] = useState('')
  const [submitButtonDisable, setSubmitButtonDisable] = useState(true)
  const [addButtonDisable, setAddButtonDisable] = useState(true)
  const [isEditActive, setIsEditActive] = useState(false)
  const [deleteIconEnabled, setDeleteIconEnabled] = useState(false)
  const [isNumberExist, setIsNumberExist] = useState(false)
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onConfirmation: () => {}, onSuccess: () => {} })
  const [error, setError] = useState({
    newCoffeeQuote: false
  })

  const [coffeeQuotesAvailableNumbers, setCoffeeQuotesAvailableNumbers] = useState([])
  const [coffeeQuotesAvailableEmails, setCoffeeQuotesAvailableEmails] = useState([])
  const [editSettings, setEditSettings] = useState({
    editMode: false,
    submitButton: false,
    editIcon: true,
    workbookEditIcon: true,
    toggle: false,
    editText: false
  })

  useEffect(() => {
    getAllAppSetting(location.state)
  }, [])

  useEffect(() => {
    const debounceInputSearch = setTimeout(() => {
      validateInput(newCoffeeQuote)
    }, 300)

    return () => clearTimeout(debounceInputSearch)
  }, [newCoffeeQuote])

  const getAllAppSetting = async () => {
    setLoading(true)

    try {
      const response = await apiAdapterCoffeeWeb.appSetting()
      const data = response.data

      const coffeeQuotesAvailableData = data.coffeeQuotesAvailable.split(',')

      setCoffeeQuotesData(coffeeQuotesAvailableData)

      const onlyNumber = coffeeQuotesAvailableData.filter((ele) => REG_EX.CHECK_ONLY_NUMBER.test(ele))

      setCoffeeQuotesAvailableNumbers(onlyNumber)

      const onlyEmail = coffeeQuotesAvailableData.filter((ele) => REG_EX.EMAIL_REG_EX.test(ele))

      setCoffeeQuotesAvailableEmails(onlyEmail)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('NO_DATA_FOUND'),
        onRetry: commonMethods.handleRetry,
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const coffeeQuotesUpdate = async () => {
    try {
      setLoading(true)
      const response = await apiAdapterCoffeeWeb.updateCoffeeQuotesData(coffeeQuotesData)

      if (response.data.statusCode === 200) {
        setChangesMade(null)
        setEditSettings({
          editMode: false,
          submitButton: false,
          editIcon: true
        })
        setIsEditActive(false)

        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: t('COFFEE_QUOTES_DATA_UPDATED'),
          onHide: handleDismiss,
          onSuccess: handleDismiss
        })
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('COFFEE_QUOTES_DATA_UPDATE_FAIL'),
        onRetry: modalRetry,
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const checkNumberRegistrationStatus = async (phone, countryPhoneCode) => {
    try {
      const { data } = await apiAdapterCoffeeWeb.isPhoneExist({ phoneNo: phone, countryPhoneCode })

      setIsNumberExist(data)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('NO_DATA_FOUND'),
        onRetry: () => validateInput(phone),
        onHide: handleDismiss
      })
    }
  }
  const checkEmailRegistrationStatus = async (email) => {
    try {
      const { data } = await apiAdapterCoffeeWeb.isEmailExist({ email })

      setIsNumberExist(data)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('NO_DATA_FOUND'),
        onRetry: () => validateInput(email),
        onHide: handleDismiss
      })
    }
  }

  const modalRetry = () => {
    setShowDialog({
      ...showDialog,
      showModal: false
    })
    setTimeout(() => {
      coffeeQuotesUpdate()
    }, 300)
  }

  const handleEditButtonClick = () => {
    setIsEditActive((prevIsEditActive) => !prevIsEditActive)
    setEditSettings((prevEditSettings) => ({
      ...prevEditSettings,
      editMode: !prevEditSettings.editMode,
      editIcon: false,
      submitButton: true
    }))

    setSubmitButtonDisable(true)
    setDeleteIconEnabled(true)
  }

  const handleReset = () => {
    setError(false)
    setEditSettings({
      editMode: false,
      submitButton: false,
      editIcon: true
    })
    setIsEditActive(false)
    setChangesMade(false)
    setNewCoffeeQuote('')
    setAddButtonDisable(true)
    setDeleteIconEnabled(false)
    getAllAppSetting()
  }

  const hasErrors = Object.values(error).some((value) => value)
  const handleInput = (event) => {
    const { value, name } = event.target

    if (name === 'email') {
      if (value === '') {
        setNewCoffeeQuote(value)
        setError((previousState) => ({ ...previousState, newCoffeeQuote: true, errorMessage: t('EMAIL_OR_PHONE_NUMBER_IS_REQUIRED') }))
      } else if (!REG_EX.CHECK_ONLY_NUMBER.test(value)) {
        if (!REG_EX.EMAIL_REG_EX.test(value)) {
          setNewCoffeeQuote(value)
          setError((previousState) => ({ ...previousState, newCoffeeQuote: true, errorMessage: t('PLEASE_ENTER_VALID_EMAIL') }))
        } else {
          setNewCoffeeQuote(value)
          setAddButtonDisable(false)
          setError((previousState) => ({ ...previousState, newCoffeeQuote: false, errorMessage: '' }))
        }
      } else {
        if (value.length > 0) {
          setError((previousState) => ({ ...previousState, newCoffeeQuote: false, errorMessage: '' }))
        }
        if (value.length > 6) {
          setAddButtonDisable(false)
        }
        if (value.length < 15) {
          setNewCoffeeQuote(value)
        }
      }
    }

    setChangesMade(index)
  }

  const validateInput = (newCoffeeQuote) => {
    if (REG_EX.CHECK_ONLY_NUMBER.test(newCoffeeQuote) && newCoffeeQuote.length > 6) {
      checkNumberRegistrationStatus(newCoffeeQuote, 91)
    } else {
      REG_EX.EMAIL_REG_EX.test(newCoffeeQuote) && checkEmailRegistrationStatus(newCoffeeQuote)
    }
  }

  const handleAdd = (newCoffeeQuote) => {
    if (REG_EX.CHECK_ONLY_NUMBER.test(newCoffeeQuote)) {
      setCoffeeQuotesAvailableNumbers([[newCoffeeQuote], ...coffeeQuotesAvailableNumbers])
    } else if (REG_EX.EMAIL_REG_EX.test(newCoffeeQuote)) {
      setCoffeeQuotesAvailableEmails([[newCoffeeQuote], ...coffeeQuotesAvailableEmails])
    }
    setNewCoffeeQuote('')
    setSubmitButtonDisable(false)
    setAddButtonDisable(true)
    setCoffeeQuotesData([[newCoffeeQuote], ...coffeeQuotesAvailableNumbers, ...coffeeQuotesAvailableEmails])
  }

  const handleDelete = (selectedItem) => {
    if (isEditActive) {
      if (REG_EX.CHECK_ONLY_NUMBER.test(selectedItem)) {
        const onlyNumber = coffeeQuotesAvailableNumbers.filter((ele) => ele !== selectedItem)

        setCoffeeQuotesAvailableNumbers(onlyNumber)
        setCoffeeQuotesData([...coffeeQuotesAvailableEmails, ...onlyNumber])
      } else if (REG_EX.EMAIL_REG_EX.test(selectedItem)) {
        const onlyEmail = coffeeQuotesAvailableEmails.filter((ele) => ele !== selectedItem)

        setCoffeeQuotesAvailableEmails(onlyEmail)
        setCoffeeQuotesData([...coffeeQuotesAvailableNumbers, ...onlyEmail])
      }

      setSubmitButtonDisable(false)
    }
  }
  const handleDismiss = () => {
    setShowDialog({
      ...showDialog,
      showModal: false
    })
    setDeleteIconEnabled(false)
  }

  const isExistingNumber = !coffeeQuotesData.includes(newCoffeeQuote)
  const isButtonDisable = addButtonDisable || hasErrors || !isExistingNumber || !isNumberExist

  return (
    <div className="coffee-quotes-data">
      <div className="coffee-quotes-input-container">
        {editSettings.editMode && <InputText name="email" placeholder={t('ADD_EMAIL_OR_PHONE_NUMBER')} className={`${error.newCoffeeQuote ? 'p-invalid custom-hover-color' : 'custom-hover-color'} add-coffee-quotes`} value={newCoffeeQuote} onChange={(event) => handleInput(event, 'setNewCoffeeQuote')} keyfilter="email" />}

        <div className={!isEditActive ? 'edit' : ''}>
          {editSettings.editMode ? (
            <ButtonComponent type={'add'} disabled={isButtonDisable} onClick={() => handleAdd(newCoffeeQuote)} />
          ) : (
            <div className="coffee-quotes-edit-container">
              <ButtonComponent type={'edit'} onClick={handleEditButtonClick} />
            </div>
          )}
        </div>
      </div>
      {error.newCoffeeQuote && <div className="error-message">{error.errorMessage}</div>}

      <div className="coffee-quotes-number">
        {coffeeQuotesAvailableNumbers.map((ele, index) => (
          <div key={index} className="coffee-quote-item-container">
            <div className="coffee-quote-item">{ele}</div>
            {coffeeQuotesAvailableNumbers && (
              <Button
                icon="pi pi-trash"
                tooltip={t('DELETE')}
                onClick={() => handleDelete(ele)}
                tooltipOptions={{ position: 'top' }}
                className={classNames('p-button-rounded p-button-text', {
                  'p-button-danger': isEditActive,
                  'p-button-secondary': !isEditActive
                })}
                disabled={!deleteIconEnabled}
              />
            )}
          </div>
        ))}
      </div>
      {coffeeQuotesAvailableEmails.length > 0 && (
        <div className="coffee-quotes-email">
          {coffeeQuotesAvailableEmails.map((ele, index) => (
            <div key={index} className="coffee-quote-item-container">
              <div className="coffee-quote-item">{ele}</div>
              {coffeeQuotesAvailableEmails && (
                <div className="coffee-quotes-delete-button">
                  <Button
                    icon="pi pi-trash delete-icon"
                    tooltip={t('DELETE')}
                    onClick={() => handleDelete(ele)}
                    tooltipOptions={{ position: 'top' }}
                    className={classNames('p-button-rounded p-button-text', {
                      'p-button-danger': isEditActive,
                      'p-button-secondary': !isEditActive
                    })}
                    disabled={!deleteIconEnabled}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      <div className="coffee-quotes-button-container">
        {editSettings.submitButton && (
          <div className="coffee-quotes-buttons">
            <ButtonComponent type={'cancel'} onClick={handleReset} />
            <ButtonComponent type={'submit'} onClick={() => coffeeQuotesUpdate(coffeeQuotesData)} disabled={submitButtonDisable || hasErrors} />
          </div>
        )}
      </div>
      <DialogModal {...showDialog} onDismiss={handleDismiss} />
    </div>
  )
}

export default Loader(CoffeeQuotesData)
