import './styles.scss'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { DialogModal } from 'Components/UIComponents'
import { TABLE_CONST } from 'Utils/Constants'
import { commonMethods } from 'Utils/commonMethods'
import Loader from 'Components/Loader'
import LayoutContainer from 'Components/LayoutComponent'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const ReconciledReports = ({ setLoading }) => {
  const { t } = useTranslation()

  let rowCount = 4
  let paginationButtons = TABLE_CONST.paginationButtonCount

  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [getReconciledReports, setGetReconciledReports] = useState([])
  const [showTableMessage, setShowTableMessage] = useState(false)
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onConfirmation: () => {}, onSuccess: () => {} })

  if (windowSize < TABLE_CONST.maxWidthTab) {
    rowCount = TABLE_CONST.mobileRowCount
    paginationButtons = TABLE_CONST.mobilePaginationButtonCount
  }

  useEffect(() => {
    getReconciledReportData()

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const getReconciledReportData = async () => {
    setLoading(true)
    setShowTableMessage(true)

    try {
      const response = await apiAdapterCoffeeWeb.getReconciledReport()

      const paymentDateResponse = response?.data?.returnLst
      const modifiedData = paymentDateResponse.map((item) => {
        const paymentDate = commonMethods.utcToLocalTime(item.paymentDate)
        const transactionDate = commonMethods.utcToLocalTime(item.transactionDate)
        const tallyDate = commonMethods.utcToLocalTime(item.tallyDate)

        return {
          ...item,
          paymentDate,
          transactionDate,
          tallyDate
        }
      })

      setGetReconciledReports(modifiedData)
    } catch (error) {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: error?.response?.statusText,
        onRetry: handleRetry
      })
    } finally {
      setLoading(false)
      setShowTableMessage(false)
    }
  }

  const handleRetry = () => {
    setShowDialog({
      ...showDialog,
      showModal: false
    })
    getReconciledReportData()
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const handleDismiss = () => setShowDialog({ ...showDialog, showModal: false })

  const columns = [
    { dataField: 'idOrder', text: t('ORDER_ID'), className: 'order-id-column' },
    { dataField: 'invoiceNumber', text: t('INVOICE_NUMBER'), className: 'invoice-number-column' },
    { dataField: 'subscriptionName', text: t('SUBSCRIPTION_ID'), className: 'subscription-name-column' },
    { dataField: 'amount', text: t('TOTAL_AMOUNT'), className: 'total-amount-column' },
    { dataField: 'paymentStatus', text: t('PAYMENT_STATUS'), className: 'payment-status-column' },
    { dataField: 'paymentSource', text: t('PAYMENT_SOURCE'), className: 'payment-source-column' },
    { dataField: 'paymentId', text: t('PAYMENT_ID'), className: 'payment-source-column' },
    { dataField: 'paymentDate', text: t('PAYMENT_DATE'), className: 'payment-date-column' },
    { dataField: 'tallyDate', text: t('TALLY_DATE'), className: 'tally-date-column' },
    { dataField: 'utrNo', text: t('UTR_NO'), className: 'utr-no-column' },
    { dataField: 'settlementNo', text: t('SETTLEMENT_NO'), className: 'settlement-no-column' },
    { dataField: 'transactionDate', text: t('TRANSACTION_DATE'), className: 'transaction-date-column' },
    { dataField: 'grossAmount', text: t('GROSS_AMOUNT'), className: 'gross-amount-column' },
    { dataField: 'deductions', text: t('DEDUCTIONS'), className: 'deductions-column' },
    { dataField: 'netAmount', text: t('NET_AMOUNT'), className: 'net-amount-column' }
  ]

  const tableResponseMessage = () => <div>{showTableMessage ? t('FETCHING') : t('NO_RESULTS_FOUND')}</div>

  return (
    <div className="reconciled-report-container">
      <LayoutContainer title={'RECONCILED_REPORT'} breadCrumbsTitle={'reconciledReport'}>
        <div className="reconciled-report-table-wrapper">
          <div className="reconciled-report-content">
            <DataTable value={getReconciledReports} paginator={getReconciledReports?.length} rows={rowCount} pageLinkSize={paginationButtons} emptyMessage={tableResponseMessage} className="reconciled-report-table">
              {columns.map((column, index) => (
                <Column key={index} field={column.dataField} header={column.text} className={column.className} body={column.body} sortable={column.sortable} />
              ))}
            </DataTable>
          </div>
        </div>
      </LayoutContainer>
      <DialogModal {...showDialog} onDismiss={handleDismiss} onHide={handleDismiss} />
    </div>
  )
}

export default Loader(ReconciledReports)
