const KA_LANGUAGES = {
  devInProgress: 'ಡ್ಯಾಶ್‌ಬೋರ್ಡ್ ಪ್ರಸ್ತುತ ಅಭಿವೃದ್ಧಿಯಲ್ಲಿದೆ',
  COUNTRY: 'ದೇಶ',
  COUNTRY_IS_REQUIRED: 'ದೇಶದ ಅಗತ್ಯವಿದೆ',
  PHONE: 'ದೂರವಾಣಿ',
  PHONE_IS_REQUIRED: 'ಫೋನ್ ಅಗತ್ಯವಿದೆ',
  SEARCH_COUNTRY: 'ಹುಡುಕಾಟ ದೇಶ',

  // List Payment Country Mapping //
  LIST_PAYMENT_COUNTRY: 'ಪಾವತಿ ದೇಶವನ್ನು ಪಟ್ಟಿ ಮಾಡಿ',
  COUNTRY_NAME: 'ದೇಶದ ಹೆಸರು',
  PAYMENT_NAME: 'ಪಾವತಿ ಹೆಸರು',
  IS_ACTIVE: 'ಸಕ್ರಿಯವಾಗಿದೆ',
  SHOWING: 'ತೋರಿಸಲಾಗುತ್ತಿದೆ',
  TO: 'ಗೆ',
  OF: 'ನ',
  RESULTS: 'ಫಲಿತಾಂಶಗಳು'
}

export default KA_LANGUAGES
