import apiAdapterCoffeeWeb from '../Services/apiAdapterCoffeeWeb'
import { ROUTE_STRINGS } from './Constants'

export const coffeewebSetLocal = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}
export const coffeewebGetLocal = (key) => {
  const value = localStorage.getItem(key)

  return JSON.parse(value)
}
export const coffeewebClearLocal = () => {
  localStorage.clear()
}

export const updateUserDetails = async () => {
  const userDetails = getLocalUserDetails()
  const response = await apiAdapterCoffeeWeb.getuserbyuserid(userDetails?.id)

  setLocalWithUserDetails(response.data)
}

export const redirectToHome = (history) => {
  history.push({
    pathname: ROUTE_STRINGS.login
  })
}

export const coffeewebStorageKeys = {
  authToken: 'authToken',
  userDetails: 'userDetails',
  appSettings: 'appSettings',
  singleSignOn: 'singleSignOn',
  languageId: 'languageId',
  isLoggedIn: 'isLoggedIn'
}

const getLocalStorage = {
  getLocalUserDetails() {
    return coffeewebGetLocal(coffeewebStorageKeys.userDetails)
  },
  getLocalAuthToken() {
    return coffeewebGetLocal(coffeewebStorageKeys.authToken)
  },
  getLocalLoggedInStatus() {
    return coffeewebGetLocal(coffeewebStorageKeys.isLoggedIn)
  }
}

const setLocalStorage = {
  setLocalWithUserDetails(userData) {
    coffeewebSetLocal(coffeewebStorageKeys.userDetails, userData)
  },
  setLocalWithAuthToken(userAuthToken) {
    coffeewebSetLocal(coffeewebStorageKeys.authToken, userAuthToken)
  },
  setLocalWithLoggedInStatus(userLoginStatus) {
    coffeewebSetLocal(coffeewebStorageKeys.isLoggedIn, userLoginStatus)
  }
}

export const { getLocalUserDetails, getLocalAuthToken, getLocalLoggedInStatus } = getLocalStorage
export const { setLocalWithUserDetails, setLocalWithAuthToken, setLocalWithLoggedInStatus } = setLocalStorage
