import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputSwitch } from 'primereact/inputswitch'
import { InputText } from 'primereact/inputtext'
import { ROUTE_STRINGS, TABLE_CONST } from 'Utils/Constants'
import LayoutContainer from 'Components/LayoutComponent'
import DialogModal from 'Components/UIComponents/DialogModal'
import Loader from 'Components/Loader'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const ListPaymentCountry = ({ setLoading }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [paymentCountry, setPaymentCountry] = useState([])
  const [currentPaymentCountry, setCurrentPaymentCountry] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [modalState, setModalState] = useState({
    showModal: false,
    message: '',
    modalType: '',
    onRetry: () => {},
    onConfirmation: () => {},
    onHide: () => {}
  })

  let rowCount = 9
  let paginationButtons = TABLE_CONST.paginationButtonCount

  useEffect(() => {
    fetchPaymentCountryList()

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  if (windowSize < TABLE_CONST.maxWidthTab) {
    rowCount = TABLE_CONST.mobileRowCount
    paginationButtons = TABLE_CONST.mobilePaginationButtonCount
  }

  const fetchPaymentCountryList = async () => {
    setLoading(true)
    try {
      const {
        data: { returnLst }
      } = await apiAdapterCoffeeWeb.getAllPaymentCountry()

      setPaymentCountry(returnLst)
      setCurrentPaymentCountry(returnLst)
    } catch {
      setModalState({
        ...modalState,
        showModal: true,
        modalType: 'error',
        message: 'NO_DATA_FOUND',
        onHide: fetchPaymentCountryList,
        onRetry: fetchPaymentCountryList
      })
    } finally {
      setLoading(false)
    }
  }

  const activeListPaymentCountry = async ({ id, isActive }) => {
    setLoading(true)
    handleDismiss()

    try {
      const { data } = await apiAdapterCoffeeWeb.activeAndInactivePayment({ userId: id, activeAndInactive: !isActive })

      if (data) {
        const toggleIsActive = (ele) => (id === ele.id ? { ...ele, isActive: !ele.isActive } : ele)

        const modifiedPaymentList = paymentCountry.map(toggleIsActive)
        const modifiedPaymentFilterList = currentPaymentCountry.map(toggleIsActive)

        setPaymentCountry(modifiedPaymentList)
        setCurrentPaymentCountry(modifiedPaymentFilterList)
      }
    } catch {
      setModalState({
        ...modalState,
        showModal: true,
        modalType: 'error',
        message: 'FAILED_TO_ACTIVATE_INACTIVATE',
        onHide: handleDismiss,
        onRetry: activeListPaymentCountry
      })
    } finally {
      setLoading(false)
    }
  }

  const openConfirmDialog = (options) => {
    if (options.isActive) {
      setModalState({
        ...modalState,
        showModal: true,
        message: 'CONFIRM_THE_DEACTIVATION_OF_PAYMENT',
        modalType: 'info',
        onHide: handleDismiss,
        onConfirmation: () => activeListPaymentCountry(options)
      })
    } else {
      activeListPaymentCountry(options)
    }
  }

  const searchInData = (e) => {
    const searchTerm = e.target.value.toLowerCase()

    setSearchQuery(searchTerm)
    const filtered = currentPaymentCountry.filter(({ nicename, paymentName }) => nicename.toLowerCase().includes(searchTerm) || paymentName.toLowerCase().includes(searchTerm))

    setPaymentCountry(filtered)
  }

  const handleAddButtonClick = () => {
    history.push(ROUTE_STRINGS.addPaymentCountry)
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const handleDismiss = () => {
    setModalState({ ...modalState, showModal: false })
  }

  const makePaymentMethodActive = (options) => (
    <div className="toggle-switch">
      <InputSwitch checked={options.isActive} onChange={() => openConfirmDialog(options)} />
    </div>
  )

  return (
    <LayoutContainer title={'LIST_PAYMENT_COUNTRY'} breadCrumbsTitle={'listPaymentMapping'} showAddButton={true} handleAddButtonClick={handleAddButtonClick}>
      <div className="list-payment-mapping">
        <div className="search-wrapper">
          <div className="search-field p-input-icon-left">
            <i className="pi pi-search" />
            <InputText value={searchQuery} onChange={(e) => searchInData(e)} placeholder={t('SEARCH')} />
          </div>
        </div>
        <DataTable value={paymentCountry} paginator rows={rowCount} pageLinkSize={paginationButtons}>
          <Column field="nicename" header={t('COUNTRY_NAME')} className="country-column" />
          <Column field="paymentName" header={t('PAYMENT_NAME')} className="payment-column" />
          <Column header={t('IS_ACTIVE')} className="action-column" body={makePaymentMethodActive} />
        </DataTable>
      </div>
      <DialogModal {...modalState} onDismiss={handleDismiss} />
    </LayoutContainer>
  )
}

export default Loader(ListPaymentCountry)
