import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { act } from 'react-dom/test-utils'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { ROUTE_STRINGS, TABLE_CONST } from 'Utils/Constants'
import { commonMethods } from 'Utils/commonMethods'
import Loader from 'Components/Loader'
import LayoutContainer from 'Components/LayoutComponent'
import DialogModal from 'Components/UIComponents/DialogModal'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const ListRoles = ({ setLoading }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [rolesMaster, setRolesMaster] = useState([])
  const [globalFilter, setGlobalFilter] = useState(null)
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [showTableMessage, setShowTableMessage] = useState(false)
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', modalCancel: '', onHide: () => {} })

  let rowCount = TABLE_CONST.rowsCount
  let paginationButtons = TABLE_CONST.paginationButtonCount

  useEffect(() => {
    roleMaster()

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  if (windowSize < TABLE_CONST.maxWidthTab) {
    rowCount = TABLE_CONST.mobileRowCount
    paginationButtons = TABLE_CONST.mobilePaginationButtonCount
  }

  const roleMaster = async () => {
    setLoading(true)
    setShowTableMessage(true)
    try {
      const response = await apiAdapterCoffeeWeb.getAllRoles()

      setRolesMaster(response.data.returnLst)
    } catch {
      act(() => {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'error',
          message: 'NO_DATA_FOUND',
          modalCancel: false,
          onHide: commonMethods.handleRetry
        })
      })
    } finally {
      act(() => {
        setLoading(false)
        setShowTableMessage(false)
      })
    }
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const handleAddButtonClick = () => {
    history.push(ROUTE_STRINGS.addRoles)
  }

  const handleRoleFeature = () => {
    history.push(ROUTE_STRINGS.roleFeature)
  }

  const handleSearch = (e) => {
    setGlobalFilter(e.target.value)
  }

  const handleDismiss = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  const tableResponseMessage = () => <div>{showTableMessage ? t('FETCHING') : t('NO_RESULTS_FOUND')}</div>

  return (
    <LayoutContainer title={'LIST_ROLES'} breadCrumbsTitle={'listRoles'} showAddButton={true} showRoleFeatureButton={true} handleRoleFeatureButtonClick={handleRoleFeature} handleAddButtonClick={handleAddButtonClick}>
      <div className="list-roles">
        <div className="list-role-search-bar">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText placeholder={t('SEARCH')} value={globalFilter || ''} onChange={handleSearch} className="search-field" />
          </span>
        </div>

        <DataTable value={rolesMaster} globalFilter={globalFilter} paginator={rolesMaster.length} rows={rowCount} pageLinkSize={paginationButtons} emptyMessage={tableResponseMessage}>
          <Column field="roleName" header={t('ROLE_NAME')} className="list-role-table" />
          <Column field="roleCode" header={t('ROLE_CODE')} className="list-role-code" />
        </DataTable>
      </div>
      <DialogModal {...showDialog} onDismiss={handleDismiss} onRetry={commonMethods.handleRetry} />
    </LayoutContainer>
  )
}

export default Loader(ListRoles)
