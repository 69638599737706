const HI_LANGUAGES = {
  devInProgress: 'डैशबोर्ड वर्तमान में विकास के दौर से गुजर रहा है',
  COUNTRY: ' देश',
  COUNTRY_IS_REQUIRED: 'देश चाहिए',
  PHONE: 'फ़ोन',
  PHONE_IS_REQUIRED: 'फ़ोन आवश्यक है',
  SEARCH_COUNTRY: 'देश खोजें',

  // List Payment Country Mapping //
  LIST_PAYMENT_COUNTRY: 'सूची भुगतान देश',
  COUNTRY_NAME: 'देश नाम',
  PAYMENT_NAME: 'भुगतान का नाम',
  IS_ACTIVE: 'सक्रिय है',
  SHOWING: 'दिखा',
  TO: 'को',
  OF: 'का',
  RESULTS: 'परिणाम'
}

export default HI_LANGUAGES
