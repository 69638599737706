import './styles.scss'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
// https://codesandbox.io/s/nostalgic-browser-e9lpmf?file=/src/App.js:679-692

// ErrorFallback component to display when an error occurs
function ErrorFallback({ error, resetErrorBoundary }) {
  console.log('ErrorFallback', error)

  return (
    <div className="error-boundary-container">
      <h2>Something went wrong</h2>
      <p>{error.status}</p>
      <p>{error.title}</p>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}
// Wrapper component using ErrorBoundary
function MyErrorBoundary({ children }) {
  return <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
}
export default MyErrorBoundary
