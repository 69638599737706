import './styles.scss'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TabView, TabPanel } from 'primereact/tabview'
import { TAB_MENUS } from 'Utils/Constants/RouteStrings'
import LayoutContainer from 'Components/LayoutComponent'
import DialogModal from 'Components/UIComponents/DialogModal'
import CoffeeQuotesData from './pages/CoffeeQuotes'
import WorkBook from './pages/Workbook'
import FeatureToggles from './pages/FeatureToggles'
import Settings from './pages/Settings'

const AppSettings = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const urlParams = new URLSearchParams(history.location.search)

  const tabMapping = [TAB_MENUS.settingsRoute, TAB_MENUS.optionsRoute, TAB_MENUS.workBooksRoute, TAB_MENUS.coffeeQuotesRoute]

  const initialActiveTab = urlParams.get('tab') || TAB_MENUS.settingsRoute
  const [activeTab, setActiveTab] = useState(initialActiveTab)
  const [changesMade, setChangesMade] = useState(null)
  const [indexValue, setIndexValue] = useState(null)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)

  useEffect(() => {
    const params = new URLSearchParams()

    params.set('tab', activeTab)
    history.replace({ search: params.toString() })
  }, [activeTab, history])

  const handleTabChange = (index) => {
    if (!changesMade) {
      setActiveTab(tabMapping[index] || tabMapping[0])
    } else if (changesMade !== index) {
      setShowConfirmationDialog(true)
      setIndexValue(index)
    }
  }

  const handleProceed = () => {
    setShowConfirmationDialog(false)
    setActiveTab(tabMapping[indexValue] || tabMapping[0])
    setChangesMade(null)
  }

  const handleCancel = () => {
    setShowConfirmationDialog(false)
  }

  return (
    <LayoutContainer title={t('LIST_APP_SETTING')} breadCrumbsTitle={'listAppSettings'}>
      <div className="card">
        <div className="tab-container">
          <TabView activeIndex={tabMapping.indexOf(activeTab)} onTabChange={(e) => handleTabChange(e.index)}>
            <TabPanel header={t('SETTINGS')} className="app-settings-tab-panel">
              <Settings setChangesMade={setChangesMade} index={0} />
            </TabPanel>
            <TabPanel header={t('OPTIONS_HEADING')} className="app-settings-tab-panel">
              <FeatureToggles setChangesMade={setChangesMade} index={1} />
            </TabPanel>
            <TabPanel header={t('WORK_BOOK')} className="app-settings-tab-panel">
              <WorkBook setChangesMade={setChangesMade} index={2} />
            </TabPanel>
            <TabPanel header={t('COFFEE_QUOTES')} className="app-settings-tab-panel">
              <CoffeeQuotesData setChangesMade={setChangesMade} index={3} />
            </TabPanel>
          </TabView>
        </div>
      </div>

      <DialogModal modalType="info" message={t('ARE_YOU_SURE_YOU_WANT_TO_SWITCH_TABS')} showModal={showConfirmationDialog} onConfirmation={handleProceed} onDismiss={handleCancel} onHide={handleCancel} />
    </LayoutContainer>
  )
}

export default AppSettings
