import './styles.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import { Toast } from 'primereact/toast'
import { classNames } from 'primereact/utils'
import { InputText } from 'primereact/inputtext'
import CountrySelector from 'Components/UIComponents/CountrySelector'
import Loader from 'Components/Loader'
import LayoutContainer from 'Components/LayoutComponent'
import Validation from 'Utils/Validation'
import { ROUTE_STRINGS } from 'Utils/Constants'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { ButtonComponent, DialogModal } from 'Components/UIComponents'

const AddLanguage = ({ setLoading, addLanguageMockData }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const toast = useRef()
  let modalCancel

  const [isInputDisabled, setIsInputDisabled] = useState(false)
  const [showDialog, setShowDialog] = useState({ showModal: false, modalCancel: false, modalType: '', message: '', errorMessagePostApiFails: false, onDismiss: () => {}, onRetry: () => {} })
  const [addLanguageData, setAddLanguageData] = useState({
    idLanguage: '',
    languageName: '',
    countryId: 102,
    localLanguageName: '',
    isActive: false
  })

  const { languageName, countryId, localLanguageName } = addLanguageData

  // useEffect to initialize state for testing purposes
  useEffect(() => {
    if (addLanguageMockData) {
      setAddLanguageData(addLanguageMockData)
    }
  }, [])

  const createNewLanguage = async () => {
    const posLanguageData = {
      idLanguage: 0,
      languageName,
      countryId,
      isActive: true,
      flagUrl: 'NA',
      localLanguageName
    }

    setLoading(true)

    try {
      const {
        data: { statusCode }
      } = await apiAdapterCoffeeWeb.postLanguages(posLanguageData)

      if (statusCode === 201) {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: 'LANGUAGE_ADDED_SUCCESSFULLY',
          modalCancel: false,
          errorMessagePostApiFails: true
        })
      } else if (statusCode === 400) {
        toast.current.show({ severity: t('ERROR_TOAST'), summary: t('DATA_ALREADY_EXISTS'), detail: t('PLEASE_ENTER_ANOTHER_LANGUAGE_NAME') })
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: 'SORRY_COULD_NOT_ADD_DATA_PLEASE_TRY_AGAIN_LATER',
        modalCancel: false,
        errorMessagePostApiFails: false,
        onDismiss: handleDismiss,
        onRetry: postRetry
      })
    } finally {
      setLoading(false)
    }
  }

  const handleGetDismiss = () => {
    setIsInputDisabled(true)
    setShowDialog({
      ...showDialog,
      showModal: false
    })
  }

  const handleCountryChange = (countryId) => {
    setAddLanguageData((previousData) => ({
      ...previousData,
      countryId
    }))
  }

  const handleChange = (event) => {
    const { value, name } = event.target

    const validateLanguageName = (value) => Validation.languageValidation(value) || Validation.isInputFieldEmpty(value)

    if (validateLanguageName(value)) {
      setAddLanguageData({
        ...addLanguageData,
        [name]: value
      })
    }
  }

  const handleSuccess = () => {
    history.push(ROUTE_STRINGS.listLanguage)
  }

  const handleDismiss = () => {
    setShowDialog({
      ...showDialog,
      showModal: false
    })
  }

  const postRetry = () => {
    setShowDialog({
      ...showDialog,
      showModal: false
    })
    setLoading(true)
    createNewLanguage()
    setLoading(false)
  }

  const onSubmit = (data) => {
    setAddLanguageData(data)
  }

  const handleSubmit = () => {
    createNewLanguage()
  }

  const isButtonDisabled = !languageName || !localLanguageName

  const validate = (data) => {
    const errors = {}

    const { languageName, localLanguageName } = data

    if (!languageName) {
      errors.languageName = t('LANGUAGE_NAME_IS_REQUIRED')
    }

    if (!localLanguageName) {
      errors.localLanguageName = t('LOCAL_LANGUAGE_NAME_REQUIRED')
    }

    return errors
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  if (showDialog.modalCancel) {
    modalCancel = () => handleGetDismiss() || window.location.reload()
  } else if (showDialog.errorMessagePostApiFails) {
    modalCancel = () => history.push(ROUTE_STRINGS.listLanguage)
  } else {
    modalCancel = () => handleDismiss()
  }

  return (
    <LayoutContainer title={'ADD_LANGUAGE'} breadCrumbsTitle={'addLanguage'}>
      <div className="add-language-container" data-testid="addLanguage-form">
        <Form
          onSubmit={onSubmit}
          initialValues={addLanguageData}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="field">
                <CountrySelector isFormComponent={true} countryId={countryId} selectCurrency={handleCountryChange} disabled={isInputDisabled} />
              </div>

              <Field
                name="languageName"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <InputText id="languageName" {...input} value={addLanguageData.languageName} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} data-testid="languageName" />
                      <label htmlFor="languageName" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'mapping-label'}`)}>
                        {t('LANGUAGE_NAME')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    <div className="error-message">{getFormErrorMessage(meta)}</div>
                  </div>
                )}
              />

              <Field
                name="localLanguageName"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <InputText id="localLanguageName" {...input} value={addLanguageData.localLanguageName} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} data-testid="localLanguageName" />
                      <label htmlFor="localLanguageName" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'mapping-label'}`)}>
                        {t('LOCAL_LANGUAGE_NAME')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    <div className="error-message">{getFormErrorMessage(meta)}</div>
                  </div>
                )}
              />
            </form>
          )}
        />

        <div className="button-container">
          <ButtonComponent type={'submit'} disabled={isButtonDisabled} onClick={handleSubmit} />
        </div>
      </div>

      <DialogModal {...showDialog} onSuccess={handleSuccess} onHide={modalCancel} />

      <Toast ref={toast} position="top-right" />
    </LayoutContainer>
  )
}

export default Loader(AddLanguage)
