import './styles.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { FileUpload } from 'primereact/fileupload'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { classNames } from 'primereact/utils'
import { getLocalUserDetails } from 'Utils/LocalStorageHandler'
import { REG_EX, ROUTE_STRINGS } from 'Utils/Constants'
import Validation from 'Utils/Validation'
import Loader from 'Components/Loader'
import ButtonComponent from 'Components/UIComponents/ButtonComponent'
import LayoutContainer from 'Components/LayoutComponent'
import DialogModal from 'Components/UIComponents/DialogModal'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const AddMenuGroup = ({ setLoading }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const location = useLocation()
  const toast = useRef(null)
  const userDetails = getLocalUserDetails()
  const params = new URLSearchParams()
  const searchParams = new URLSearchParams(location.search)
  const tab = searchParams.get('tab')

  const [menuUser, setMenuUser] = useState([])
  const [imageUrl, setImageUrl] = useState('')
  const [userMenuIcon, setUserMenuIcon] = useState('')
  const [selectedFile, setSelectedFile] = useState(null)
  const [addMenuData, setAddMenuData] = useState({
    Menu: '',
    menuName: '',
    MenuIcon: '',
    menuUrl: '',
    pageCode: '',
    mobilePath: '',
    isadminConsoleMenu: false
  })

  const [errorState, setErrorState] = useState({
    Menu: '',
    menuName: '',
    MenuIcon: '',
    menuUrl: '',
    pageCode: '',
    mobilePath: ''
  })

  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {}, onConfirmation: () => {}, onSuccess: () => {} })
  const { Menu, menuName, menuUrl, pageCode, mobilePath, isadminConsoleMenu } = addMenuData

  const submitFileToS3Bucket = async (event) => {
    setLoading(true)

    const selectedFile = event.files[0]

    const fileData = new FormData()

    fileData.append('file', selectedFile)

    try {
      const response = await apiAdapterCoffeeWeb.uploadFileToS3Bucket(fileData)

      setSelectedFile(response.data)
      setImageUrl(response.data)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('FAILED_TO_UPLOAD'),
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getallMenuMaster()
  }, [])

  const getallMenuMaster = async () => {
    handleDismiss()
    setLoading(true)
    try {
      const response = await apiAdapterCoffeeWeb.getAllMenu()
      const filteredMenuList = response.data.filter((menu) => menu.isadminConsoleMenu)

      setMenuUser(filteredMenuList)
      setUserMenuIcon(response.data[0].menuIcon)
    } catch (error) {
      if (tab === 'admin_menu') {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'error',
          message: t('NO_DATA_FOUND'),
          onRetry: getallMenuMaster,
          onHide: handleDismiss
        })
      }
    } finally {
      setLoading(false)
    }
  }

  const createNewMenu = async (imgUrlLink) => {
    handleDismiss()
    setLoading(true)

    try {
      const dataObject = {
        id: 0,
        menuName: menuName,
        parentId: Menu === '' ? 0 : parseInt(Menu, 10),
        disaplayOrder: 0,
        menuUrl: menuUrl,
        isActive: true,
        levelId: 0,
        menuClass: 'string',
        isAvailable: true,
        parentDisaplayOrder: 0,
        isWebDisplayEnable: true,
        isMobDisplayEnable: true,
        isPwadisplayEnable: true,
        isDemoMenu: true,
        isLaunch: true,
        launchDate: '2023-11-03T04:03:39.057Z',
        menuIcon: imgUrlLink === undefined ? userMenuIcon : imgUrlLink,
        pageCode: pageCode,
        mobilePath: mobilePath === '' ? 'NA' : mobilePath,
        userId: userDetails.id,
        createdDate: '2023-11-03T04:03:39.057Z',
        lastupdatedDate: '2023-11-03T04:03:39.057Z',
        subMenuModels: 'string',
        menulock: true,
        isadminConsoleMenu: isadminConsoleMenu
      }

      const { data } = await apiAdapterCoffeeWeb.insertMenu(dataObject)

      if (data.statusCode === 201) {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: t('MENU_ADDED_SUCCESSFULLY'),
          onHide: handleSuccess,
          onSuccess: handleSuccess
        })
      }
    } catch (error) {
      const { statusCode } = error.response.data

      if (statusCode === 404) {
        toast.current.show({ severity: t('ERROR_TOAST'), summary: t('DATA_ALREADY_EXISTS'), detail: t('PLEASE_ENTER_ANOTHER_MENU_NAME') })
      } else {
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'error',
          message: t('FAILED_TO_ADD_MENU'),
          onRetry: handleSaveButton,
          onHide: handleSuccess
        })
      }
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (event) => {
    const { value, name } = event.target

    let isInvalidValue = value === '' || (!REG_EX.NAME_REG_EX.test(value) && name !== 'menuUrl')

    if (name === 'menuUrl' || name === 'mobilePath') {
      isInvalidValue = value === '' || !REG_EX.URL_REG_EX.test(value)
    }

    if (name === 'menu') {
      if (Validation.isInputFieldEmpty) {
        setAddMenuData((prevData) => ({
          ...prevData,
          Menu: value,
          isadminConsoleMenu: true
        }))
      }
    }
    setAddMenuData((prevData) => ({
      ...prevData,
      [name]: value
    }))

    setErrorState((prevErrState) => ({
      ...prevErrState,
      [name]: isInvalidValue
    }))
  }

  const validate = () => {
    const errors = {}

    const { menu, menuName, menuUrl, pageCode, mobilePath } = addMenuData

    if (!(tab === 'admin_menu' && menu)) {
      errors.menu = t('MENU_IS_REQUIRED')
    }
    if (!menuName) {
      errors.menuName = t('MENU_NAME_REQUIRED')
    }
    if (!menuUrl) {
      errors.menuUrl = t('MENU_URL_REQUIRED')
    }
    if (!pageCode) {
      errors.pageCode = t('PAGE_CODE_REQUIRED')
    }
    if (!mobilePath) {
      errors.mobilePath = t('MOBILE_PATH_REQUIRED')
    }

    return errors
  }

  const menuItems = [...menuUser.map((item) => ({ label: item.menuName, value: item.id }))]

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  const submitButtonDisabled = (tab === 'admin_menu' && !Menu) || !menuName || !menuUrl || !pageCode || (tab === 'client_menu' && !mobilePath)

  const handleSaveButton = () => {
    createNewMenu(selectedFile)
  }

  const onSubmit = (addMenuData) => {
    setMenuUser(addMenuData)
  }

  const handleDismiss = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  const handleDeleteImage = () => {
    setSelectedFile(null)
    setImageUrl('')
  }

  const handleSuccess = () => {
    if (tab === 'admin_menu') {
      params.set('tab', 'admin_menu')
      history.push({ pathname: `${ROUTE_STRINGS.listmenu}`, search: params.toString() })
    } else {
      params.set('tab', 'client_menu')
      history.push({ pathname: `${ROUTE_STRINGS.listmenu}`, search: params.toString() })
    }
  }

  return (
    <LayoutContainer title={tab === 'admin_menu' ? t('ADD_ADMIN_MENU') : t('ADD_CLIENT_MENU')} breadCrumbsTitle={tab === 'admin_menu' ? 'addMenu' : 'addClientMenu'}>
      <div className="add-menu">
        <Form
          onSubmit={onSubmit}
          initialValues={menuUser}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {tab === 'admin_menu' && (
                <Field
                  name="menu"
                  render={({ input, meta }) => (
                    <div className="field">
                      <div className="dropdown">
                        <div className="p-float-label">
                          <Dropdown {...input} id="menu" name="menu" value={Menu} options={menuItems} onChange={handleChange} className={classNames(`${isFormFieldValid(meta) ? 'p-invalid' : ''}`)} placeholder={t('SELECT_MENU')} filter filterPlaceholder={t('SEARCH_MENU')} />
                          <label htmlFor="menu" className={classNames(`${isFormFieldValid(meta) ? 'p-error' : 'mapping-label'}`)}>
                            {t('MENUS')} <span className="star-required">*</span>
                          </label>
                        </div>
                      </div>
                      <div className="error-message">{getFormErrorMessage(meta)}</div>
                    </div>
                  )}
                />
              )}

              <Field
                name="menuName"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <InputText id="menuName" {...input} value={menuName} onChange={handleChange} className={classNames(isFormFieldValid(meta) || errorState.menuName ? 'error' : 'add-menu-field')} />

                      <label htmlFor="menuName" className={classNames(isFormFieldValid(meta) || errorState.menuName ? 'p-error' : 'mapping-label')}>
                        {t('MENU_NAME')} <span className="star-required">*</span>
                      </label>
                    </span>
                    {errorState.menuName ? <div className="error-message">{t('INVALID_INPUT')}</div> : getFormErrorMessage(meta) && <div className="error-message">{getFormErrorMessage(meta)}</div>}
                  </div>
                )}
              />
              <Field
                name="menuUrl"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <InputText id="menuUrl" {...input} onChange={handleChange} value={menuUrl} className={classNames(isFormFieldValid(meta) || errorState.menuName ? 'error' : 'add-menu-field')} />

                      <label htmlFor="menuUrl" className={classNames(isFormFieldValid(meta) || errorState.menuUrl ? 'p-error' : 'mapping-label')}>
                        {t('MENU_URL')} <span className="star-required">*</span>
                      </label>
                    </span>
                    {errorState.menuUrl ? <div className="error-message">{t('INVALID_INPUT')}</div> : getFormErrorMessage(meta) && <div className="error-message">{getFormErrorMessage(meta)}</div>}
                  </div>
                )}
              />

              <Field
                name="pageCode"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <InputText id="pageCode" {...input} onChange={handleChange} value={pageCode} className={classNames(isFormFieldValid(meta) || errorState.pageCode ? 'error' : 'add-menu-field')} />

                      <label htmlFor="pageCode" className={classNames(isFormFieldValid(meta) || errorState.pageCode ? 'p-error' : 'mapping-label')}>
                        {t('PAGE_CODE')} <span className="star-required">*</span>
                      </label>
                    </span>
                    {errorState.pageCode ? <div className="error-message">{t('INVALID_INPUT')}</div> : getFormErrorMessage(meta) && <div className="error-message">{getFormErrorMessage(meta)}</div>}
                  </div>
                )}
              />

              {tab === 'client_menu' && (
                <Field
                  className="menu"
                  name="mobilePath"
                  render={({ input, meta }) => (
                    <div className="field">
                      <span className="p-float-label">
                        <InputText id="mobilePath" {...input} onChange={handleChange} value={mobilePath} className={classNames(isFormFieldValid(meta) || errorState.mobilePath ? 'error' : 'add-menu-field')} />

                        <label htmlFor="mobilePath" className={classNames(isFormFieldValid(meta) || errorState.mobilePath ? 'p-error' : 'mapping-label')}>
                          {t('MOBILE_PATH')} <span className="star-required">*</span>
                        </label>
                      </span>
                      {errorState.mobilePath ? <div className="error-message">{t('INVALID_INPUT')}</div> : getFormErrorMessage(meta) && <div className="error-message">{getFormErrorMessage(meta)}</div>}
                    </div>
                  )}
                />
              )}

              <div className="file-upload">
                <label htmlFor="menu" className="menu-upload">
                  {t('MENU_LOGO')}
                </label>
                <FileUpload
                  chooseLabel="Choose File"
                  accept="image/*,.pdf"
                  auto={true}
                  onSelect={(event) => submitFileToS3Bucket(event)}
                  emptyTemplate={
                    <div>
                      {imageUrl ? (
                        <div className="add-file-upload">
                          <div className="image-file">
                            <div className="add-image-container">
                              <img src={imageUrl} alt="new-component" />
                            </div>

                            <div className="cancel-action">
                              <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={handleDeleteImage} />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <p className="choose-title">{t('IMAGE_UPLOAD')}</p>
                      )}
                    </div>
                  }
                />
              </div>

              <div className="button-container">
                <ButtonComponent type={'submit'} onClick={handleSaveButton} disabled={submitButtonDisabled} />
              </div>
            </form>
          )}
        />
      </div>

      <DialogModal {...showDialog} onDismiss={handleDismiss} />
      <Toast ref={toast} position="top-right" />
    </LayoutContainer>
  )
}

export default Loader(AddMenuGroup)
