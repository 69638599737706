import './styles.scss'
import { React, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { Messages } from 'primereact/messages'
import { TabView, TabPanel } from 'primereact/tabview'
import { commonMethods } from 'Utils/commonMethods'
import { TABLE_CONST } from 'Utils/Constants'
import { getLocalUserDetails } from 'Utils/LocalStorageHandler'
import CountrySelector from 'Components/UIComponents/CountrySelector'
import { ButtonComponent } from 'Components/UIComponents'
import DialogModal from 'Components/UIComponents/DialogModal'
import Loader from 'Components/Loader'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const GlobalRawDataPreview = ({ setLoading }) => {
  const { t } = useTranslation()
  const showErrorMessage = useRef(null)
  const { countryId, id } = getLocalUserDetails()

  const [coffeePrice, setCoffeePrice] = useState([])
  const [producingCountries, setProducingCountries] = useState([])
  const [errorMessage, setErrorMessage] = useState(false)
  const [countryCode, setCountryCode] = useState('')
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [showTableMessage, setShowTableMessage] = useState(false)
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', modalCancel: '', onHide: () => {} })

  let rowCount = 6
  let paginationButtons = TABLE_CONST.paginationButtonCount

  const columns = [
    {
      dataField: 'qualityName',
      text: t('QUALITY')
    },
    {
      dataField: 'lowRate',
      text: t('LOW_RATE')
    },
    {
      dataField: 'highRate',
      text: t('HIGH_RATE')
    },
    {
      dataField: 'bagWeight',
      text: t('BAG_WEIGHT')
    },
    {
      dataField: 'mc',
      text: t('MC')
    },
    {
      dataField: 'outTurn',
      text: t('OUT_TURN')
    },
    {
      dataField: 'remarks',
      text: t('REMARKS')
    }
  ]

  useEffect(() => {
    fetchCoffeeNewsDataByCurrencyId(countryId)

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  useEffect(() => {
    if (showErrorMessage.current) {
      showErrorMessage.current.clear()
      showErrorMessage.current.show([{ sticky: true, severity: 'info', summary: 'Error', detail: t('NO_DATA_FOUND_FOR_THIS_SELECTED_COUNTRY'), closable: false }])
    }
  })

  if (windowSize < TABLE_CONST.maxWidthTab) {
    rowCount = TABLE_CONST.mobileRowCount
    paginationButtons = TABLE_CONST.mobilePaginationButtonCount
  }

  const fetchCoffeeNewsDataByCurrencyId = async (id) => {
    setLoading(true)
    setShowTableMessage(true)
    try {
      setCountryCode(id)

      const coffeePriceApiResponse = await apiAdapterCoffeeWeb.getGlobalRawCoffeePriceOrderByCurrencyForAdmin({ id })

      if (coffeePriceApiResponse.data.returnLst.length > 0) {
        const coffeeData = coffeePriceApiResponse.data.returnLst[0].gcrpProducingCountry

        setCoffeePrice(coffeeData.map((country) => country.grcpAdminlstDTO))
        setProducingCountries(coffeeData.map((country) => country.producingCountry))
      } else {
        setErrorMessage(true)
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: 'NO_DATA_FOUND',
        modalCancel: false,
        onHide: commonMethods.handleRetry
      })
    } finally {
      setLoading(false)
      setShowTableMessage(false)
    }
  }

  const approveRawCoffeePrice = async () => {
    setLoading(true)

    try {
      const postData = {
        idUser: id,
        idCountry: countryCode,
        nextUpdateDate: '2023-01-03T04:56:39.313Z'
      }

      if (id === 2) {
        const approveRawCoffeePriceAdmin = await apiAdapterCoffeeWeb.postApproveCoffeePriceAdmin(postData)

        if (approveRawCoffeePriceAdmin.status === 200 || approveRawCoffeePriceAdmin.status === 201) {
          setShowDialog({
            showModal: true,
            modalType: 'success',
            message: 'DATA_APPROVED_SUCCESSFULLY',
            onHide: handleSuccess
          })
        }
      } else {
        const approveRawCoffeePriceSuperAdmin = await apiAdapterCoffeeWeb.postSuperAdminApproveRawCoffeePrice(postData)

        if (approveRawCoffeePriceSuperAdmin.status === 200 || approveRawCoffeePriceSuperAdmin.status === 201) {
          setShowDialog({
            showModal: true,
            modalType: 'success',
            message: 'DATA_APPROVED_SUCCESSFULLY',
            onHide: handleSuccess
          })
        }
      }
    } catch {
      if (id === 2) {
        setShowDialog({
          showModal: true,
          modalType: 'success',
          message: 'DATA_APPROVED_SUCCESSFULLY',
          onHide: handleSuccess
        })
      } else {
        setShowDialog({
          showModal: true,
          modalType: 'error',
          message: 'ERROR_WHILE_FETCHING_GLOBAL_RAW_DATA',
          onHide: commonMethods.handleRetry
        })
      }
    } finally {
      setLoading(false)
    }
  }

  const handleCurrencyDropdown = (id) => {
    fetchCoffeeNewsDataByCurrencyId(id)
    setCountryCode(id)
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const handleDismiss = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  const handleSuccess = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  const headerGroup = (countryName) => (
    <ColumnGroup>
      <Row>
        <Column header={`${countryName} ${t('RAW_COFFEES')}`} className="column-producing-country" />
        <Column header={t('PRICE_PER_BAG')} className="column-price-bag" />
        <Column header={t('STANDARDS')} className="column-price-bag-rate" />
        <Column header="" className="column-data" />
        <Column header="" className="column-data" />
        <Column header={columns[6].text} />
        <Column header="" className="column-row-data" />
        <Column header="" className="column-price-bag-rate" />
      </Row>

      <Row>
        <Column header={columns[0].text} />
        <Column
          body={(rowData) => (
            <div className="column-content">
              <div className="column-content-div">
                <span className="row-data-content">{rowData[columns[1].dataField]}</span>
                <span className="row-data-content">{rowData[columns[2].dataField]}</span>
              </div>
            </div>
          )}
          header={<span className="header-content">{t('BETWEEN')}</span>}
          className="column-row-data"
        />
        <Column header={columns[3].text} className="column-price-bag-rate" />
        <Column header={columns[4].text} className="column-data" />
        <Column header={columns[5].text} className="column-outTurn" />
        <Column header="" />
        <Column header="" />
        <Column header="" />
      </Row>
    </ColumnGroup>
  )

  const tableResponseMessage = () => <div>{showTableMessage ? t('FETCHING') : t('NO_RESULTS_FOUND')}</div>

  return (
    <div className="global-raw-data-container">
      <div className="country-select-component">
        <CountrySelector selectCurrency={handleCurrencyDropdown} countryId={countryId} />
        <ButtonComponent type="custom" label="APPROVE" icon="pi pi-check-circle" variant="action" onClick={approveRawCoffeePrice} />
      </div>
      <TabView className="global-data-table">
        {coffeePrice.map((data, index) => (
          <TabPanel className="global-raw-data-tab-panel" key={index} header={`${producingCountries[index]} ${t('RAW_COFFEES')}`}>
            <DataTable value={data} headerColumnGroup={headerGroup(producingCountries[index])} paginator={data.length} rows={rowCount} pageLinkSize={paginationButtons} emptyMessage={tableResponseMessage}>
              <Column field={columns[0].dataField}></Column>
              <Column
                body={(rowData) => (
                  <div className="column-content">
                    <span className="row-data-content">{rowData[columns[1].dataField]}</span>
                    <span className="column-price-rate">&nbsp;&nbsp;</span>
                    <span className="row-data-content">{rowData[columns[2].dataField]}</span>
                  </div>
                )}
                className="column-row-data"
              />
              <Column field={columns[3].dataField}></Column>
              <Column field={columns[4].dataField}></Column>
              <Column field={columns[5].dataField}></Column>
              <Column field={columns[6].dataField} className="column-row-data"></Column>
              <Column header="" />
              <Column header="" />
            </DataTable>
          </TabPanel>
        ))}
      </TabView>
      {errorMessage && <Messages ref={showErrorMessage} />}
      <DialogModal {...showDialog} onDismiss={handleDismiss} onSuccess={handleSuccess} onRetry={commonMethods.handleRetry} />
    </div>
  )
}

export default Loader(GlobalRawDataPreview)
