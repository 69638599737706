import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { ROUTE_STRINGS } from 'Utils/Constants'
import LayoutContainer from 'Components/LayoutComponent'
import ProfileInformation from './ProfileInformation'

const AddUser = () => (
  <LayoutContainer title={'ADD_USER'} breadCrumbsTitle={'addUser'}>
    <Switch>
      <Route path={ROUTE_STRINGS.addUser} component={ProfileInformation} />
    </Switch>
  </LayoutContainer>
)

export default AddUser
