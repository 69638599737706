import { REG_EX } from './Constants'

const { PHONE_REG_EX, EMAIL_REG_EX, PASSWORD_REG_EX, NAME_REG_EX, NUMBER_DOTS_REG_EX, LANGUAGE_REG_EX, USER_NAME_REG_EX, ONLY_NUMBER_REG_EX, COLOR_PATTERN, PHONE_SPECIAL_CHAR, URL_REG_EX } = REG_EX

const Validation = {
  phoneNumberValidation(value) {
    return PHONE_REG_EX.test(value.trim())
  },
  emailValidation(value) {
    return EMAIL_REG_EX.test(value.trim())
  },
  phoneValidation(value) {
    return PHONE_REG_EX.test(value.trim())
  },
  passwordValidation(value) {
    return PASSWORD_REG_EX.test(value.trim())
  },
  nameValidation(value) {
    return NAME_REG_EX.test(value.trim())
  },
  userNameValidation(value) {
    return USER_NAME_REG_EX.test(value.trim())
  },
  numberAndDotsValidation(value) {
    return NUMBER_DOTS_REG_EX.test(value.trim())
  },
  checkEmptyField(...values) {
    return values.every((value) => value === '')
  },
  checkAllInputFieldsNotEmpty(...values) {
    return values.every((value) => value !== '')
  },
  checkEmailValidity(checkEmail) {
    return EMAIL_REG_EX.test(checkEmail)
  },
  checkEmptySomeInputField(...values) {
    return values.some((value) => value === '')
  },
  isInputFieldEmpty(value) {
    return value.trim() === ''
  },
  checkEmptyFieldNull(...values) {
    return values.every((value) => value === null)
  },
  languageValidation(value) {
    return LANGUAGE_REG_EX.test(value.trim())
  },
  numberValidation(value) {
    return ONLY_NUMBER_REG_EX.test(value.trim())
  },
  colorValidation(value) {
    return COLOR_PATTERN.test(value.trim())
  },
  phoneCharValidation(value) {
    return PHONE_SPECIAL_CHAR.test(value.trim())
  },
  urlValidation(value) {
    return URL_REG_EX.test(value.trim())
  }
}

export default Validation
