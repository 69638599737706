function formatNumber(num) {
  return parseFloat(num).toFixed(2)
}

export const coffeeQuotesDefaultValue = Array.from({ length: 5 }, () => ({
  isHighlight: 0,
  id: 0,
  createdBy: 0,
  createdOn: '2023-08-07T04:13:55.454Z',
  updatedBy: 0,
  updatedDtms: '2023-08-07T04:13:55.454Z',
  idMarket: 0,
  marketName: '--- --',
  lastChng: formatNumber(0),
  chng: formatNumber(0),
  percentageVal: formatNumber(0),
  volume: 0,
  highRate: formatNumber(0),
  netHigh: formatNumber(0),
  highRateCurrency: formatNumber(0),
  lowRate: formatNumber(0),
  netLow: formatNumber(0),
  lowRateCurrency: formatNumber(0),
  openRate: formatNumber(0),
  prevRate: formatNumber(0),
  openInterest: 0,
  bid: formatNumber(0),
  bsize: 0,
  ask: formatNumber(0),
  asize: 0,
  optionExpiry: 'DD-MMM-YYYY',
  optionExpiryStatus: null,
  optionExpiryDateFormat: 'DD-MMM-YYYY',
  firstNoticeDate: 'DD-MMM-YYYY',
  firstNoticeDateStatus: null,
  firstNoticeDateFormat: 'DD-MMM-YYYY',
  highCurrency: formatNumber(0),
  lowCurrency: formatNumber(0),
  _52weeksHighRate: formatNumber(0),
  _52weeksLowRate: formatNumber(0),
  userSymbolId: 0,
  orderBy: 0
}))
