import './styles.scss'
import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import { Card } from 'primereact/card'
import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import { Messages } from 'primereact/messages'
import { commonMethods } from 'Utils/commonMethods'
// TODO
// import { getLocalUserDetails } from 'Utils/LocalStorageHandler'
import { ROUTE_STRINGS } from 'Utils/Constants'
import Loader from 'Components/Loader'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import DialogModal from 'Components/UIComponents/DialogModal'

const DeleteAccount = ({ setLoading }) => {
  const { i18n, t } = useTranslation()
  const history = useHistory()
  const { id: paramId } = useParams()
  const msgs = useRef(null)

  // TODO TRADING ACCOUNT DELETION
  // const userDetails = getLocalUserDetails()
  // const [userDetailsData, setUserDetailsData] = useState({})
  // const { isTraderVerified, traderId } = userDetailsData

  const [modalState, setModalState] = useState({
    showModal: false,
    modalType: '',
    message: '',
    softAction: '',
    hardAction: '',
    onConfirmation: () => {},
    onSuccess: () => {},
    onHide: () => {},
    onDismiss: () => {},
    onRetry: () => {}
  })

  const { modalType } = modalState

  useEffect(() => {
    if (msgs.current) {
      msgs.current.clear()
      msgs.current.show([{ sticky: true, severity: 'error', summary: 'Error', detail: t('DELETE_ACCOUNT_ALERT'), closable: false }])
    }

    // TODO TRADING ACCOUNT DELETION
    // getUserDetail()
  }, [])

  // const getUserDetail = async () => {
  //   try {
  //     const selectedUser = await apiAdapterCoffeeWeb.getUserByUserId(paramId)
  //     setUserDetailsData(selectedUser.data)
  //   } catch {
  //     setModalState((previousState) => ({
  //       ...previousState,
  //       showModal: true,
  //       modalType: 'error',
  //       message: 'NO_DATA_FOUND'
  //     }))
  //   }
  // }

  // const handleTradingAccountDeletion = () => {
  //   setModalState((previousState) => ({
  //     ...previousState,
  //     showConfirmationModal: true,
  //     modalType: 'info',
  //     modalMessage: 'DELETE_TRADING_ACCOUNT_MESSAGE'
  //   }))
  // }

  const handleAccountDeletion = () => {
    setModalState(() => ({
      showModal: true,
      modalType: 'info',
      message: 'DELETE_ACCOUNT_ALERT_MESSAGE',
      onConfirmation: handleConfirmation,
      onDismiss: handleDismiss,
      onHide: handleDismiss
    }))
  }

  const handleConfirmation = async () => {
    setLoading(true)

    setModalState((previousState) => ({ ...previousState, showModal: false }))

    try {
      const response = await apiAdapterCoffeeWeb.deleteAccount(paramId)

      if (response.status === 200) {
        setModalState((previousState) => ({
          ...previousState,
          showModal: true,
          modalType: 'success',
          message: 'DELETE_ACCOUNT_SUCCESS',
          onSuccess: handleSuccess,
          onHide: handleSuccess
        }))
      }
    } catch {
      setModalState((previousState) => ({
        ...previousState,
        showModal: true,
        modalType: 'error',
        message: 'DELETE_ACCOUNT_FAILED',
        onRetry: handleConfirmation,
        onDismiss: handleDismiss,
        onHide: handleDismiss
      }))
    } finally {
      setLoading(false)
    }
  }

  // TODO TRADING ACCOUNT DELETION

  // const handleTradingConfirmation = async () => {
  //   setLoading(true)
  //   const { updatedBy } = userDetails
  //   setModalState((previousState) => ({
  //     ...previousState,
  //     showConfirmationModal: false
  //   }))
  //   try {
  //     const response = await apiAdapterCoffeeWeb.deletetrader({ traderId, updatedBy })

  //     if (response.status === 200) {
  //       setLoading(false)
  //       setModalState((previousState) => ({
  //         ...previousState,
  //         showConfirmationModal: false,
  //         showSuccessModal: true,
  //         modalType: 'success',
  //         modalMessage: 'DELETE_TRADING_ACCOUNT_SUCCESS'
  //       }))
  //     }
  //   } catch {
  //     setLoading(false)
  //     setModalState((previousState) => ({
  //       ...previousState,
  //       showModal: true,
  //       modalType: 'error',
  //       message: 'DELETE_ACCOUNT_FAILED'
  //     }))
  //   }
  // }

  const handleDismiss = () => {
    if (modalType === 'success') {
      handleSuccess()
    }

    setModalState((previousState) => ({
      ...previousState,
      showModal: false
    }))
  }

  const handleSuccess = () => {
    setModalState((previousState) => ({ ...previousState, showModal: false }))
    history.push(ROUTE_STRINGS.listUsers)
  }

  return (
    <>
      <div className="delete-tab-container">
        {/* TODO TRADING ACCOUNT DELETION */}

        {/* {isTraderVerified && (
          <div className="card">
            <Card>
              <div className="card-title">{t('DELETE_TRADING_ACCOUNT')}</div>
              <Divider />
              <div className="card-content">
                <div className="card-description">{t('DELETE_ACCOUNT_DESCRIPTION')}</div>
                <div className="delete-button-container">
                  <Button label={t('YES_DELETE')} className="delete-button" onClick={handleTradingAccountDeletion} />
                </div>
              </div>
            </Card>
          </div>
        )} */}

        <div className="card">
          <Card>
            <div className="card-title">{t('DELETE_ACCOUNT')}</div>
            <Divider />

            <div className="card-content">
              {t('DELETE_ACCOUNT_ALERT_MESSAGE')} <Messages ref={msgs} />
              <div className="delete-button-container">
                <Button label={t('YES_DELETE')} className="delete-button" onClick={handleAccountDeletion} />
              </div>
            </div>
          </Card>
        </div>
      </div>

      <DialogModal {...modalState} />
    </>
  )
}

export default Loader(DeleteAccount)
