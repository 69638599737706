import './styles.scss'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputSwitch } from 'primereact/inputswitch'
import { InputText } from 'primereact/inputtext'
import { Messages } from 'primereact/messages'
import { Button } from 'primereact/button'
import { Tooltip } from 'primereact/tooltip'
import { ROUTE_STRINGS, TABLE_CONST } from 'Utils/Constants'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import Loader from 'Components/Loader'
import DialogModal from 'Components/UIComponents/DialogModal'
import { ButtonComponent } from 'Components/UIComponents'

const ListSubscriptionPaymentMapping = ({ setLoading }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const location = useLocation()
  const msgs = useRef(null)
  const params = new URLSearchParams()
  const searchParams = new URLSearchParams(location.search)

  const {
    state: { name, id: subscriptionId }
  } = location

  let { rowsCount: rowsPerPage, paginationButtonCount: paginationButtons } = TABLE_CONST

  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [subscriptionList, setSubscriptionList] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [showTableMessage, setShowTableMessage] = useState(false)
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {} })

  useEffect(() => {
    getAllSubscriptionPaymentMappingDetailsById()
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])
  useEffect(() => {
    if (msgs.current) {
      msgs.current.clear()

      msgs.current.show([{ sticky: true, severity: 'info', summary: 'Info :', detail: t('SUBSCRIPTION_PAYMENT_INFO'), closable: false }])
    }
  }, [])

  if (windowSize < TABLE_CONST.maxWidthTab) {
    rowsPerPage = TABLE_CONST.mobileRowCount
    paginationButtons = TABLE_CONST.mobilePaginationButtonCount
  }

  const getAllSubscriptionPaymentMappingDetailsById = async () => {
    handleDismiss()
    setLoading(true)
    setShowTableMessage(true)
    try {
      const response = await apiAdapterCoffeeWeb.getSubscriptionPaymentMappingDataById(subscriptionId)

      setSubscriptionList(response.data.returnLst)
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('NO_DATA_FOUND'),
        onRetry: getAllSubscriptionPaymentMappingDetailsById,
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
      setShowTableMessage(false)
    }
  }

  const UpdateSubscriptionPaymentMapping = async (item) => {
    handleDismiss()
    setLoading(true)
    try {
      if (!item.isSubScriptionPaymentMapping) {
        await apiAdapterCoffeeWeb.activateSubscriptionMapping(item.id)
      } else {
        await apiAdapterCoffeeWeb.deactivateSubscriptionMapping(item.id)
      }
      const updatedList = subscriptionList.map((element) => {
        if (element.id === item.id) {
          return {
            ...element,
            isSubScriptionPaymentMapping: !item.isSubScriptionPaymentMapping
          }
        }

        return element
      })

      setSubscriptionList(updatedList)
    } catch {
      setShowDialog({
        showModal: true,
        modalType: 'error',
        message: 'FAILED_TO_UPDATE_SUBSCRIPTION_PAYMENT',
        onHide: handleDismiss,
        onRetry: () => UpdateSubscriptionPaymentMapping(item)
      })
    } finally {
      setLoading(false)
    }
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value)
  }

  const handleEditMenuGroup = (item) => {
    history.push({
      pathname: `${ROUTE_STRINGS.editSubscriptionPaymentMapping}/${item.id}`,
      state: location.state
    })
  }

  const handleAddMenu = () => {
    const tabParm = searchParams.get('tab')

    params.set('tab', tabParm)
    history.push({ pathname: ROUTE_STRINGS.addSubscriptionPaymentMapping, search: params.toString(), state: location.state })
  }

  const editScreenRedirect = (index) => <Button type="button" icon="pi pi-pencil" className="edit-button" tooltip={t('EDIT')} tooltipOptions={{ position: 'top' }} onClick={() => handleEditMenuGroup(index)} />

  const filteredList = subscriptionList.filter((item) => item.subscriptionSecondName.toLowerCase().includes(searchInput.toLowerCase()) || item.paymentName.toLowerCase().includes(searchInput.toLowerCase()))

  const toggleActivate = (item) => (
    <div className="toggle-switch-menu">
      <InputSwitch checked={item.isSubScriptionPaymentMapping} onChange={() => openConfirmDialog(item)} />
    </div>
  )

  const openConfirmDialog = (item) => {
    if (item.isSubScriptionPaymentMapping) {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'info',
        message: t('ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE_SUBSCRIPTION'),
        onConfirmation: () => UpdateSubscriptionPaymentMapping(item),
        onHide: handleDismiss
      })
    } else {
      UpdateSubscriptionPaymentMapping(item)
    }
  }

  const handleDismiss = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  const columns = [
    {
      field: 'paymentName',
      header: t('PAYMENT_NAME'),
      className: 'payment-name'
    },

    {
      body: toggleActivate,
      header: t('IS_ACTIVE'),
      className: 'is-active'
    },

    {
      body: editScreenRedirect,
      header: t('ACTIONS'),
      className: 'action-column'
    }
  ]

  const isAddButtonDisabled = !location.state.isActive
  const tableResponseMessage = () => <div>{showTableMessage ? t('FETCHING') : t('NO_RESULTS_FOUND')}</div>

  return (
    <div className="subscription-payment-container">
      {name !== 'Regular' || subscriptionId !== 1 ? (
        <>
          <div className="subscription-payment-header">
            <div className="subscription-search-field">
              <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={searchInput} onChange={handleSearchInputChange} placeholder={t('SEARCH')} className="search-field" />
              </span>
            </div>

            <Tooltip target=".subscription-payment-add-button" position="bottom" className="custom-tooltip">
              {isAddButtonDisabled && (
                <div className="subscription-not-active-message">
                  <span>{t('INACTIVE_SUBSCRIPTION_MESSAGE')}</span>
                </div>
              )}
            </Tooltip>

            <div className={`subscription-payment-add-button ${isAddButtonDisabled ? 'disabled-button' : ''}`}>
              <ButtonComponent type="add" disabled={isAddButtonDisabled} onClick={handleAddMenu} className="custom-tooltip-btn" />
            </div>
          </div>

          <div className="subscription-payment-table-container">
            <DataTable value={filteredList} paginator={filteredList.length} rows={rowsPerPage} pageLinkSize={paginationButtons} emptyMessage={tableResponseMessage} className="subscription-payment-table">
              {columns.map((column, index) => (
                <Column key={index} field={column.field} body={column.body} header={column.header} className={column.className} />
              ))}
            </DataTable>
          </div>
        </>
      ) : (
        <div className="no-data-content">
          <Messages ref={msgs} />
        </div>
      )}

      <DialogModal {...showDialog} onDismiss={handleDismiss} />
    </div>
  )
}

export default Loader(ListSubscriptionPaymentMapping)
