import './styles.scss'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { useTranslation } from 'react-i18next'
import { Form, Field } from 'react-final-form'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { ROUTE_STRINGS } from 'Utils/Constants'
import LayoutContainer from 'Components/LayoutComponent'
import Loader from 'Components/Loader'
import DialogModal from 'Components/UIComponents/DialogModal'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import { ButtonComponent } from 'Components/UIComponents'

const AddTerminalDetails = ({ setLoading }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [showDialog, setShowDialog] = useState({ showModal: false, modalType: '', message: '', onHide: () => {}, onRetry: () => {} })
  const [terminalDetails, setTerminalDetails] = useState({
    id: 0,
    idMarket: '',
    contractName: '',
    optionExpiry: '',
    firstNoticeDay: '',
    terminalId: '',
    isActive: true,
    _52weeksHighRate: '',
    _52weeksLowRate: '',
    _52weeksHighDate: '',
    _52weeksLowDate: ''
  })

  const marketType = [
    { id: 1, type: t('ROBUSTA') },
    { id: 2, type: t('ARABICA') }
  ]

  const addTerminalDetails = async () => {
    setLoading(true)
    const data = {
      id: 0,
      idMarket: terminalDetails.idMarket,
      contractName: terminalDetails.contractName,
      optionExpiry: terminalDetails.optionExpirydateFormat,
      firstNoticeDay: terminalDetails.firstNoticeDaydateFormat,
      terminalId: terminalDetails.terminalId,
      isActive: true,
      /* eslint-disable no-underscore-dangle */
      _52weeksHighRate: terminalDetails._52weeksHighRate,
      /* eslint-disable no-underscore-dangle */
      _52weeksLowRate: terminalDetails._52weeksLowRate,
      /* eslint-disable no-underscore-dangle */
      _52weeksHighDate: terminalDetails._52weeksHighDatedateFormat,
      /* eslint-disable no-underscore-dangle */
      _52weeksLowDate: terminalDetails._52weeksLowDatedateFormat
    }

    try {
      const response = await apiAdapterCoffeeWeb.terminalDetails(data)
      const { status: responseStatus } = response

      if (responseStatus === 200) {
        setLoading(false)
        setShowDialog({
          ...showDialog,
          showModal: true,
          modalType: 'success',
          message: t('ADDED_TERMINAL_DATA_SUCCESSFULLY'),
          onHide: handleSuccess
        })
      }
    } catch {
      setShowDialog({
        ...showDialog,
        showModal: true,
        modalType: 'error',
        message: t('COULD_NOT_ADD_TERMINAL_DATA'),
        onRetry: handleRetry,
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = (data) => {
    setTerminalDetails(data)
  }

  const handleChange = (event) => {
    const { name, value } = event.target

    setTerminalDetails((prevData) => ({
      ...prevData,
      [name]: name === 'idMarket' ? parseInt(value, 10) : value
    }))
  }

  const handleDateChange = (event) => {
    const { value, name } = event.target
    const selectedDate = event.value

    let formattedDate = ''

    if (selectedDate) {
      const year = selectedDate.getFullYear()
      const month = selectedDate.getMonth() + 1
      const date = selectedDate.getDate()
      const hours = selectedDate.getHours()
      const minutes = selectedDate.getMinutes()
      const seconds = selectedDate.getSeconds()

      formattedDate = `${year}-${month.toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    }

    setTerminalDetails((prevState) => ({
      ...prevState,
      [`${name}dateFormat`]: formattedDate,
      [name]: value
    }))
  }

  const handleDismiss = () => {
    setShowDialog({ ...showDialog, showModal: false })
  }

  const handleSuccess = () => {
    history.push(ROUTE_STRINGS.listTerminalDetails)
    setShowDialog({ ...showDialog, showModal: false })
  }

  const handleRetry = () => {
    setShowDialog(false)
    addTerminalDetails()
  }

  const requiredFields = {
    idMarket: t('TERMINAL_DETAILS_ERROR_MESSAGE', { dataField: 'Market Type' }),
    contractName: t('TERMINAL_DETAILS_ERROR_MESSAGE', { dataField: 'Contract Name' }),
    optionExpiry: t('TERMINAL_DETAILS_ERROR_MESSAGE', { dataField: 'Option Expiry' }),
    firstNoticeDay: t('TERMINAL_DETAILS_ERROR_MESSAGE', { dataField: 'First Notice Day' }),
    terminalId: t('TERMINAL_DETAILS_ERROR_MESSAGE', { dataField: 'Terminal ID' }),
    _52weeksHighRate: t('WEEKS_HIGH_AND_LOW_ERROR_MESSAGE', { dataField: 'High Rate' }),
    _52weeksHighDate: t('WEEKS_HIGH_AND_LOW_ERROR_MESSAGE', { dataField: 'High Date' }),
    _52weeksLowRate: t('WEEKS_HIGH_AND_LOW_ERROR_MESSAGE', { dataField: 'Low Rate' }),
    _52weeksLowDate: t('WEEKS_HIGH_AND_LOW_ERROR_MESSAGE', { dataField: 'Low Date' })
  }

  const validate = (data) => {
    const errors = {}

    Object.keys(requiredFields).forEach((field) => {
      if (!data[field]) {
        errors[field] = requiredFields[field]
      }
    })

    return errors
  }

  const { idMarket, contractName, optionExpiry, firstNoticeDay, terminalId, _52weeksHighRate, _52weeksHighDate, _52weeksLowRate, _52weeksLowDate } = terminalDetails
  const isButtonDisabled = !idMarket || !contractName || !optionExpiry || !firstNoticeDay || !terminalId || !_52weeksHighRate || !_52weeksHighDate || !_52weeksLowDate || !_52weeksLowRate

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  return (
    <LayoutContainer title={t('ADD_TERMINAL_DETAILS')} breadCrumbsTitle={'addTerminal'}>
      <div className="add-terminal-form">
        <Form
          onSubmit={onSubmit}
          initialValues={terminalDetails}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="p-fluid">
              <Field
                name="idMarket"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <Dropdown
                        {...input}
                        id="idMarket"
                        name="idMarket"
                        value={terminalDetails.idMarket}
                        onChange={handleChange}
                        options={marketType}
                        optionLabel="type"
                        optionValue="id"
                        className={classNames(
                          'dropdown-field',
                          {
                            'p-invalid': isFormFieldValid(meta)
                          },
                          isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields'
                        )}
                      />
                      <label htmlFor="idMarket" className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                        {t('MARKET_TYPE')} <span className="span-text-color">*</span>{' '}
                      </label>
                    </span>
                    <div className="error-message">{getFormErrorMessage(meta)}</div>
                  </div>
                )}
              />
              <Field
                name="contractName"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <InputText id="contractName" {...input} name="contractName" value={terminalDetails.contractName} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
                      <label htmlFor="contractName" className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                        {t('CONTRACT_NAME')} <span className="span-text-color">*</span>
                      </label>
                    </span>
                    <div className="error-message">{getFormErrorMessage(meta)}</div>{' '}
                  </div>
                )}
              />
              <Field
                name="optionExpiry"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <Calendar {...input} id="optionExpiry" name="optionExpiry" value={terminalDetails.optionExpiry} onChange={(e) => handleDateChange(e)} dateFormat="dd/mm/yy" showIcon={true} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
                      <label htmlFor="optionExpiry" className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                        {t('OPTION_EXPIRY')} <span className="span-text-color">*</span>
                      </label>
                    </span>
                    <div className="error-message">{getFormErrorMessage(meta)}</div>{' '}
                  </div>
                )}
              />

              <Field
                name="firstNoticeDay"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <Calendar {...input} id="firstNoticeDay" name="firstNoticeDay" value={terminalDetails.firstNoticeDay} onChange={(e) => handleDateChange(e)} dateFormat="dd/mm/yy" showIcon className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                      <label htmlFor="firstNoticeDay" className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                        {t('FIRST_NOTICE_DAY')} <span className="span-text-color">*</span>
                      </label>
                    </span>
                    <div className="error-message">{getFormErrorMessage(meta)}</div>
                  </div>
                )}
              />

              <Field
                name="terminalId"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <InputText {...input} name="terminalId" value={terminalDetails.terminalId} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />

                      <label htmlFor="terminalId" className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                        {t('TERMINAL_ID')} <span className="span-text-color">*</span>
                      </label>
                    </span>
                    <div className="error-message">{getFormErrorMessage(meta)}</div>{' '}
                  </div>
                )}
              />

              <Field
                name="_52weeksHighRate"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <InputText {...input} id="_52weeksHighRate" name="_52weeksHighRate" value={terminalDetails._52weeksHighRate} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />

                      <label htmlFor="_52weeksHighRate" className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                        {t('WEEKS_HIGH_RATE')} <span className="span-text-color">*</span>
                      </label>
                    </span>
                    <div className="error-message">{getFormErrorMessage(meta)}</div>{' '}
                  </div>
                )}
              />
              <Field
                name="_52weeksHighDate"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <Calendar {...input} id="_52weeksHighDate" name="_52weeksHighDate" value={terminalDetails._52weeksHighDate} onChange={(e) => handleDateChange(e)} dateFormat="dd/mm/yy" showIcon className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                      <label htmlFor="_52weeksHighDate" className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                        {t('WEEKS_HIGH_DATE')} <span className="span-text-color">*</span>
                      </label>
                    </span>
                    <div className="error-message">{getFormErrorMessage(meta)}</div>{' '}
                  </div>
                )}
              />
              <Field
                name="_52weeksLowRate"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <InputText {...input} id="_52weeksLowRate" name="_52weeksLowRate" value={terminalDetails._52weeksLowRate} onChange={handleChange} className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : 'form-fields')} />
                      <label htmlFor="_52weeksLowRate" className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                        {t('WEEKS_LOW_RATE')} <span className="span-text-color">*</span>
                      </label>
                    </span>
                    <div className="error-message">{getFormErrorMessage(meta)}</div>{' '}
                  </div>
                )}
              />
              <Field
                name="_52weeksLowDate"
                render={({ input, meta }) => (
                  <div className="field">
                    <span className="p-float-label">
                      <Calendar {...input} id="_52weeksLowDate" name="_52weeksLowDate" value={terminalDetails._52weeksLowDate} onChange={(e) => handleDateChange(e)} dateFormat="dd/mm/yy" showIcon className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                      <label htmlFor="_52weeksLowDate" className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                        {t('WEEKS_LOW_DATE')}
                        <span className="span-text-color">*</span>
                      </label>
                    </span>
                    <div className="error-message">{getFormErrorMessage(meta)}</div>{' '}
                  </div>
                )}
              />
            </form>
          )}
        />
        <div className="add-terminal-button">
          <ButtonComponent type="submit" disabled={isButtonDisabled} onClick={addTerminalDetails} />
        </div>
      </div>

      <DialogModal {...showDialog} onDismiss={handleDismiss} onSuccess={handleSuccess} />
    </LayoutContainer>
  )
}

export default Loader(AddTerminalDetails)
