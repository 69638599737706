// Ref: https://dev.to/franklin030601/building-a-multi-language-app-with-react-js-2och
// https://translate.google.co.in/?sl=auto&tl=hi&text=Password%20Is%20Required&op=translate

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import language from './languages'

i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  },
  resources: language
})

export default i18n
