import './styles.scss'
import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Form, Field } from 'react-final-form'
import { InputText } from 'primereact/inputtext'
import { FileUpload } from 'primereact/fileupload'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import { Toast } from 'primereact/toast'
import { Image } from 'primereact/image'
import DialogModal from 'Components/UIComponents/DialogModal'
import LayoutContainer from 'Components/LayoutComponent'
import Validation from 'Utils/Validation'
import { commonMethods } from 'Utils/commonMethods'
import { ROUTE_STRINGS } from 'Utils/Constants'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import ButtonComponent from 'Components/UIComponents/ButtonComponent'
import Loader from 'Components/Loader'

const AddPayment = ({ setLoading }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const toast = useRef(null)

  const [selectedFile, setSelectedFile] = useState('')
  const [imageUrl, setImageUrl] = useState('')

  const [imageUploaded, setImageUploaded] = useState(false)
  const [addPaymentData, setAddPaymentData] = useState({
    paymentName: '',
    imageUrl: '',
    isPlanId: false,
    isActive: true
  })
  const [modalState, setModalState] = useState({
    showModal: false,
    message: '',
    multipleMessage: {},
    modalType: '',
    modalCancel: false,
    onHide: () => {},
    onSubmit: () => {}
  })

  const submitFileToS3Bucket = async (event) => {
    const selectedFile = event.files[0]

    const fileData = new FormData()

    fileData.append('file', selectedFile)
    setLoading(true)
    try {
      const { data, status } = await apiAdapterCoffeeWeb.uploadFileToS3Bucket(fileData)

      if (status === 200) {
        setSelectedFile(data)
        setImageUploaded(true)
        setImageUrl(data)
      }
    } catch {
      setModalState({
        ...modalState,
        showModal: true,
        message: t('FAILED_TO_UPLOAD_IMAGE'),
        modalType: 'error',
        modalCancel: false,
        onHide: handleDismiss
      })
    } finally {
      setLoading(false)
    }
  }

  const postNewPaymentData = async (imgUrlLink) => {
    const { paymentName, imageUrl, isPlanId, isActive } = addPaymentData
    const postPaymentData = {
      paymentName: paymentName.charAt(0).toUpperCase() + paymentName.slice(1),
      imageUrl: !imgUrlLink ? imageUrl : imgUrlLink,
      isPlanId,
      isActive
    }

    setLoading(true)
    try {
      const {
        data: { statusCode, returnLst }
      } = await apiAdapterCoffeeWeb.createNewPayment(postPaymentData)

      if (statusCode === 200 || statusCode === 201) {
        setModalState({
          ...modalState,
          showModal: true,
          message: t('PAYMENT_TYPE_ADDED_SUCCESSFULLY'),
          multipleMessage: { firstMessage: t('ADD_COUNTRY_PAYMENT') },
          modalType: 'success',
          modalCancel: true,
          softAction: 'cancel',
          hardAction: 'Continue',
          onHide: handleSuccess,
          onSubmit: () => handleRedirect(returnLst)
        })
      }
    } catch (error) {
      const { statusCode } = error.response.data

      if (statusCode === 404) {
        setLoading(false)
        toast.current.show({ severity: t('ERROR_TOAST'), summary: t('DATA_ALREADY_EXISTS'), detail: t('PLEASE_ENTER_ANOTHER_PAYMENT_NAME') })
      } else {
        setModalState({
          ...modalState,
          showModal: true,
          message: t('SORRY_COULD_NOT_ADD_THE_DATA_PLEASE_TRY_AGAIN_LATER'),
          modalType: 'error',
          modalCancel: false,
          onHide: handleDismiss
        })
      }
    } finally {
      setLoading(false)
    }
  }

  const handleDismiss = () => {
    setModalState({ ...modalState, showModal: false, message: t('SORRY_COULD_NOT_ADD_THE_DATA_PLEASE_TRY_AGAIN_LATER'), modalType: 'error' })
  }

  const handleSuccess = () => {
    history.push(ROUTE_STRINGS.listPayment)
  }

  const handleSaveButton = (e) => {
    if (imageUploaded) {
      postNewPaymentData(selectedFile)
    }
  }

  const handleChange = (event) => {
    const { value, name } = event.target

    if (name === 'paymentName') {
      if (Validation.nameValidation(value) || Validation.isInputFieldEmpty(value)) {
        setAddPaymentData((prevData) => ({
          ...prevData,
          paymentName: value
        }))
      }
    }
    if (name === 'imageUrl') {
      setAddPaymentData((prevData) => ({
        ...prevData,
        imageUrl: value
      }))
    }
  }

  const onSubmit = (data) => {
    setAddPaymentData(data)
  }

  const handleRetry = () => {
    setModalState({ ...modalState, showModal: false })
    postNewPaymentData()
  }

  const handleDeleteImage = () => {
    setSelectedFile(null)
    setImageUploaded(false)
    setImageUrl('')
  }

  const validate = (data) => {
    const errors = {}

    const { paymentName } = data

    if (!paymentName) {
      errors.paymentName = t('PAYMENT_IS_REQUIRED')
    }

    return errors
  }

  const submitButtonDisabled = !addPaymentData.paymentName || !imageUploaded

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  const handleRedirect = (returnLst) => {
    history.push({
      pathname: `${ROUTE_STRINGS.editPayment}/${returnLst.idPayment}`,
      state: returnLst
    })
  }

  return (
    <LayoutContainer title={'ADD_NEW_PAYMENT'} breadCrumbsTitle={'addPayment'}>
      <div className="add-payment-container">
        <Form
          onSubmit={onSubmit}
          initialValues={addPaymentData}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="p-fluid">
              <Field
                name="paymentName"
                render={({ input, meta }) => (
                  <div className="payment-name-field-wrapper">
                    <span className="p-float-label">
                      <InputText id="name" name="paymentName" autoComplete="off" {...input} onChange={handleChange} value={addPaymentData.paymentName} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                      <label htmlFor="name" className={classNames(`${isFormFieldValid(meta) ? 'error-message' : 'add-payment-label'}`)}>
                        {t('PAYMENT_CHANNEL_NAME')}
                        <span className="required-field">*</span>
                      </label>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}
              />
            </form>
          )}
        />
        <div className="payment-logo-container">
          <label htmlFor="paymentImage" className="payment-logo-label">
            {t('PAYMENT_CHANNEL_LOGO')}
          </label>
          <span className="required-field">*</span>

          <FileUpload
            chooseLabel="Choose File"
            accept="image/*"
            auto={true}
            onSelect={(event) => submitFileToS3Bucket(event)}
            emptyTemplate={
              <div>
                {imageUrl ? (
                  <div className="logo-upload-container">
                    <div className="payment-logo-wrapper">
                      <div className="logo-wrapper">
                        <Image src={imageUrl} alt="payment logo" />
                      </div>

                      <div className="logo-cancel-button-container">
                        <Button icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={handleDeleteImage} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <p className="logo-choose-title">{t('DRAG_AND_DROP_A_FILE_HERE_TO_UPLOAD')}.</p>
                )}
              </div>
            }
          />
        </div>
        <div className="button-container">
          <ButtonComponent type={'submit'} onClick={handleSaveButton} disabled={submitButtonDisabled} />
        </div>
      </div>
      <DialogModal {...modalState} onDismiss={handleDismiss} onRetry={modalState.modalCancel ? commonMethods.handleRetry : handleRetry} onSuccess={handleSuccess} />
      <Toast ref={toast} position="top-right" />
    </LayoutContainer>
  )
}

export default Loader(AddPayment)
