import './App.scss'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import LoginScreen from 'Screens/AuthScreens'
import Routes from 'Components/Routes'
import ProtectedRoute from 'Components/ProtectedRoute'
import MyErrorBoundary from 'Components/MyErrorBoundary'
import { ROUTE_STRINGS } from 'Utils/Constants'

function App() {
  return (
    <MyErrorBoundary>
      <Switch>
        <Route exact path={ROUTE_STRINGS.login} component={LoginScreen} />
        <ProtectedRoute path={ROUTE_STRINGS.dashboard} component={Routes} />
      </Switch>
    </MyErrorBoundary>
  )
}

export default App
