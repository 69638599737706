import './styles.scss'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { InputSwitch } from 'primereact/inputswitch'
import { Image } from 'primereact/image'
import { InputText } from 'primereact/inputtext'
import { ROUTE_STRINGS, TABLE_CONST } from 'Utils/Constants'
import { commonMethods } from 'Utils/commonMethods'
import DialogModal from 'Components/UIComponents/DialogModal'
import Loader from 'Components/Loader'
import LayoutContainer from 'Components/LayoutComponent'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const ListPayment = ({ setLoading }) => {
  const history = useHistory()
  let rowCount = TABLE_CONST.paymentRowCount
  let paginationButtons = TABLE_CONST.paginationButtonCount
  const { t } = useTranslation()

  const [paymentMaster, setPaymentMaster] = useState([])
  const [currentPaymentData, setCurrentPaymentData] = useState([])
  const [eachRowData, setEachRowData] = useState({})
  const [searchQuery, setSearchQuery] = useState('')
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [showTableMessage, setShowTableMessage] = useState(false)
  const [modalState, setModalState] = useState({
    showModal: false,
    message: '',
    modalType: '',
    softAction: '',
    hardAction: ''
  })

  useEffect(() => {
    getPaymentMaster()
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  if (windowSize < TABLE_CONST.maxWidthTab) {
    rowCount = TABLE_CONST.rowsCount
    paginationButtons = TABLE_CONST.mobilePaginationButtonCount
  }

  const getPaymentMaster = async () => {
    setLoading(true)
    setShowTableMessage(true)
    try {
      const { data } = await apiAdapterCoffeeWeb.getAllPaymentType()

      setPaymentMaster(data.returnLst)
      setCurrentPaymentData(data.returnLst)
    } catch {
      setModalState({
        ...modalState,
        showModal: true,
        message: t('NO_DATA_FOUND'),
        modalType: 'error'
      })
    } finally {
      setLoading(false)
      setShowTableMessage(false)
    }
  }

  const putPaymentMaster = async (item) => {
    setLoading(true)
    try {
      if (!item.isActive) {
        await apiAdapterCoffeeWeb.activatePayment({ toggleId: item.idPayment })
      } else {
        await apiAdapterCoffeeWeb.deleteInActiveMaster({ toggleId: eachRowData.idPayment })
      }

      updateStateOnToggle(paymentMaster, setPaymentMaster, item, 'isActive')
      updateStateOnToggle(currentPaymentData, setCurrentPaymentData, item, 'isActive')

      setModalState({
        ...modalState,
        showModal: false
      })
    } catch {
      setModalState({
        ...modalState,
        showModal: true,
        message: t('FAILED_TO_ACTIVATE_DEACTIVATE'),
        modalType: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const updateStateOnToggle = (state, stateUpdater, item, key) => {
    const modifiedData = state.map((ele) => (ele.idPayment === item.idPayment ? { ...ele, [key]: !item[key] } : ele))

    stateUpdater(modifiedData)
  }

  const confirmDeactivateToggle = (item) => {
    if (item.isActive) {
      setEachRowData(item)
      setModalState({
        showModal: true,
        message: t('ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE_PAYMENT'),
        modalType: 'info',
        softAction: t('CANCEL'),
        hardAction: t('DEACTIVATE')
      })
    } else {
      putPaymentMaster(item)
    }
  }

  const searchInData = (e) => {
    const searchTerm = e.target.value.toLowerCase()

    setSearchQuery(searchTerm)
    const filtered = currentPaymentData.filter(({ paymentName }) => paymentName.toLowerCase().includes(searchTerm))

    setPaymentMaster(filtered)
  }

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth)
  }

  const handleDismiss = () => {
    setModalState({
      ...modalState,
      message: t('NO_DATA_FOUND'),
      modalType: 'error',
      showModal: false
    })
  }

  const handleOnHide = () => {
    window.location.reload()
  }

  const handleEditPayment = (row) => {
    history.push({
      pathname: `${ROUTE_STRINGS.editPayment}/${row.idPayment}`,
      state: row
    })
  }

  const handleAddPayment = () => {
    history.push(ROUTE_STRINGS.addPayment)
  }

  const imageRenderer = (props) => {
    const { imageUrl } = props

    return (
      <div className="payment-type-image">
        <Image src={imageUrl} alt="Dynamic Image" />
      </div>
    )
  }

  const toggleActivate = (id) => (
    <div className="toggle-switch">
      <InputSwitch checked={id.isActive} onChange={() => confirmDeactivateToggle(id)} />
    </div>
  )

  const editScreenRedirect = (index) => <Button type="button" icon="pi pi-pencil" className="edit-button" tooltip={t('EDIT')} tooltipOptions={{ position: 'top' }} onClick={() => handleEditPayment(index)} />

  const tableResponseMessage = () => <div>{showTableMessage ? t('FETCHING') : t('NO_RESULTS_FOUND')}</div>

  return (
    <LayoutContainer title={'PAYMENT_TYPE'} breadCrumbsTitle={'listPayment'} showAddButton={true} handleAddButtonClick={handleAddPayment}>
      <div className="list-payment-container">
        <div className="search-wrapper">
          <div className="search-field p-input-icon-left">
            <i className="pi pi-search" />
            <InputText value={searchQuery} onChange={(e) => searchInData(e)} placeholder={t('SEARCH')} />
          </div>
        </div>
        <DataTable value={paymentMaster} paginator={paymentMaster.length} rows={rowCount} pageLinkSize={paginationButtons} emptyMessage={tableResponseMessage}>
          <Column field="paymentName" header={t('PAYMENT_NAME')} className="payment-column" />
          <Column field={imageRenderer} header={t('IMAGE_URL')} className="image-column" />
          <Column field="isActive" header={t('IS_ACTIVE')} body={toggleActivate} className="toggle-column" />
          <Column body={editScreenRedirect} header={t('ACTIONS')} className="action-column" />
        </DataTable>
      </div>
      <DialogModal {...modalState} onDismiss={handleDismiss} onRetry={commonMethods.handleRetry} onHide={handleOnHide} onConfirmation={() => putPaymentMaster(eachRowData)} />
    </LayoutContainer>
  )
}

export default Loader(ListPayment)
