import './styles.scss'
import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Form, Field } from 'react-final-form'
import { classNames } from 'primereact/utils'
import { Dropdown } from 'primereact/dropdown'
import { Toast } from 'primereact/toast'
import ButtonComponent from 'Components/UIComponents/ButtonComponent'
import CountrySelector from 'Components/UIComponents/CountrySelector'
import DialogModal from 'Components/UIComponents/DialogModal'
import LayoutContainer from 'Components/LayoutComponent'
import Loader from 'Components/Loader'
import { getLocalUserDetails } from 'Utils/LocalStorageHandler'
import { ROUTE_STRINGS } from 'Utils/Constants'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'

const AddPaymentCountry = ({ setLoading }) => {
  const history = useHistory()
  const toast = useRef(null)
  const { t } = useTranslation()
  const { countryId } = getLocalUserDetails()

  const [idCountry, setIdCountry] = useState('102')
  const [paymentId, setPaymentId] = useState('')
  const [paymentList, setPaymentList] = useState([])
  const [userPaymentCountry, setUserPaymentCountry] = useState({
    countryName: '',
    paymentName: ''
  })
  const [modalState, setModalState] = useState({
    showModal: false,
    message: '',
    modalType: '',
    onRetry: () => {},
    onHide: () => {}
  })

  useEffect(() => {
    fetchPaymentList()
  }, [])

  const fetchPaymentList = async () => {
    setLoading(true)
    try {
      const getAllPaymentUpdatesData = await apiAdapterCoffeeWeb.getAllPaymentType()

      const allPaymentMethod = getAllPaymentUpdatesData.data.returnLst

      const isActivePaymentMethod = allPaymentMethod.filter((ele) => ele.isActive)

      setPaymentList(isActivePaymentMethod)
    } catch {
      setModalState({
        ...modalState,
        showModal: true,
        message: 'NO_DATA_FOUND',
        modalType: 'error',
        onRetry: fetchPaymentList
      })
    } finally {
      setLoading(false)
    }
  }

  const paymentSubmitData = async () => {
    setLoading(true)
    handleDismiss()
    const postPaymentCountryData = {
      id: 0,
      countryId: idCountry,
      paymentId,
      isActive: true
    }

    try {
      const {
        data: { statusCode }
      } = await apiAdapterCoffeeWeb.postPaymentCountry(postPaymentCountryData)

      if (statusCode === 200) {
        setModalState({
          ...modalState,
          showModal: true,
          message: 'PAYMENT_FOR_COUNTRY_ADDED_SUCCESSFULLY',
          modalType: 'success'
        })
      }
    } catch (error) {
      const { statusCode } = error.response.data

      if (statusCode === 404) {
        toast.current.show({ severity: t('ERROR_TOAST'), summary: t('DATA_ALREADY_EXISTS'), detail: t('PLEASE_ENTER_ANOTHER_PAYMENT_NAME') })
      } else {
        setModalState({
          ...modalState,
          showModal: true,
          message: 'SORRY_COULD_NOT_ADD_THE_DATA_PLEASE_TRY_AGAIN_LATER',
          modalType: 'error',
          onRetry: paymentSubmitData
        })
      }
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (e) => {
    setIdCountry(e)
  }

  const handleChangeForCountry = (e) => {
    setUserPaymentCountry({
      ...userPaymentCountry,
      paymentName: e.value
    })
    setPaymentId(e.value)
  }

  const handleSuccess = () => {
    history.push(ROUTE_STRINGS.listPaymentCountry)
  }

  const handleDismiss = () => {
    if (modalState.modalType === 'success') handleSuccess()
    setModalState({ ...modalState, showModal: false })
  }

  const onSubmit = (data) => {
    setUserPaymentCountry(data)
  }

  const validate = (data) => {
    const errors = {}

    const { countryName, paymentName } = data

    if (!countryName) {
      errors.countryName = t('COUNTRY_IS_REQUIRED')
    }
    if (!paymentName) {
      errors.paymentName = t('PAYMENT_IS_REQUIRED')
    }

    return errors
  }

  const submitButtonDisabled = !userPaymentCountry.paymentName

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error)
  const getFormErrorMessage = (meta) => isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>

  return (
    <LayoutContainer title={'ADD_PAYMENT_COUNTRY'} breadCrumbsTitle={'addPaymentMapping'}>
      <div className="add-payment-country-master">
        <Form
          onSubmit={onSubmit}
          initialValues={userPaymentCountry}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="p-fluid">
              <Field
                name="paymentName"
                render={({ input, meta }) => (
                  <div className=" field add-payment-country-fields">
                    <span className="p-float-label">
                      <Dropdown
                        className={classNames({ 'p-invalid': isFormFieldValid(meta) }, isFormFieldValid(meta) ? 'p-invalid-hover' : '')}
                        {...input}
                        name="paymentName"
                        id="payment-name"
                        value={userPaymentCountry.paymentName}
                        options={paymentList.map(({ paymentName, idPayment }) => ({
                          label: paymentName,
                          value: idPayment
                        }))}
                        onChange={handleChangeForCountry}
                        placeholder={t('SELECT_A_PAYMENT')}
                      />
                      <label htmlFor="payment-name" className={classNames(`${isFormFieldValid(meta) ? 'error-message' : 'add-payment-country'}`)}>
                        {t('PAYMENT_NAME')}
                        <span className="star">*</span>
                      </label>
                    </span>
                    <div>{getFormErrorMessage(meta)}</div>
                  </div>
                )}
              />
              <div className="field add-payment-country-fields">
                <label htmlFor="name" className="country-name">
                  {t('COUNTRY_NAME')}
                </label>
                <span className="star">*</span>
                <CountrySelector id="name" isFormComponent={true} countryId={countryId} selectCurrency={handleChange} />
              </div>
            </form>
          )}
        />
        <div className="add-payment-container-button">
          <ButtonComponent type={'submit'} onClick={paymentSubmitData} disabled={submitButtonDisabled} />
        </div>
      </div>
      <DialogModal {...modalState} onDismiss={handleDismiss} onSuccess={handleSuccess} onHide={handleDismiss} />
      <Toast ref={toast} position="top-right" />
    </LayoutContainer>
  )
}

export default Loader(AddPaymentCountry)
