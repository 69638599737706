import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { DataTable } from 'primereact/datatable'
import apiAdapterCoffeeWeb from 'Services/apiAdapterCoffeeWeb'
import Loader from 'Components/Loader'

const OtherUser = ({ setLoading, rowCount, paginationButtons, columns, actionTemplate, toggleTemplate, openErrorModal, othersData, setOthersData, othersFilterData, setOthersFilterData }) => {
  const { t } = useTranslation()

  const [searchQuery, setSearchQuery] = useState('')
  const [showTableMessage, setShowTableMessage] = useState(false)

  useEffect(() => {
    getUsersDataOthers()
  }, [])

  const getUsersDataOthers = async () => {
    openErrorModal(false)
    setLoading(true)
    setShowTableMessage(true)
    try {
      const { data } = await apiAdapterCoffeeWeb.getAllOtherUsers()

      setOthersData(data)
      setOthersFilterData(data)
    } catch (error) {
      openErrorModal(true, getUsersDataOthers)
    } finally {
      setLoading(false)
      setShowTableMessage(false)
    }
  }

  const searchInOthersData = (e) => {
    const searchTerm = e.target.value.toLowerCase()

    setSearchQuery(searchTerm)
    const filteredData = othersFilterData.filter((item) => {
      const { fullName, occupationName, userRoleName, phone, email, states, countryName } = item

      if (fullName.toLowerCase().includes(searchTerm) || occupationName.toLowerCase().includes(searchTerm) || userRoleName.toLowerCase().includes(searchTerm) || phone.toLowerCase().includes(searchTerm) || email.toLowerCase().includes(searchTerm) || states.toLowerCase().includes(searchTerm) || countryName.toLowerCase().includes(searchTerm)) return item
    })

    setOthersData(filteredData)
  }

  const Columns = columns.filter((col) => col.dataField !== 'subscriptionTypeName')

  const tableResponseMessage = () => <div>{showTableMessage ? t('FETCHING') : t('NO_RESULTS_FOUND')}</div>

  return (
    <div>
      <div className="search-wrapper">
        <div className="search-field p-input-icon-left">
          <i className="pi pi-search" />
          <InputText value={searchQuery} onChange={(e) => searchInOthersData(e)} placeholder={t('SEARCH')} />
        </div>
      </div>
      <DataTable value={othersData} removableSort paginator={othersData.length > 0} rows={rowCount} pageLinkSize={paginationButtons} emptyMessage={tableResponseMessage} className="user-table">
        {Columns.map((col, index) => (
          <Column key={index} field={col.dataField} header={col.text} sortable={col.sortable} className={col.className} />
        ))}
        <Column field="isActive" header={t('IS_ACTIVE')} body={toggleTemplate} className="toggle-switch-column" />
        <Column body={actionTemplate} header={t('ACTIONS')} className="action-column" />
      </DataTable>
    </div>
  )
}

export default Loader(OtherUser)
